import { Chart } from 'react-chartjs-2'

class GraphClass {
  initialize() {
    this.createDoughnutWithLabelsType()
  }

  createDoughnutWithLabelsType() {
    Chart.defaults.doughnutWithLabels = Chart.helpers.clone(
      Chart.defaults.doughnut
    )
    Chart.controllers.doughnutWithLabels = Chart.controllers.doughnut.extend({
      updateElement: function (arc, index, reset) {
        const { chartArea, options } = this.chart
        const {
          animation: animationOpts,
          elements: { arc: arcOpts },
        } = options

        const centerX = (chartArea.left + chartArea.right) / 2
        const centerY = (chartArea.top + chartArea.bottom) / 2
        const shouldAnimateRotate = reset && animationOpts.animateRotate
        const shouldAnimateScale = reset && animationOpts.animateScale

        const dataset = this.getDataset()
        const defaultCircumference =
          this.calculateCircumference(dataset.data[index]) *
          (options.circumference / (2.0 * Math.PI))

        const startAngle = options.rotation
        const endAngle = options.rotation
        const circumference =
          shouldAnimateRotate || arc.hidden ? 0 : defaultCircumference
        const innerRadius = shouldAnimateScale ? 0 : this.innerRadius
        const outerRadius = shouldAnimateScale ? 0 : this.outerRadius
        const custom = arc.custom || {}
        const label = Chart.helpers.getValueAtIndexOrDefault(
          dataset.label,
          index,
          this.chart.data.labels[index]
        )

        const x = centerX + this.chart.offsetX
        const y = centerY + this.chart.offsetY

        Chart.helpers.extend(arc, {
          _datasetIndex: this.index,
          _index: index,
          _model: {
            x,
            y,
            startAngle,
            endAngle,
            circumference,
            outerRadius,
            innerRadius,
            label,
          },
          draw: function () {
            const {
              _view,
              _chart: {
                ctx,
                config: { options },
              },
            } = this
            const labelPos = this.tooltipPosition()

            ctx.beginPath()

            ctx.arc(
              _view.x,
              _view.y,
              _view.outerRadius,
              _view.startAngle,
              _view.endAngle
            )
            ctx.arc(
              _view.x,
              _view.y,
              _view.innerRadius,
              _view.endAngle,
              _view.startAngle,
              true
            )

            ctx.closePath()
            ctx.strokeStyle = _view.borderColor
            ctx.lineWidth = _view.borderWidth

            ctx.fillStyle = _view.backgroundColor

            ctx.fill()
            ctx.lineJoin = 'bevel'

            if (_view.borderWidth) {
              ctx.stroke()
            }

            if (_view.circumference > options.label.minLabelCircumference) {
              ctx.beginPath()
              ctx.font = Chart.helpers.fontString(
                options.defaultFontSize,
                options.defaultFontStyle,
                options.defaultFontFamily
              )
              ctx.fillStyle = options.label.color
              ctx.textBaseline = 'top'
              ctx.textAlign = 'center'

              ctx.fillText(label, labelPos.x, labelPos.y)
            }
          },
        })

        const getModelCustomProp = (prop) =>
          custom[prop] ||
          Chart.helpers.getValueAtIndexOrDefault(
            dataset[prop],
            index,
            arcOpts[prop]
          )

        arc._model.backgroundColor = getModelCustomProp('backgroundColor')
        arc._model.hoverBackgroundColor = getModelCustomProp(
          'hoverBackgroundColor'
        )
        arc._model.borderWidth = getModelCustomProp('borderWidth')
        arc._model.borderColor = getModelCustomProp('borderColor')

        if (!reset || !animationOpts.animateRotate) {
          arc._model.startAngle =
            index === 0
              ? options.rotation
              : this.getMeta().data[index - 1]._model.endAngle
          arc._model.endAngle = arc._model.startAngle + arc._model.circumference
        }

        arc.pivot()
      },
    })
  }
}

export const Graph = new GraphClass()
