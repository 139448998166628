import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle fill="#2c3e50" cx="12" cy="12" r="9" />
    <path stroke="#2c3e50" d="M10 10l4 4m0 -4l-4 4" />
  </svg>
)
