import { defineMessages } from 'react-intl'

export default defineMessages({
  totalEarnings: {
    id: 'reportData.totalEarnings',
    defaultMessage: 'Total earnings potential',
  },
  totalHint: {
    id: 'reportData.totalHint',
    defaultMessage: 'Assumes 1 MW of demand response capability per facility',
  },
  totalPrograms: {
    id: 'reportData.totalPrograms',
    defaultMessage: 'Total programs',
  },
  totalSites: {
    id: 'reportData.totalSites',
    defaultMessage: 'Total sites',
  },
  dispatchFrequency: {
    id: 'reportData.dispatchFrequency',
    defaultMessage: 'Dispatch frequency',
  },
  eventsPerYear: {
    id: 'reportData.eventsPerYear',
    defaultMessage: 'Number of demand response event per year',
  },
  responseTime: {
    id: 'reportData.responseTime',
    defaultMessage: 'Response time',
  },
  energyConsumption: {
    id: 'reportData.energyConsumption',
    defaultMessage: 'Time required to curtail energy consumption',
  },
})
