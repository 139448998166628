import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { components } from 'react-select'

export class Option extends PureComponent {
  static propTypes = {
    selectProps: PropTypes.object.isRequired,
  }

  render() {
    const { selectProps, ...props } = this.props
    const { emptyValuePlaceholder } = selectProps
    const isEmptyText = props.children === ''
    const children =
      emptyValuePlaceholder && isEmptyText
        ? emptyValuePlaceholder
        : props.children
    return (
      <components.Option
        {...selectProps}
        {...props}
        className={classnames({
          [`${selectProps.classNamePrefix}__option--is-placeholder`]:
            isEmptyText,
        })}
      >
        {children}
      </components.Option>
    )
  }
}
