import React from 'react'
import { AnyObject } from '@voltus/types'

/**
 * A Helper to normalize the rendering of child nodes.
 * Accepts primitives to be rendered directly, or accepts a Component or
 * a function that returns a Component
 *
 * @param Comp - One of: a renderable react node, or a function that returns a renderable react node
 * @param props - Extra props to pass to a component
 */
export const renderNode = <Props = AnyObject>(
  Comp: React.ReactNode | ((props?: Props) => React.ReactNode),
  props?: Props
): React.ReactNode => {
  if (React.isValidElement(Comp)) {
    // No need to clone if no extra props are passed
    if (props) {
      return React.cloneElement(Comp, props)
    }

    return Comp
  }

  if (typeof Comp === 'function') {
    return Comp(props)
  }

  // Otherwise, likely a string, or something
  // that can be directly rendered
  return Comp
}
