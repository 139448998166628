/**
 * A general purpose browser logger utility. This wraps `console` and adds
 * some extra functionality like providing the ability to log something once
 * and only once, and allows sending custom logs to datadog.
 *
 * ## Logging Once
 * Each log method can be prefixed with `.once` (e.g. `logger.once.log('hi')`)
 * The next time that `logger.once.log('hi')` is called, it will not log anything.
 *
 * It's important to know that this will happen _globally_. So if anywhere else
 * in an application calls logger.once.log('hi'), and then you try to call in some
 * other, unrelated place, your log will not show.
 *
 * Logging once is best used for logging things like deprecation messages, or network errors
 * that might fail then retry (we don't need to log every failed retry, necessarily).
 *
 *
 * ## Example
 * ```ts
 * import logger from '@voltus/logger'
 *
 * logger.log('Hello world')  // logs continuously
 * logger.once.log('Hello world') // Will only log this message once
 *
 * // .once exists for all methods
 * logger.once.error('error')
 * ```
 *
 * ## Reporting
 * each log method has a final `.report` method that can be used to log to datadog.
 * e.g.
 * ```ts
 * // This will log this message to datadog once per the entire browser session
 * logger.report.once.log('hello datadog!')
 * ```
 * @packageDocumentation
 */
import { datadogLogs } from '@datadog/browser-logs'
import { Any, AnyObject } from '@voltus/types'

type AllowedLogType = 'log' | 'info' | 'warn' | 'error' | 'debug'

interface LogFn {
  (msg: Any, metadata?: AnyObject): void
  type: AllowedLogType
}

function createLogger({ logInProduction = ['info', 'error', 'log'] } = {}) {
  const logged = {}
  function _log(type = 'log', msg: Any, metadata?: AnyObject | null): void {
    if (typeof metadata === 'undefined' || metadata === null) {
      console[type](msg)
      return
    }

    console[type](msg, metadata)
  }

  /**
   * Creates a new logger function of a specific log type
   * the `type` parameter maps directly to the associated `console` method.
   * e.g. `makeLogger('info')` will return a function that calls `console.info`
   */
  function makeLogger(type: AllowedLogType): LogFn {
    const loggerFn = (msg: string, metadata?: AnyObject) => {
      _log(type, msg, metadata)
    }
    // This `type` property is used by the higher-order functions below
    loggerFn.type = type
    return loggerFn
  }

  /**
   * Below we'll define a series of higher-order functions.
   * Each function takes a loggerFn, and returns a new function that
   * executes the loggerFn along with some additional behavior
   */

  // Wrap a logger function in a check for if we should log this message type in production
  const logInProd = (logFn: LogFn): LogFn => {
    const logInProdFn = (msg: string, metadata?: AnyObject) => {
      const isProduction = window.location.hostname === 'voltapp.voltus.co'
      const shouldLogInProduction = logInProduction.includes(logFn.type)

      if (!isProduction) {
        logFn(msg, metadata)
        return
      }

      // If we are in production, only log if we say so
      if (shouldLogInProduction) {
        logFn(msg, metadata)
      }
    }
    logInProdFn.type = logFn.type
    return logInProdFn
  }

  // Wrap a logger function to only log a specific message one time during the entire app session
  const once = (logFn: LogFn): LogFn => {
    const onceFn = (msg: string, metadata?: AnyObject) => {
      const key = `${JSON.stringify(msg)}-${JSON.stringify(metadata)}`
      // Only call this logger once per serialized message
      if (logged[key] !== true) {
        logFn(msg, metadata)
        logged[key] = true
      }
    }

    onceFn.type = logFn.type
    return onceFn
  }

  // Wrap a logger function to send logs to datadog
  const reporter = (logFn: LogFn): LogFn => {
    const reporterFn = (msg: string | Error, metadata?: AnyObject) => {
      logFn(msg, metadata)
      if (msg instanceof Error) {
        datadogLogs.logger[logFn.type](msg.message, {
          ...metadata,
          stack: msg.stack,
        })
      } else {
        datadogLogs.logger[logFn.type](msg, metadata)
      }
    }
    reporterFn.type = logFn.type
    return reporterFn
  }

  const warn = makeLogger('warn')
  const error = makeLogger('error')
  const info = makeLogger('info')
  const log = makeLogger('log')
  const debug = makeLogger('debug')

  const logger = {
    /**
     * Warning log. Will NOT log in production.
     */
    warn: logInProd(warn),
    /**
     * Error log. Will log in production.
     */
    error: logInProd(error),
    /**
     * Info log. Will log in production.
     */
    info: logInProd(info),
    /**
     * Standard log. Will log in production.
     */
    log: logInProd(log),
    /**
     * Debug log. Will NOT log in production.
     */
    debug: logInProd(debug),
    once: {
      /**
       * Warning log. Prints unique messages 1 time only. Will NOT log in production.
       */
      warn: logInProd(once(warn)),
      /**
       * Error log. Prints unique messages 1 time only. Will log in production.
       */
      error: logInProd(once(error)),
      /**
       * Info log. Prints unique messages 1 time only. Will NOT in production.
       */
      info: logInProd(once(info)),
      /**
       * Standard log. Prints unique messages 1 time only. Will log in production.
       */
      log: logInProd(once(log)),
      /**
       * Debug log. Prints unique messages 1 time only. Will NOT log in production.
       */
      debug: logInProd(once(debug)),
    },
    // Reporter methods will log to datadog
    report: {
      /**
       * Report a warn log to datadog. Will NOT log in production.
       */
      warn: logInProd(reporter(warn)),
      /**
       * Report an error log to datadog. Will log in production.
       */
      error: logInProd(reporter(error)),
      /**
       * Report an info log to datadog. Will log in production.
       */
      info: logInProd(reporter(info)),
      /**
       * Report a standard log to datadog. Will log in production.
       */
      log: logInProd(reporter(log)),
      /**
       * Report a debug log to datadog. Will NOT log in production.
       */
      debug: logInProd(reporter(debug)),
      // Reporter Once methods will only log the message to datadog once per browser session
      once: {
        /**
         * Report a warning log ONCE to datadog. Will NOT log in production.
         */
        warn: logInProd(once(reporter(warn))),
        /**
         * Report an error log ONCE to datadog. Will log in production.
         */
        error: logInProd(once(reporter(error))),
        /**
         * Report an info log ONCE to datadog. Will log in production.
         */
        info: logInProd(once(reporter(info))),
        /**
         * Report a standard log ONCE to datadog. Will log in production.
         */
        log: logInProd(once(reporter(log))),
        /**
         * Report a debug log ONCE to datadog. Will NOT log in production.
         */
        debug: logInProd(once(reporter(debug))),
      },
    },
  }

  return logger
}

export default createLogger({
  logInProduction: ['info', 'error', 'log'],
})
export { createLogger }
