import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage } from 'react-intl'

import { Alert } from '../../../shared/components/alert'
import { NavigationButtons } from '../../../shared/components/navigationButtons'
import { renderWhenTrue } from '../../../shared/utils/rendering'
import { HOME_ROUTES } from '../home.constants'
import { Graph } from './graph'
import messages from './report.messages'
import { ReportData } from './reportData'

export class Report extends PureComponent {
  static propTypes = {
    reportsData: PropTypes.object.isRequired,
    totalEarnings: PropTypes.number.isRequired,
    totalPrograms: PropTypes.number.isRequired,
    totalSites: PropTypes.number.isRequired,
    statesEarnings: PropTypes.object.isRequired,
    statesLabels: PropTypes.object.isRequired,
    statesAbbreviations: PropTypes.object.isRequired,
    statesPrograms: PropTypes.object.isRequired,
    frequency: PropTypes.number.isRequired,
    responseTime: PropTypes.number.isRequired,
    searchParams: PropTypes.object.isRequired,
    setFrequency: PropTypes.func.isRequired,
    setResponseTime: PropTypes.func.isRequired,
    getSavedReport: PropTypes.func.isRequired,
    isLoadingReportsData: PropTypes.bool.isRequired,
    areProgramsAvailable: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { searchParams, getSavedReport } = this.props
    const { reportToken } = Object.fromEntries([...searchParams])

    if (reportToken) {
      // expect a report token upon redirect from salesforce
      // use token to retrieve report
      getSavedReport(reportToken)
    }

    if (!this.props.reportsData.size && !this.props.isLoadingReportsData) {
      this.props.navigate(HOME_ROUTES.siteInfo)
    }

    window.scrollTo(0, 0)
  }

  handleBackClick = () => {
    this.props.navigate(HOME_ROUTES.learnMore)
  }

  renderLoader = renderWhenTrue(() => (
    <div className="report__loader">
      <FormattedMessage {...messages.loader} />
    </div>
  ))

  renderReports = renderWhenTrue(() => (
    <div className="report__results-container">
      <div className="report__data-container">
        <ReportData
          totalEarnings={this.props.totalEarnings}
          totalPrograms={this.props.totalPrograms}
          totalSites={this.props.totalSites}
          frequency={this.props.frequency}
          responseTime={this.props.responseTime}
          setFrequency={this.props.setFrequency}
          setResponseTime={this.props.setResponseTime}
        />
      </div>
      <div className="report__graph-container">
        <Graph
          labels={this.props.statesLabels.toArray()}
          abbreviations={this.props.statesAbbreviations.toArray()}
          programs={this.props.statesPrograms.toArray()}
          values={this.props.statesEarnings.toArray()}
          areProgramsAvailable={this.props.areProgramsAvailable}
          type="doughnutWithLabels"
        />
      </div>
    </div>
  ))

  renderError = renderWhenTrue(() => (
    <div className="report__error">
      <Alert className="report__error-alert">
        <FormattedMessage {...messages.error} />
      </Alert>

      <button
        type="button"
        className="button report__error-back"
        onClick={this.handleBackClick}
      >
        <FormattedMessage {...messages.goBack} />
      </button>
    </div>
  ))

  renderCongratulations = renderWhenTrue(() => {
    return (
      <React.Fragment>
        <h1 className="report__title">
          <FormattedMessage {...messages.title} />
        </h1>

        <p className="report__description">
          <FormattedMessage {...messages.description} />
        </p>
      </React.Fragment>
    )
  })

  render() {
    return (
      <div className="report__container">
        <Helmet title={this.props.intl.formatMessage(messages.pageTitle)} />

        {this.renderLoader(this.props.isLoadingReportsData)}

        {this.renderCongratulations(
          !this.props.isLoadingReportsData &&
            !!this.props.reportsData.size &&
            this.props.areProgramsAvailable
        )}

        {this.renderReports(
          !this.props.isLoadingReportsData && !!this.props.reportsData.size
        )}

        {this.renderError(
          !this.props.isLoadingReportsData && !this.props.reportsData.size
        )}

        <NavigationButtons onBack={this.handleBackClick} />
      </div>
    )
  }
}
