import React from 'react'
import createSVGComponent from './createSVGComponent'

export default createSVGComponent(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="layout-list">
      <g id="Vector">
        <path
          d="M2.6665 3.99999C2.6665 3.64637 2.80698 3.30723 3.05703 3.05718C3.30708 2.80713 3.64622 2.66666 3.99984 2.66666H11.9998C12.3535 2.66666 12.6926 2.80713 12.9426 3.05718C13.1927 3.30723 13.3332 3.64637 13.3332 3.99999V5.33332C13.3332 5.68695 13.1927 6.02608 12.9426 6.27613C12.6926 6.52618 12.3535 6.66666 11.9998 6.66666H3.99984C3.64622 6.66666 3.30708 6.52618 3.05703 6.27613C2.80698 6.02608 2.6665 5.68695 2.6665 5.33332V3.99999Z"
          stroke="#36454F"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.6665 10.6667C2.6665 10.313 2.80698 9.9739 3.05703 9.72385C3.30708 9.4738 3.64622 9.33332 3.99984 9.33332H11.9998C12.3535 9.33332 12.6926 9.4738 12.9426 9.72385C13.1927 9.9739 13.3332 10.313 13.3332 10.6667V12C13.3332 12.3536 13.1927 12.6928 12.9426 12.9428C12.6926 13.1928 12.3535 13.3333 11.9998 13.3333H3.99984C3.64622 13.3333 3.30708 13.1928 3.05703 12.9428C2.80698 12.6928 2.6665 12.3536 2.6665 12V10.6667Z"
          stroke="#36454F"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
)
