import classnames from 'classnames'
import React, { useLayoutEffect, useRef } from 'react'
import ReactModal from 'react-modal'
import { MODAL } from '@voltus/constants/testSelectors'
import logger from '@voltus/logger'
import { usePrevious } from '@voltus/utils'
import { isTestEnv } from '@voltus/utils'

import { StyledIcons } from '../../icons'
import { StyledProps } from '../../utils/styledSystem'
import { Box } from '../Box'
import { IconButton } from '../IconButton'
import stylesheet from './Modal.scss'
import { ModalContext, useModalContext } from './ModalContext'

export type ModalProps = ReactModal.Props & {
  /**
   * If true, the modal is open
   */
  isOpen: boolean
  /**
   * Called when the modal requests to be called. For example, if `esc` is pressed,
   * or a click occurs outside the modal. Closing the modal is up to the consumer,
   * typically setting some local `isOpen` prop to false.
   * e.g.
   * ```
   * onRequestClose={() => setIsOpen(false)}
   * ```
   */
  onRequestClose: () => void
  /**
   * If `false` the "Close" button in the upper right corner will not be rendered.
   * @deprecated - use `showCloseButton` instead
   */
  closeable?: boolean
  /**
   * Controls the animation timeout when closing the modal.
   */
  closeTimeoutMS?: number
  /**
   * If `false` the "Close" button in the upper right corner will not be rendered.
   */
  showCloseButton?: boolean
  /**
   * The header of the modal. Accepts any renderable React node
   */
  header?: React.ReactNode
  /**
   * Adds a data-testid attribute to the modal body. Useful for testing if
   * you want to select the modal HTML node.
   */
  'data-testid'?: string
  /**
   * Class name applied to the root modal element. Useful if you want
   * to override some default styles.
   */
  className?: string
  /**
   * Class name applied to the transparent overlay element.
   */
  overlayClassName?: string
  /**
   * Class name applied to the modal content wrapper.
   */
  wrapperClassName?: string
  /**
   * Rendered into the modal body.
   */
  children: React.ReactNode
  /**
   * If `true`, the modal will take up the entire screen
   */
  fullscreen?: boolean
  /**
   * Renders smaller text under the header
   */
  subheader?: React.ReactNode
  /**
   * When `false`, the modal cannot be scrolled.
   * `scrollable` defaults to `true`, and typically you
   * won't want to change this.
   */
  scrollable?: boolean
  /**
   * Explicitly set the z-index of the modal.
   * You may need to use this if you have a more complex layout
   * with multiple floating dialogs. In general you don't need
   * to touch z-index. If you're modal is rendering above or behind
   * something it shouldn't, you may need to tweak this.
   */
  zIndex?: number
  /**
   * If `true` the modal will have max-height set to 100vh.
   * By default the modal has a max height of 90vh.
   */
  fullHeight?: boolean
  /**
   * Props to pass to the wrapper element that contains all the body content,
   * including the header and close button.
   */
  wrapperProps?: StyledProps
}

export const Modal = ({
  isOpen,
  onRequestClose,
  closeable,
  closeTimeoutMS,
  showCloseButton,
  header,
  className,
  overlayClassName,
  wrapperClassName,
  children,
  fullscreen,
  fullHeight,
  subheader,
  scrollable,
  zIndex,
  wrapperProps,
  ...props
}: ModalProps): JSX.Element => {
  const contentRef = useRef<HTMLElement>()
  const scrollbarWidth = useRef<number>(0)
  const prevIsOpen = usePrevious(isOpen)

  const setScrollOffset = (pos) => {
    if (contentRef?.current?.scrollTop) {
      contentRef.current.scrollTop = pos
    }
  }

  const renderHeader = () => (
    <header className={stylesheet.header}>
      <h3 className={stylesheet.headerText}>{header}</h3>
      {subheader && <h5 className={stylesheet.subheaderText}>{subheader}</h5>}
    </header>
  )

  if (prevIsOpen === false && isOpen === true) {
    scrollbarWidth.current = window.innerWidth - document.body.clientWidth
    document.body.style.paddingRight = scrollbarWidth.current + 'px'
  }

  useLayoutEffect(() => {
    if (prevIsOpen === true && !isOpen) {
      setTimeout(() => {
        document.body.style.paddingRight = 0 + 'px'
      }, closeTimeoutMS)
    }
  }, [closeTimeoutMS, prevIsOpen, isOpen])

  if (closeable) {
    logger.once.warn(
      'closeable prop is deprecated, please use `showCloseButton` instead'
    )
  }

  // Check if we are in a test environment.
  // Disable animations if we are.
  const isTest = isTestEnv()

  return (
    <ModalContext.Provider setScrollOffset={setScrollOffset}>
      <ReactModal
        closeTimeoutMS={closeTimeoutMS}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        contentRef={(ref) => (contentRef.current = ref)}
        style={{ overlay: { zIndex } }}
        className={classnames(stylesheet.modal, className, {
          [stylesheet.fullscreen]: fullscreen,
          [stylesheet.fullHeight]: fullHeight,
          [stylesheet.scrollable]: scrollable,
          [stylesheet.isTest]: isTest,
        })}
        overlayClassName={classnames(stylesheet.overlay, overlayClassName, {
          [stylesheet.isTest]: isTest,
        })}
        bodyOpenClassName={stylesheet.bodyOpen}
        ariaHideApp={isTest ? false : true}
        {...props}
      >
        <Box
          data-testid={props['data-testid']}
          className={classnames(stylesheet.wrapper, wrapperClassName)}
          {...wrapperProps}
        >
          {header && renderHeader()}

          {(closeable || showCloseButton) && (
            <IconButton
              className={stylesheet.closeButton}
              onClick={onRequestClose}
              isStrokeBased
            >
              <StyledIcons.Dismiss color="slates.70" />
            </IconButton>
          )}

          {children}
        </Box>
      </ReactModal>
    </ModalContext.Provider>
  )
}

Modal.setAppElement = ReactModal.setAppElement
Modal.useModalContext = useModalContext
Modal.displayName = 'Modal'

Modal.defaultProps = {
  fullscreen: false,
  fullHeight: false,
  showCloseButton: true,
  scrollable: true,
  shouldFocusAfterRender: true,
  closeTimeoutMS: isTestEnv() ? 0 : 130,
  'data-testid': MODAL,
}
