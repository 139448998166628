import PropTypes from 'prop-types'
import { cond, always, T } from 'ramda'
import React, { PureComponent } from 'react'
import Dropzone from 'react-dropzone'
import { FormattedMessage, injectIntl } from 'react-intl'

import postcodesFileMessages from '../../../../modules/postcodesFile/postcodesFile.messages'
import SVGIcon_svg from './csvFileUploader.SVGicon_svg'
import messages from './csvFileUploader.messages'
export class CsvFileUploaderComponent extends PureComponent {
  static propTypes = {
    error: PropTypes.object,
    parseFile: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  }

  setError = (error) => this.props.onError(error)

  resetError = () => this.props.onError(null)

  handleDropzoneClick = (e) => {
    e.preventDefault()
  }

  handleDragEnter = () => {
    if (this.props.error) {
      this.resetError()
    }
  }

  handleDrop = (acceptedFiles = [], rejectedFiles = []) => {
    const { setError } = this
    const filesLength = rejectedFiles.length + acceptedFiles.length

    if (filesLength > 1) {
      setError(postcodesFileMessages.tooManyFilesError)
      return
    }

    if (rejectedFiles.length || acceptedFiles.length === 0) {
      setError(postcodesFileMessages.invalidFileError)
      return
    }

    const file = acceptedFiles[0]
    this.props.parseFile(file, { setError })
  }

  renderDropzoneContent = ({
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  }) => {
    const title = cond([
      [() => isDragActive, always(messages.dropHere)],
      [T, always(messages.title)],
    ])()

    return (
      <>
        <h3 className="csv-file-uploader__title">
          <FormattedMessage {...title} />
        </h3>
        <div className="csv-file-uploader" {...getRootProps()}>
          <input {...getInputProps()} />

          <div>
            <SVGIcon_svg />
          </div>

          <span className="csv-file-uploader__hint">
            <FormattedMessage
              {...messages.hint}
              values={{
                link: (
                  <a href="#" className="csv-file-button" onClick={open}>
                    <FormattedMessage {...messages.browse} />
                  </a>
                ),
              }}
            />
          </span>
        </div>
      </>
    )
  }

  render() {
    return (
      <Dropzone
        multiple={false}
        disableClick={null}
        onClick={this.handleDropzoneClick}
        onDrop={this.handleDrop}
        onDragEnter={this.handleDragEnter}
        accept=".csv"
      >
        {this.renderDropzoneContent}
      </Dropzone>
    )
  }
}

export const CsvFileUploader = injectIntl(CsvFileUploaderComponent)
