import PropTypes from 'prop-types'
import React, { PureComponent, Fragment } from 'react'
import Helmet from 'react-helmet'
import { IntlProvider, FormattedMessage } from 'react-intl'

import { translationMessages } from '../i18n'
import { Footer } from '../shared/components/footer'
import { Header } from '../shared/components/header'
import messages from './app.messages'

export class App extends PureComponent {
  static propTypes = {
    startup: PropTypes.func.isRequired,
    language: PropTypes.string,
    children: PropTypes.node,
  }

  componentDidMount() {
    this.props.startup()
  }

  render() {
    return (
      <IntlProvider
        locale={this.props.language}
        messages={translationMessages[this.props.language]}
      >
        <Fragment>
          <FormattedMessage {...messages.pageTitle}>
            {(pageTitle) => (
              <Helmet
                titleTemplate={`%s - ${pageTitle[0]}`}
                defaultTitle={pageTitle[0]}
              />
            )}
          </FormattedMessage>

          <Header />

          <div className="app__container">
            {React.Children.only(this.props.children)}
          </div>
          <Footer />
        </Fragment>
      </IntlProvider>
    )
  }
}
