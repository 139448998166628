import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import { RootRoutes } from '../../RootRoutes'

import { HOME_ROUTES } from './home.constants'
import HomeContainer from './home.container'
import { LearnMore } from './learnMore'
import { Report } from './report'

import { SiteInfo } from './siteInfo'

export * from './home.constants'

export class Home extends Component {
  render() {
    return (
      <HomeContainer>
        <RootRoutes>
          <Route exact path={HOME_ROUTES.siteInfo} element={<SiteInfo />} />

          <Route exact path={HOME_ROUTES.report} element={<Report />} />

          <Route exact path={HOME_ROUTES.learnMore} element={<LearnMore />} />
        </RootRoutes>
      </HomeContainer>
    )
  }
}
