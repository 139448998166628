import classnames from 'classnames'
import { List, Map } from 'immutable'
import PropTypes from 'prop-types'
import React, { Fragment, PureComponent } from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage } from 'react-intl'

import { Alert } from '../../../shared/components/alert'
import { NavigationButtons } from '../../../shared/components/navigationButtons'
import {
  renderWhenNotNil,
  renderWhenTrue,
} from '../../../shared/utils/rendering'
import { HOME_ROUTES } from '../home.constants'
import { ColumnSelector } from './columnSelector'
import { CsvFileUploader } from './csvFileUploader'
import { FileInfo } from './fileInfo'
import { PostCodesForm } from './postCodesForm'
import messages from './siteInfo.messages'

export class SiteInfo extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    submitForm: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    parseFile: PropTypes.func.isRequired,
    resetFile: PropTypes.func.isRequired,
    generateReport: PropTypes.func.isRequired,
    isFileSet: PropTypes.bool.isRequired,
    fileMeta: PropTypes.instanceOf(Map).isRequired,
    fileValues: PropTypes.instanceOf(List).isRequired,
    fileDataSize: PropTypes.number.isRequired,
    filePreview: PropTypes.instanceOf(List).isRequired,
    fileColumnNames: PropTypes.instanceOf(List).isRequired,
    postcodeColumn: PropTypes.number.isRequired,
    setPostcodeColumn: PropTypes.func.isRequired,
  }

  static onSubmit = (values, { setSubmitting, props }) => {
    const postCodes = values.postCodes
      .filter((postCode) => !!postCode)
      .map((postCode) => postCode.toUpperCase())
    props.generateReport(postCodes)
    setSubmitting(false)
  }

  state = {
    error: null,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  setError = (error) => this.setState({ error })

  submitCSVFilePostcodes = () => {
    const { generateReport, fileValues, navigate } = this.props
    const postCodes = fileValues
      .toArray()
      .map((postCode) => postCode.toUpperCase())
    generateReport(postCodes)
    navigate(HOME_ROUTES.report)
  }

  submitPostcodesForm = () => {
    this.props.submitForm()
  }

  handleCsvFileError = (error) => {
    this.setError(error)
  }

  handleNextClick = () => {
    if (this.props.isFileSet) {
      this.submitCSVFilePostcodes()
    } else {
      this.submitPostcodesForm()
    }
    this.props.navigate(HOME_ROUTES.learnMore)
  }

  handleBackClick = () => {
    this.props.resetFile()
  }

  renderFileInfo = renderWhenTrue(() => {
    const {
      fileMeta,
      filePreview,
      fileColumnNames,
      postcodeColumn,
      fileDataSize,
      setPostcodeColumn,
    } = this.props
    return (
      <Fragment>
        <div className="site-info__file-info">
          <h1 className="site-info__form-title">
            <FormattedMessage {...messages.fileInfoTitle} />
          </h1>
          <p className="site-info__form-subtitle">
            <FormattedMessage {...messages.fileInfoSubtitle} />
          </p>

          <FileInfo fileMeta={fileMeta} fileDataSize={fileDataSize} />
        </div>

        <div className="site-info__column-selector">
          <ColumnSelector
            filePreview={filePreview}
            fileColumnNames={fileColumnNames}
            postcodeColumn={postcodeColumn}
            setPostcodeColumn={setPostcodeColumn}
          />
        </div>
      </Fragment>
    )
  })

  renderError = renderWhenNotNil((error) => (
    <Alert className="site-info__error">
      <FormattedMessage {...error} />
    </Alert>
  ))

  renderPostCodesForm = renderWhenTrue(() => {
    const { error } = this.state
    const {
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      parseFile,
    } = this.props
    return (
      <Fragment>
        <div
          className={classnames('site-info__form', {
            'site-info__form--with-error': !!error,
          })}
        >
          {this.renderError(error)}
          <h1 className="site-info__form-title">
            <FormattedMessage {...messages.formTitle} />
          </h1>

          <p className="site-info__form-subtitle">
            <FormattedMessage {...messages.formSubtitle} />
          </p>

          <p className="site-info__form-header">
            <FormattedMessage {...messages.fileInfoHeader} />
          </p>

          <PostCodesForm
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
        </div>

        <hr className="site-info__break-line" />

        <div className="site-info__csv-form">
          <CsvFileUploader
            parseFile={parseFile}
            onError={this.handleCsvFileError}
            error={error}
          />
        </div>
      </Fragment>
    )
  })

  render() {
    const { isFileSet, intl } = this.props
    return (
      <div className="site-info__container">
        <Helmet title={intl.formatMessage(messages.pageTitle)} />

        {this.renderPostCodesForm(!isFileSet)}
        {this.renderFileInfo(isFileSet)}

        <NavigationButtons
          onBack={isFileSet ? this.handleBackClick : null}
          onNext={this.handleNextClick}
        />
      </div>
    )
  }
}
