import React from 'react'

const SVG = ({ className }) => (
  <svg
    className={className}
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1133 20.0115C15.6363 20.0115 20.1133 15.5345 20.1133 10.0115C20.1133 4.48847 15.6363 0.0114746 10.1133 0.0114746C4.59028 0.0114746 0.113281 4.48847 0.113281 10.0115C0.113281 15.5345 4.59028 20.0115 10.1133 20.0115Z"
      fill="#E8EEF2"
    />
    <path
      d="M10.1133 9.51147V14.5115"
      stroke="#6A8395"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1133 5.5215L10.1233 5.5105"
      stroke="#6A8395"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SVG
