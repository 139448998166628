/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  earnings: {
    id: 'graph.earnings',
    defaultMessage: '{earnings} / MW / year', // eslint-disable-line no-template-curly-in-string
  },
  potentialEarnings: {
    id: 'graph.potentialEarnings',
    defaultMessage: 'potential earnings',
  },
  programs: {
    id: 'graph.programs',
    defaultMessage:
      '{programs} {programs, plural, one {program} other {programs} }',
  },
  emptyPlaceholderTitle: {
    id: 'graph.emptyPlaceholderTitle',
    defaultMessage: 'No Eligible Programs Found',
  },
  emptyPlaceholderText: {
    id: 'graph.emptyPlaceholderText',
    defaultMessage:
      'The specified frequency or response time removes all eligible programs in your geography.',
  },
  noProgramsPlaceholderTitle: {
    id: 'graph.noProgramsPlaceholderTitle',
    defaultMessage: 'No Eligible Programs Found  in Your Geography',
  },
  noProgramsPlaceholderText: {
    id: 'graph.noProgramsPlaceholderText',
    defaultMessage:
      'Available programs are always changing so {link} to learn about any new programs expected in your area.',
  },
  noProgramsPlaceholderLink: {
    id: 'graph.noProgramsPlaceholderLink',
    defaultMessage: 'Talk to a Voltan',
  },
  noProgramsVoltusLink: {
    id: 'graph.noProgramsVoltusLink',
    defaultMessage: 'voltus.co',
  },
  noProgramsLinkedInLink: {
    id: 'graph.noProgramsLinkedInLink',
    defaultMessage: 'LinkedIn',
  },
  noProgramsTwitterLink: {
    id: 'graph.noProgramsTwitterLink',
    defaultMessage: 'Twitter',
  },
  noProgramsYouTubeLink: {
    id: 'graph.noProgramsYouTubeLink',
    defaultMessage: 'YouTube',
  },
})
