import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="33"
    height="42"
    viewBox="0 0 33 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.158 41.1H24.258V41V1V0.9H24.158H4.15796H4.05796V1V41V41.1H4.15796H24.158ZM22.5917 39.4985H5.72423V2.50154H22.5917V39.4985Z"
      fill="#36454F"
      stroke="#36454F"
      strokeWidth="0.2"
    />
    <path
      d="M27.4549 39.5238V39.4238H27.3549H1H0.9V39.5238V40.9991V41.0991H1H27.3549H27.4549V40.9991V39.5238Z"
      fill="#36454F"
      stroke="#36454F"
      strokeWidth="0.2"
    />
    <path
      d="M20.9999 16.7894H7.31567V4.15784H20.9999V16.7894ZM8.68849 15.4135H19.6271V5.54477H8.68849V15.4135Z"
      fill="#36454F"
    />
    <path
      d="M26.8712 12.7969H22.7639V14.2722H26.8712V12.7969Z"
      fill="#36454F"
    />
    <path
      d="M29.2743 34.4196C28.3263 34.4181 27.4176 34.0408 26.7473 33.3705C26.077 32.7002 25.6997 31.7915 25.6982 30.8435V23.4729H22.7476V22.0035H27.1558V30.8553C27.1539 31.2739 27.2772 31.6836 27.5097 32.0317C27.7423 32.3798 28.0736 32.6505 28.461 32.809C28.8485 32.9676 29.2745 33.0067 29.6844 32.9215C30.0942 32.8363 30.4693 32.6305 30.7614 32.3306C30.9561 32.1313 31.1086 31.8948 31.21 31.6352C31.3114 31.3757 31.3595 31.0984 31.3516 30.8199L31.0978 19.3184L32.5672 19.2771L32.8682 30.7786C32.8752 31.2537 32.7878 31.7255 32.611 32.1666C32.4342 32.6077 32.1716 33.0092 31.8383 33.348C31.5051 33.6867 31.1079 33.9559 30.6697 34.1398C30.2316 34.3238 29.7613 34.4189 29.2861 34.4196H29.2743Z"
      fill="#36454F"
    />
    <path
      d="M14.1578 34.6842C12.0629 34.6842 10.4736 33.0372 10.4736 31.0322C10.4736 29.242 13.1465 25.59 13.5799 24.8739L14.1578 24.1578L14.7358 24.8739C15.3137 25.59 17.8421 29.3136 17.8421 31.0322C17.8421 33.0372 16.1083 34.6842 14.1578 34.6842ZM14.1578 26.4493C13.1465 27.953 11.7739 30.1013 11.7739 31.0322C11.7739 32.3211 12.7853 33.3952 14.1578 33.3952C15.4582 33.3952 16.5417 32.3927 16.5417 31.0322C16.5417 30.1013 15.1692 28.0246 14.1578 26.4493Z"
      fill="#36454F"
    />
    <path
      d="M32.5009 20.7691H30.5063C29.4665 20.7675 28.4699 20.3534 27.7352 19.6176C27.0005 18.8818 26.5879 17.8845 26.5879 16.8448V12.7434L31.1731 14.4843L31.2381 14.5256C31.5213 14.7085 32.9199 15.8357 32.5776 20.0904L32.5009 20.7691ZM28.0337 14.8797V16.8448C28.0337 17.1684 28.0978 17.4888 28.2223 17.7874C28.3469 18.0861 28.5294 18.3572 28.7593 18.5849C28.9892 18.8126 29.262 18.9925 29.5619 19.1142C29.8617 19.2358 30.1827 19.2969 30.5063 19.2938H31.0964C31.1318 17.7772 30.9076 16.1897 30.4414 15.7943L28.0337 14.8797Z"
      fill="#36454F"
    />
  </svg>
)
