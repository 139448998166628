import React from 'react'

import createSVGComponent from './createSVGComponent'

export default createSVGComponent(
  <svg
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M13.1082 6.76523L13.7832 7.44023C14.069 7.72604 14.069 8.18821 13.7832 8.47098L7.87539 14.3818C7.58957 14.6676 7.12741 14.6676 6.84464 14.3818L0.936825 8.47402C0.651011 8.18821 0.651011 7.72604 0.936825 7.44327L1.61183 6.76827C1.90068 6.47941 2.37197 6.48549 2.65474 6.78043L6.14227 10.4413L6.14227 1.70269C6.14227 1.2983 6.46761 0.972956 6.872 0.972956H7.84498C8.24938 0.972956 8.57472 1.2983 8.57472 1.70269L8.57472 10.4413L12.0653 6.77739C12.3481 6.47941 12.8193 6.47333 13.1082 6.76523Z"
        fill="#36454F"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.04094 18H0.66628C0.29705 18 0 17.6746 0 17.2702V16.2972C0 15.8928 0.29705 15.5675 0.66628 15.5675L8.64498 15.5675V15.5676H14.0196C14.3889 15.5676 14.6859 15.8929 14.6859 16.2973V17.2703C14.6859 17.6747 14.3889 18 14.0196 18H6.04094V18Z"
      fill="#36454F"
    />
    <defs>
      <clipPath id="clip0">
        <rect
          x="14.1692"
          y="15.5676"
          width="13.6217"
          height="15.5677"
          transform="rotate(-180 14.1692 15.5676)"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
)
