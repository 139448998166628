const urls = {
  home: 'https://www.voltus.co',
  hwh: 'https://www.voltus.co/how-we-help',
  wwh_c: 'https://www.voltus.co/our-customers',
  wwh_p: 'https://www.voltus.co/partners',
  abt_m: 'https://www.voltus.co/about/mission',
  abt_t: 'https://www.voltus.co/about/team',
  abt_j: 'https://www.voltus.co/join-us',
  lrn_v: 'https://www.voltus.co/vlog',
  lrn_e: 'https://www.voltus.co/event',
  val: 'https://voltapp.voltus.co/auth/login',
}
export default urls
