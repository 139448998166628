import { createSelector } from 'reselect'

export const selectContactDomain = (state) => state.get('contact')

export const selectSendSuccess = createSelector(selectContactDomain, (state) =>
  state.get('sendSuccess')
)

export const selectFormError = createSelector(selectContactDomain, (state) =>
  state.get('formError')
)
