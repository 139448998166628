import PropTypes from 'prop-types'
import { includes } from 'ramda'
import React, { PureComponent } from 'react'

import { Banner } from '../../shared/components/banner'
import { Loader } from '../../shared/components/loader'
import { Navigation } from '../../shared/components/navigation'
import { renderWhenTrue } from '../../shared/utils/rendering'
import { HOME_ROUTES } from './home.constants'
import messages from './home.messages'

export class Home extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isLoadingReportsData: PropTypes.bool.isRequired,
    isReportSaved: PropTypes.bool.isRequired,
    reportHasData: PropTypes.bool.isRequired,
    children: PropTypes.node,
  }

  get navigationItems() {
    const {
      isReportSaved,
      isLoadingReportsData,
      reportHasData,
      location: { pathname },
    } = this.props
    return [
      {
        pathname: HOME_ROUTES.siteInfo,
        label: this.props.intl.formatMessage(messages.siteInfo),
        active: this.isItemActive(HOME_ROUTES.siteInfo),
        visited: includes(pathname, [
          HOME_ROUTES.learnMore,
          HOME_ROUTES.report,
        ]),
      },
      {
        pathname: HOME_ROUTES.learnMore,
        label: this.props.intl.formatMessage(messages.learnMore),
        active: this.isItemActive(HOME_ROUTES.learnMore),
        visited:
          (this.isItemActive(HOME_ROUTES.learnMore) && isReportSaved) ||
          includes(pathname, [HOME_ROUTES.report]),
      },
      {
        pathname: HOME_ROUTES.report,
        label: this.props.intl.formatMessage(messages.report),
        active: this.isItemActive(HOME_ROUTES.report),
        visited:
          this.isItemActive(HOME_ROUTES.report) &&
          !isLoadingReportsData &&
          reportHasData,
      },
    ]
  }

  isItemActive = (pathname) => pathname === this.props.location.pathname

  renderLoader = renderWhenTrue(() => (
    <div className="home__loader">
      <Loader />
    </div>
  ))

  render() {
    return (
      <div className="home__container">
        <Banner />
        <div className="home__component-wrapper">
          <Navigation
            location={this.props.location}
            items={this.navigationItems}
          />
          <hr className="home__break-line" />
          {React.Children.only(this.props.children)}
        </div>
      </div>
    )
  }
}
