import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.3107 13.4414L19.4466 0.224243L21.8786 2.6563L6.3107 18.2242L0.121582 12.0351L2.47234 9.60306L6.3107 13.4414Z"
      fill="white"
    />
  </svg>
)
