/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  siteInfo: {
    id: 'home.siteInfo',
    defaultMessage: 'Site info',
  },
  report: {
    id: 'home.report',
    defaultMessage: 'Cashdash earnings report',
  },
  learnMore: {
    id: 'home.learnMore',
    defaultMessage: 'Learn more',
  },
})
