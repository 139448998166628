import noop from 'lodash/noop'
import * as React from 'react'

type ScrollOffsetSetter = (pos: string | number) => void

type ModalContextType = {
  setScrollOffset: ScrollOffsetSetter
}
const context = React.createContext({
  setScrollOffset: noop,
})

type ModalContextProviderProps = {
  children: React.ReactNode
  setScrollOffset: ScrollOffsetSetter
  [key: string]: unknown
}

function MobileContextProvider({
  children,
  setScrollOffset,
  ...props
}: ModalContextProviderProps): JSX.Element {
  const contextValue = React.useRef<ModalContextType>({ setScrollOffset: noop })
  React.useEffect(() => {
    contextValue.current = {
      setScrollOffset,
    }
  }, [setScrollOffset])
  return (
    <context.Provider value={contextValue.current} {...props}>
      {children}
    </context.Provider>
  )
}

const ModalContext = {
  Provider: MobileContextProvider,
  Consumer: context.Consumer,
}

const useModalContext = (): ModalContextType =>
  React.useContext<ModalContextType>(context)

export { ModalContext, useModalContext }
