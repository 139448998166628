import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#36454F"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.53366 5.84086L0.193175 1.44079C-0.0643917 1.22858 -0.0643917 0.884535 0.193175 0.672347L0.816057 0.159153C1.07318 -0.0526956 1.48991 -0.0531032 1.74765 0.158247L6.00001 3.64538L10.2524 0.158247C10.5101 -0.0531032 10.9268 -0.0526956 11.1839 0.159153L11.8068 0.672347C12.0644 0.884558 12.0644 1.2286 11.8068 1.44079L6.46637 5.84086C6.2088 6.05305 5.79122 6.05305 5.53366 5.84086Z"
    />
  </svg>
)
