import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0001 8V12L14.0001 14M3.05005 11C3.27414 8.80006 4.30031 6.75962 5.93278 5.26797C7.56525 3.77633 9.68974 2.93789 11.9009 2.91264C14.1121 2.88738 16.2552 3.67707 17.9213 5.13104C19.5874 6.58501 20.6599 8.60149 20.9342 10.7957C21.2085 12.99 20.6654 15.2084 19.4084 17.0278C18.1515 18.8471 16.2687 20.14 14.1193 20.6599C11.97 21.1797 9.70446 20.89 7.75504 19.8461C5.80563 18.8022 4.30877 17.0771 3.55005 15M3.05005 20V15H8.05005"
      stroke="#536373"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
