import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="#2c3e50"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M20.942 13.021a9 9 0 1 0 -9.407 7.967" />
    <path d="M12 7v5l3 3" />
    <path d="M15 19l2 2l4 -4" />
  </svg>
)
