import { noop } from 'lodash'
import * as React from 'react'
import ReactDOM from 'react-dom'
import ReactFocusLock from 'react-focus-lock'
import logger from '@voltus/logger'
import { AnyObject, ObjectValues } from '@voltus/types'
import {
  keyboard,
  renderNode,
  stopProp,
  useKeyboardHandler,
} from '@voltus/utils'

import { StyledProps } from '../../utils/styledSystem'
import { Box } from '../Box'
import { Modal, ModalProps } from '../Modal'
import Tooltip, { DEFAULT_Z_INDEX, TooltipProps } from './Tooltip'

/**
 * A wrapper component to easily render a tooltip based on an interaction
 * with the passed in child component. E.g.
 *
 * ```jsx
 * <WithTooltip trigger="hover" content={() => <Text>Some Content</Text>}>
 *  <Text>Some text that will render a tooltip when hovered</Text>
 * </WithTooltip>
 * ```
 */

export type WithTooltipCallbacks = {
  openTooltip: () => void
  closeTooltip: () => void
  focus: () => void
  blur: () => void
  tooltipRef: React.MutableRefObject<HTMLElement | undefined>
}

type ContentProps = {
  close: () => void
}

export interface WithTooltipProps
  extends Omit<TooltipProps, 'children' | 'content'> {
  as?: string
  closeOnEsc?: boolean
  contentContainerStyle?: StyledProps
  /**
   * If you want to conditionally render the tooltip, you can pass `enabled = true` or `enabled = false`
   * When `enabled = false`, the children will render, but the tooltip will not be rendered
   */
  enabled?: boolean
  content: React.ReactNode | ((contentProps: ContentProps) => React.ReactNode)
  openDelay?: number
  closeDelay?: number
  isDisabled?: boolean
  trigger?: ObjectValues<typeof Triggers>
  children:
    | React.ReactElement
    | (({ isOpen }: { isOpen: boolean }) => React.ReactElement)
  stopPropOnClick?: boolean
  preventDefaultOnClick?: boolean
  lockFocus?: boolean
  containerProps?: AnyObject
  mountAt?: Element | null
  useModalOnMobile?: boolean
  mobileBreakpoint?: number
  modalProps?: Omit<Partial<ModalProps>, 'onReqestClose' | 'isOpen'>
  // Makes the tooltip act sort of like a modal
  // Can be controlled by passing the following props
  isOpen?: boolean
  onRequestClose?: () => void
  tooltipRef?: React.MutableRefObject<HTMLElement | undefined>
  // A ref used to expose callbacks that allow a parent component to manually open and close the tooltip
  // via the openTooltip() and closeTooltip() methods on the { openTooltip, closeTooltip } ref object.
  callbacksRef?:
    | ((callbacks: WithTooltipCallbacks) => void)
    | React.MutableRefObject<WithTooltipCallbacks | undefined>
}

export const Triggers = {
  Click: 'click',
  Hover: 'hover',
} as const

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

export const WithTooltip = React.forwardRef<HTMLElement, WithTooltipProps>(
  (
    {
      as = 'div',
      closeOnEsc = true,
      enabled = true,
      openDelay = 0,
      closeDelay = 0,
      content,
      isDisabled = false,
      trigger = Triggers.Hover,
      children,
      lockFocus = false,
      containerProps = {},
      isOpen = false,
      onRequestClose = noop,
      stopPropOnClick = true,
      preventDefaultOnClick = true,
      mountAt,
      useModalOnMobile,
      mobileBreakpoint,
      modalProps,
      callbacksRef,
      tooltipRef,
      contentContainerStyle = {},
      zIndex = DEFAULT_Z_INDEX,
      ...props
    }: WithTooltipProps,
    ref: React.MutableRefObject<HTMLElement>
  ) => {
    const mountLocation = mountAt ?? (() => document.body)()
    const id = React.useId()
    const internalTrigger = isTouchDevice() ? Triggers.Click : trigger
    const tooltipRefInternal = React.useRef<HTMLElement>()
    React.useImperativeHandle(tooltipRef, () => tooltipRefInternal.current)
    const containerRef = React.useRef<HTMLElement>()
    const anchor = React.useRef()
    const [open, setOpen] = React.useState(false)
    const [hovering, setHovering] = React.useState(false)
    const hoverRef = React.useRef(false)
    const tooltipIsOpen = open || isOpen
    const child =
      typeof children === 'function'
        ? children({ isOpen: tooltipIsOpen })
        : children

    const Child = React.cloneElement(React.Children.only(child), {
      ref: (r) => {
        anchor.current = r
        if (ref) {
          ref.current = r
        }
      },
    })

    // Exposes callbacks to open and close the tooltip from a parent component
    React.useEffect(() => {
      const openTooltip = () => setOpen(true)
      const closeTooltip = () => setOpen(false)
      if (callbacksRef) {
        if (typeof callbacksRef === 'function') {
          callbacksRef({
            openTooltip,
            closeTooltip,
            tooltipRef: tooltipRefInternal,
            focus: () => {
              tooltipRefInternal.current?.focus()
            },
            blur: () => containerRef.current?.blur(),
          })
        } else {
          callbacksRef.current = {
            openTooltip,
            closeTooltip,
            tooltipRef: tooltipRefInternal,
            focus: () => containerRef.current?.focus(),
            blur: () => containerRef.current?.blur(),
          }
        }
      }
    }, [setOpen, callbacksRef])

    React.useLayoutEffect(() => {
      if (hovering) {
        hoverRef.current = true
      } else {
        hoverRef.current = false
      }
    }, [hovering])

    useKeyboardHandler(
      (evt) => {
        if (keyboard.isEscapeKey(evt) && closeOnEsc) {
          const tooltips = Array.from(
            mountLocation.querySelectorAll('[data-tooltipid]')
          )
          const lastTooltipId =
            tooltips[tooltips.length - 1]?.getAttribute('data-tooltipid')
          if (tooltips.length <= 1) {
            setOpen(false)
            return
          }

          if (id === lastTooltipId) {
            setOpen(false)
          }
        }
      },
      [id]
    )

    const openTimeoutRef = React.useRef<ReturnType<typeof setTimeout>>()

    const makeTriggerHandlers = React.useCallback(() => {
      if (!enabled) {
        return {}
      }

      type HoverTriggerHandlers = {
        onPointerEnter: (event: React.MouseEvent) => void
        onPointerLeave: (event: React.MouseEvent) => void
      }
      type ClickTriggerHandlers = {
        onClick: (event: React.MouseEvent) => void
      }

      if (internalTrigger === Triggers.Hover) {
        return {
          onClick: (e) => {
            if (preventDefaultOnClick) {
              e.preventDefault()
            }
            if (stopPropOnClick) {
              e.stopPropagation()
            }
            setOpen((state) => !state)
          },
          onPointerEnter: (): void => {
            setHovering(true)
            if (openDelay > 0) {
              openTimeoutRef.current = setTimeout(() => {
                if (hoverRef.current) {
                  setOpen(true)
                  openTimeoutRef.current = undefined
                }
              }, openDelay)
            } else {
              setOpen(true)
            }
          },
          onPointerLeave: (): void => {
            setHovering(false)
            if (closeDelay > 0) {
              // Clear any opening timeout if it exists
              if (openTimeoutRef.current) {
                clearTimeout(openTimeoutRef.current)
                openTimeoutRef.current = undefined
              }
              setTimeout(() => {
                if (hoverRef.current === false) {
                  setOpen(false)
                }
              }, closeDelay)
            } else {
              setOpen(false)
              if (openTimeoutRef.current) {
                clearTimeout(openTimeoutRef.current)
                openTimeoutRef.current = undefined
              }
            }
          },
        } as HoverTriggerHandlers
      } else if (internalTrigger === 'click') {
        return {
          onClick: (e: React.MouseEvent) => {
            if (preventDefaultOnClick) {
              e.preventDefault()
            }
            if (stopPropOnClick) {
              e.stopPropagation()
            }
            setOpen((state) => !state)
          },
        } as ClickTriggerHandlers
      }
    }, [
      enabled,
      openDelay,
      closeDelay,
      preventDefaultOnClick,
      internalTrigger,
      stopPropOnClick,
    ])

    const close = React.useCallback(() => {
      setOpen(false)
    }, [setOpen])
    const renderContent = () => {
      return typeof content === 'function' ? content({ close }) : content
    }

    if (isDisabled) {
      return Child
    }

    if (
      internalTrigger === Triggers.Click &&
      useModalOnMobile &&
      !mobileBreakpoint
    ) {
      logger.once.warn(
        'If you are using WithTooltip with a Modal on mobile screens, you must pass a `mobileBreakpoint` prop'
      )
    }

    if (
      internalTrigger === Triggers.Click &&
      useModalOnMobile &&
      mobileBreakpoint &&
      window.innerWidth <= mobileBreakpoint
    ) {
      return (
        <>
          <Box {...containerProps} {...makeTriggerHandlers()} as={as}>
            {renderNode(Child, { isOpen: tooltipIsOpen })}
          </Box>
          {enabled ? (
            <Modal
              onRequestClose={() => {
                setOpen(false)
              }}
              isOpen={tooltipIsOpen}
              {...modalProps}
              overlayElement={(props, content) => {
                return (
                  <div
                    {...props}
                    onClick={(e) => {
                      e.stopPropagation()
                      props.onClick?.(e)
                    }}
                  >
                    {content}
                  </div>
                )
              }}
            >
              {renderContent()}
            </Modal>
          ) : null}
        </>
      )
    }

    return (
      <Box
        ref={containerRef}
        {...containerProps}
        {...makeTriggerHandlers()}
        as={as}
      >
        {internalTrigger === Triggers.Click && tooltipIsOpen
          ? ReactDOM.createPortal(
              <Box
                position="fixed"
                top={0}
                left={0}
                right={0}
                bottom={0}
                zIndex={zIndex}
                data-testid="tooltip-click-overlay"
                bg="transparent"
                onClick={(e) => {
                  e.stopPropagation()
                  e.nativeEvent.stopImmediatePropagation()
                  setOpen(false)
                  onRequestClose()
                }}
              />,
              mountLocation
            )
          : null}
        {Child}
        {tooltipIsOpen && enabled && (
          <Tooltip
            ref={tooltipRefInternal}
            onRequestClose={() => setOpen(false)}
            data-tooltipid={id}
            data-iswithtooltip
            mountAt={mountLocation}
            autoPointerEvents
            anchor={anchor.current}
            zIndex={zIndex}
            {...props}
          >
            <Box px={2} py={2} {...contentContainerStyle} onClick={stopProp()}>
              {lockFocus ? (
                <ReactFocusLock>{renderContent()}</ReactFocusLock>
              ) : (
                renderContent()
              )}
            </Box>
          </Tooltip>
        )}
      </Box>
    )
  }
)

WithTooltip.displayName = 'WithTooltip'

export default WithTooltip
