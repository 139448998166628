import qs from 'query-string'
import { HOME_ROUTES } from '../../routes/home'

const SALESFORCE_WEB_TO_LEAD_FORM_PATH =
  'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'

/**
 * from https://stackoverflow.com/a/133997
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

const submitForm = (path, params, method = 'post') => {
  const form = document.createElement('form')
  form.method = method
  form.action = path

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = key
      hiddenField.value = params[key]

      form.appendChild(hiddenField)
    }
  }

  document.body.appendChild(form)
  form.submit()
}

const generateReturnUrl = (reportToken) =>
  `${window.location.origin}${HOME_ROUTES.report}?${qs.stringify({
    reportToken,
  })}`
// Submits form data to Salesforce Web-to-Lead (https://www.salesforce.com/products/guide/lead-gen/web-to-lead/)
// Salesforce Web-to-Lead requires generating an HTML form that we submit to a given address.
// This utility dynamically generates and submits an HTML form resembling the Salesforce Web-to-Lead form.

export const submitSalesforceWebToLeadForm = ({
  firstName,
  lastName,
  email,
  phone,
  company,
  reportToken,
}) => {
  // Save the form success state in query string params so that we can redirect to the state that the user last saw.
  const returnUrl = generateReturnUrl(reportToken)
  submitForm(SALESFORCE_WEB_TO_LEAD_FORM_PATH, {
    first_name: firstName,
    last_name: lastName,
    email,
    phone,
    company,
    // Required default value for lead_source
    lead_source: 'Inbound - Website',
    // Required default value for oid
    oid: '00D50000000cFF4',
    // Salesforce requires that all leads have an address so we default to the Voltus company address.
    city: 'San Francisco',
    country_code: 'US',
    state_code: 'CA',
    street: '2443 Fillmore Street',
    zip: '94115',
    // Required field for Lead Source List Name
    '00N5000000AYJqn': 'Estimate Your Earnings',
    // returnUrl is the url that this form will redirect to after it submits to Salesforce.
    retURL: returnUrl,
  })
}
