import { List } from 'immutable'
import PropTypes from 'prop-types'
import { is, find } from 'ramda'
import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import Select from 'react-select'
import { Option } from './option'
import { SingleValue } from './singleValue'

export class DropdownComponent extends PureComponent {
  static propTypes = {
    emptyValuePlaceholder: PropTypes.node,
    isSearchable: PropTypes.bool,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)])
      .isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isSearchable: false,
  }

  render() {
    const { options, value, ...props } = this.props
    const rawOptions = is(List, options) ? options.toJS() : options
    const valueOption = find((option) => option.value === value, rawOptions)
    return (
      <Select
        className="dropdown__container"
        classNamePrefix="dropdown"
        {...props}
        components={{
          Option,
          SingleValue,
        }}
        value={valueOption}
        options={rawOptions}
      />
    )
  }
}

export const Dropdown = injectIntl(DropdownComponent)
