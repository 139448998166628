import { withFormik } from 'formik'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import * as Yup from 'yup'

import { ContactActions } from '../../modules/contact'
import {
  selectFormError,
  selectSendSuccess,
} from '../../modules/contact/contact.selectors'
import {
  CONTACT_FORM_INITIAL_VALUES,
  CONTACT_FORM_VALIDATION,
} from '../../shared/components/contactForm/contactForm.constants'
import { Contact } from './contact.component'

const mapStateToProps = createStructuredSelector({
  sendSuccess: selectSendSuccess,
  formError: selectFormError,
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendContact: ContactActions.sendContact,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  injectIntl(
    withFormik({
      handleSubmit: Contact.onSubmit,
      validateOnBlur: true,
      validationSchema: Yup.object().shape(CONTACT_FORM_VALIDATION),
      mapPropsToValues: () => CONTACT_FORM_INITIAL_VALUES,
    })(Contact)
  )
)
