import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
      fill="#E0E0E0"
    />
    <path
      d="M10.6779 20.5852V25H8.56001V17.3636H10.5785V18.7109H10.668C10.8403 18.2668 11.1237 17.9155 11.5181 17.657C11.9158 17.3951 12.3981 17.2642 12.9648 17.2642C13.7603 17.2642 14.3983 17.5211 14.8789 18.0348C15.3595 18.5452 15.5998 19.2462 15.5998 20.1378V25H13.4819V20.5156C13.4852 20.0483 13.3659 19.6837 13.1239 19.4219C12.882 19.1567 12.5489 19.0241 12.1246 19.0241C11.6971 19.0241 11.3491 19.1617 11.0806 19.4368C10.8155 19.7086 10.6812 20.0914 10.6779 20.5852ZM21.7037 14.3409L18.4224 26.5312H16.5978L19.8791 14.3409H21.7037ZM24.9016 25.1442C24.1725 25.1442 23.5676 24.9536 23.087 24.5724C22.6064 24.1913 22.3661 23.6229 22.3661 22.8672C22.3661 22.2971 22.5004 21.8497 22.7688 21.5249C23.0373 21.2 23.3886 20.9631 23.8228 20.8139C24.2603 20.6615 24.7309 20.562 25.2347 20.5156C25.8976 20.4493 26.3716 20.3797 26.6566 20.3068C26.9416 20.2339 27.0842 20.0748 27.0842 19.8295V19.7997C27.0842 19.4848 26.9847 19.2412 26.7859 19.0689C26.5903 18.8965 26.3119 18.8104 25.9506 18.8104C25.5695 18.8104 25.2662 18.8932 25.0408 19.0589C24.8155 19.2247 24.6663 19.4351 24.5934 19.6903L22.6346 19.5312C22.7804 18.8352 23.1417 18.2834 23.7184 17.8757C24.2984 17.468 25.0458 17.2642 25.9606 17.2642C26.5273 17.2642 27.0576 17.3537 27.5515 17.5327C28.0453 17.7116 28.4431 17.9917 28.7447 18.3729C29.0496 18.7507 29.2021 19.2429 29.2021 19.8494V25H27.1935V23.9411H27.1339C26.9483 24.2957 26.6699 24.5857 26.2987 24.8111C25.9308 25.0331 25.4651 25.1442 24.9016 25.1442ZM25.5082 23.6825C25.9722 23.6825 26.3533 23.5483 26.6516 23.2798C26.9499 23.008 27.0991 22.6716 27.0991 22.2706V21.4602C27.0162 21.5166 26.887 21.5663 26.7113 21.6094C26.5389 21.6525 26.3533 21.6906 26.1545 21.7237C25.9589 21.7536 25.7833 21.7801 25.6275 21.8033C25.2563 21.8563 24.9563 21.9607 24.7276 22.1165C24.5022 22.2723 24.3896 22.4993 24.3896 22.7976C24.3896 23.0893 24.494 23.3097 24.7028 23.4588C24.9149 23.608 25.1834 23.6825 25.5082 23.6825Z"
      fill="#626E76"
    />
  </svg>
)
