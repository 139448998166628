import React from 'react'
import { Route, Routes } from 'react-router-dom'
import App from './app.container'
import { Contact, CONTACT_ROUTE } from './contact'
import { Home, HOME_ROUTES } from './home'
import { NotFound } from './notFound'

export * from './app.constants'

export default () => {
  return (
    <App>
      <Routes>
        <Route path={HOME_ROUTES.learnMore} element={<Home />} />
        <Route path={HOME_ROUTES.siteInfo} element={<Home />} />
        <Route path={HOME_ROUTES.report} element={<Home />} />

        <Route path={CONTACT_ROUTE} element={<Contact />} />

        <Route element={<NotFound />} />
      </Routes>
    </App>
  )
}
