import { List } from 'immutable'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Dropdown } from '../../../../shared/components/forms/dropdown'
import messages from './columnSelector.messages'

export class ColumnSelectorComponent extends PureComponent {
  static propTypes = {
    filePreview: PropTypes.instanceOf(List).isRequired,
    fileColumnNames: PropTypes.instanceOf(List).isRequired,
    postcodeColumn: PropTypes.number.isRequired,
    setPostcodeColumn: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  }

  handleColumnChange = (option) => {
    this.props.setPostcodeColumn(option.value)
  }

  renderColumnDropdown = () => {
    const { fileColumnNames, postcodeColumn, intl } = this.props
    const options = fileColumnNames.map((name, index) => ({
      value: index,
      label: name,
    }))
    return (
      <Dropdown
        options={options}
        value={postcodeColumn}
        onChange={this.handleColumnChange}
        emptyValuePlaceholder={intl.formatMessage(
          messages.emptyTitlePlaceholder
        )}
      />
    )
  }

  render() {
    const { filePreview } = this.props
    return (
      <div className="column-selector">
        <div className="column-selector__title column-selector__section">
          <FormattedMessage {...messages.title} />
        </div>
        <div className="column-selector__input-container column-selector__section">
          <div className="column-selector__label">
            <FormattedMessage {...messages.ourBestGuess} />
          </div>
          {this.renderColumnDropdown()}
        </div>
        <div className="column-selector__preview column-selector__section">
          <div className="column-selector__label">
            <FormattedMessage {...messages.exampleValues} />
          </div>
          <ul className="column-selector__values">
            {filePreview.map((value, index) => (
              <li key={index} className="column-selector__value">
                {value}
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

export const ColumnSelector = injectIntl(ColumnSelectorComponent)
