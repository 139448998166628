import React from 'react'

import createSVGComponent from './createSVGComponent'

export default createSVGComponent(
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="12" fill="white" />
    <rect width="16" height="11" fill="white" />
    <rect width="9" height="6.46" fill="#41479B" />
    <rect x="1" y="0.924734" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="3" y="0.924734" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="5" y="0.924734" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="7" y="0.924734" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="1" y="2.77134" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="3" y="2.77134" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="5" y="2.77134" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="2" y="3.69465" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="4" y="3.69465" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="6" y="3.69465" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="2" y="1.84804" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="4" y="1.84804" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="6" y="1.84804" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="7" y="2.77134" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="1" y="4.61795" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="3" y="4.61795" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="5" y="4.61795" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="7" y="4.61795" width="1" height="0.922857" fill="#C5D0EC" />
    <rect x="9" width="7" height="0.923077" fill="#DC251C" />
    <rect x="9" y="1.84766" width="7" height="0.923077" fill="#DC251C" />
    <rect x="9" y="3.69141" width="7" height="0.923077" fill="#DC251C" />
    <rect x="9" y="5.53906" width="7" height="0.923077" fill="#DC251C" />
    <rect y="7.38281" width="16" height="0.923077" fill="#DC251C" />
    <rect y="9.23047" width="16" height="0.923077" fill="#DC251C" />
    <rect y="11.0781" width="16" height="0.923077" fill="#DC251C" />
  </svg>
)
