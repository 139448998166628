import filesize from 'filesize'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import fileIcon from '../../../../images/sprites/fileIcon.png'
import fileTick from '../../../../images/sprites/fileTick.png'
import messages from './fileInfo.messages'

export class FileInfoComponent extends PureComponent {
  static propTypes = {
    fileMeta: PropTypes.instanceOf(Map).isRequired,
    fileDataSize: PropTypes.number.isRequired,
  }

  render() {
    const { fileMeta, fileDataSize } = this.props

    return (
      <div className="file-info__container">
        <div className="file-info__background">
          <div className="file-details">
            <img className="file-details__icon" src={fileTick} />
            <div className="file-details__title">{fileMeta.get('name')}</div>
            <div className="file-details__size">
              <img src={fileIcon} />
              {filesize(fileMeta.get('size'))}
            </div>
          </div>
        </div>

        <div className="file-info__sites-count">
          <FormattedMessage
            {...messages.sitesCount}
            values={{
              count: (
                <strong className="file-info__sites-count-value">
                  <FormattedMessage
                    {...messages.sitesCountValue}
                    values={{ count: fileDataSize }}
                  />
                </strong>
              ),
            }}
          />
        </div>
      </div>
    )
  }
}

export const FileInfo = injectIntl(FileInfoComponent)
