import React from 'react'
import { HexColorString } from '@voltus/types'
import colors from '../constants/colors.json'
import createSVGComponent from './createSVGComponent'

type EyeProps = {
  [key: string]: unknown
  color?: HexColorString | string
}

export default createSVGComponent(
  ({ color = colors.grays[20], ...props }: EyeProps): JSX.Element => (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 6C16.5825 2.4752 13.098 0 9 0C4.90196 0 1.41667 2.4752 0 6C1.41748 9.5248 4.90196 12 9 12C13.098 12 16.5833 9.5248 18 6ZM8.99941 10.0127C11.2592 10.0127 13.0974 8.21269 13.0974 5.99989C13.0974 3.78709 11.2592 1.98709 8.99941 1.98709C6.7396 1.98709 4.90137 3.78709 4.90137 5.99989C4.90137 8.21269 6.7396 10.0127 8.99941 10.0127ZM9.00078 3.59979C10.3415 3.59979 11.4518 4.68699 11.4518 5.99979C11.4518 7.31259 10.3415 8.39979 9.00078 8.39979C7.6601 8.39979 6.5498 7.31259 6.5498 5.99979C6.5498 4.68699 7.6601 3.59979 9.00078 3.59979Z"
          fill={color}
        />
      </g>
    </svg>
  )
)
