import { withFormik } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import * as Yup from 'yup'

import {
  ReportsActions,
  selectReportSaveError,
  selectReportSaveSuccess,
  selectReportsData,
  selectReportToken,
  selectReportsFrequency,
  selectReportsResponseTime,
} from '../../../modules/reports'
import {
  CONTACT_FORM_INITIAL_VALUES,
  CONTACT_FORM_VALIDATION,
} from '../../../shared/components/contactForm/contactForm.constants'
import { LearnMore } from './learnMore.component'

const mapStateToProps = createStructuredSelector({
  formError: selectReportSaveError,
  saveSuccess: selectReportSaveSuccess,
  reportToken: selectReportToken,
  reportsData: selectReportsData,
  frequency: selectReportsFrequency,
  responseTime: selectReportsResponseTime,
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveReport: ReportsActions.save,
      setReportToken: ReportsActions.saveSuccess,
      setFrequency: ReportsActions.setFrequency,
      setResponseTime: ReportsActions.setResponseTime,
      generateReport: ReportsActions.generate,
    },
    dispatch
  )

const BaseLearnMore = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const intl = useIntl()
  return (
    <LearnMore
      {...props}
      navigate={navigate}
      intl={intl}
      location={location}
      searchParams={searchParams}
    />
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    handleSubmit: LearnMore.onSubmit,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: Yup.object().shape(CONTACT_FORM_VALIDATION),
    mapPropsToValues: () => CONTACT_FORM_INITIAL_VALUES,
  })(BaseLearnMore)
)
