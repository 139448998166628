import {
  dispatchFrequencyMessages,
  responseTimeMessages,
} from './reports.messages'

export const ZIP_CODE_PATTERN =
  /^\d{5}(?:[-\s]\d{4})?|[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/

export const MIN_PROCESSING_TIME = 5000

export const DISPATCH_FREQUENCY_RANGE_VALUES = [
  {
    value: 0,
    label: dispatchFrequencyMessages.frequencyFirstLabel,
    title: dispatchFrequencyMessages.frequencyFirstTitle,
  },
  {
    value: 1,
    label: dispatchFrequencyMessages.frequencySecondLabel,
    title: dispatchFrequencyMessages.frequencySecondTitle,
  },
  {
    value: 2,
    label: dispatchFrequencyMessages.frequencyThirdLabel,
    title: dispatchFrequencyMessages.frequencyThirdTitle,
  },
]

export const RESPONSE_TIME_RANGE_VALUES = [
  {
    value: 0,
    label: responseTimeMessages.responseFirstLabel,
    title: responseTimeMessages.responseFirstTitle,
  },
  {
    value: 1,
    label: responseTimeMessages.responseSecondLabel,
    title: responseTimeMessages.responseSecondTitle,
  },
  {
    value: 2,
    label: responseTimeMessages.responseThirdLabel,
    title: responseTimeMessages.responseThirdTitle,
  },
]

export const STATES = {
  Alabama: { code: 'AL' },
  Alaska: { code: 'AK' },
  Arizona: { code: 'AZ' },
  Arkansas: { code: 'AR' },
  California: { code: 'CA' },
  Colorado: { code: 'CO' },
  Connecticut: { code: 'CT' },
  Delaware: { code: 'DE' },
  'District of Columbia': { code: 'DC' },
  Florida: { code: 'FL' },
  Georgia: { code: 'GA' },
  Hawaii: { code: 'HI' },
  Idaho: { code: 'ID' },
  Illinois: { code: 'IL' },
  Indiana: { code: 'IN' },
  Iowa: { code: 'IA' },
  Kansas: { code: 'KS' },
  Kentucky: { code: 'KY' },
  Louisiana: { code: 'LA' },
  Maine: { code: 'ME' },
  Maryland: { code: 'MD' },
  Massachusetts: { code: 'MA' },
  Michigan: { code: 'MI' },
  Minnesota: { code: 'MN' },
  Mississippi: { code: 'MS' },
  Missouri: { code: 'MO' },
  Montana: { code: 'MT' },
  Nebraska: { code: 'NE' },
  Nevada: { code: 'NV' },
  'New Hampshire': { code: 'NH' },
  'New Jersey': { code: 'NJ' },
  'New Mexico': { code: 'NM' },
  'New York': { code: 'NY' },
  'North Carolina': { code: 'NC' },
  'North Dakota': { code: 'ND' },
  Ohio: { code: 'OH' },
  Oklahoma: { code: 'OK' },
  Oregon: { code: 'OR' },
  Pennsylvania: { code: 'PA' },
  'Rhode Island': { code: 'RI' },
  'South Carolina': { code: 'SC' },
  'South Dakota': { code: 'SD' },
  Tennessee: { code: 'TN' },
  Texas: { code: 'TX' },
  Utah: { code: 'UT' },
  Vermont: { code: 'VT' },
  Virginia: { code: 'VA' },
  Washington: { code: 'WA' },
  'West Virginia': { code: 'WV' },
  Wisconsin: { code: 'WI' },
  Wyoming: { code: 'WY' },
}
