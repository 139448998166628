import { List } from 'immutable'
import { not, either, isEmpty, isNil } from 'ramda'
import { createSelector } from 'reselect'

export const selectPostcodesFileDomain = (state) => state.get('postcodesFile')

export const selectIsFileSet = createSelector(
  selectPostcodesFileDomain,
  (state) => state.get('isFileSet')
)

export const selectFileMeta = createSelector(
  selectPostcodesFileDomain,
  (state) => state.get('meta')
)

export const selectFileData = createSelector(
  selectPostcodesFileDomain,
  (state) => state.get('data')
)

export const selectColumnNames = createSelector(selectFileData, (state) =>
  state.first(List())
)

export const selectPostcodeColumn = createSelector(
  selectPostcodesFileDomain,
  (state) => state.get('postcodeColumn')
)

export const selectValues = createSelector(
  selectFileData,
  selectPostcodeColumn,
  (state, column) =>
    state
      .shift()
      .map((row) => row.get(column))
      .filter((item) => not(either(isEmpty, isNil)(item)))
)

export const selectDataSize = createSelector(
  selectValues,
  (state) => state.size
)

export const selectPreview = createSelector(selectValues, (state) =>
  state.take(3)
)
