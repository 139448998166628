import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="27"
    height="45"
    viewBox="0 0 27 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8.08921C1.35174 8.27295 1.76441 8.46347 2.24589 8.65187C4.36097 9.47951 7.79871 10.2647 13.2353 10.2647C18.6719 10.2647 22.1096 9.47951 24.2247 8.65187C24.7062 8.46347 25.1189 8.27295 25.4706 8.08921V41.106C25.4238 41.1529 25.3661 41.2076 25.2961 41.2687C24.9934 41.5328 24.4554 41.9226 23.5732 42.3259C21.8064 43.1335 18.6477 44 13.2353 44C7.8229 44 4.66417 43.1335 2.89738 42.3259C2.01522 41.9226 1.47717 41.5328 1.17454 41.2687C1.10454 41.2076 1.04679 41.1529 1 41.106V8.08921Z"
      stroke="#36454F"
      strokeWidth="2"
    />
    <path
      d="M13.6842 31.5263C11.5893 31.5263 10 29.8793 10 27.8743C10 26.0841 12.6729 22.4322 13.1063 21.7161L13.6842 21L14.2621 21.7161C14.84 22.4322 17.3684 26.1557 17.3684 27.8743C17.3684 29.8793 15.6347 31.5263 13.6842 31.5263ZM13.6842 23.2914C12.6729 24.7952 11.3003 26.9434 11.3003 27.8743C11.3003 29.1633 12.3117 30.2374 13.6842 30.2374C14.9845 30.2374 16.0681 29.2349 16.0681 27.8743C16.0681 26.9434 14.6956 24.8668 13.6842 23.2914Z"
      fill="#36454F"
    />
    <path
      d="M25.4706 3.97059C25.4706 4.034 25.4234 4.29513 24.852 4.70046C24.3065 5.08736 23.4523 5.47672 22.3067 5.82039C20.0283 6.50391 16.8208 6.94118 13.2353 6.94118C9.64975 6.94118 6.44226 6.50391 4.16388 5.82039C3.01832 5.47672 2.16411 5.08736 1.61863 4.70046C1.04716 4.29513 1 4.034 1 3.97059C1 3.90718 1.04716 3.64605 1.61863 3.24072C2.16411 2.85381 3.01832 2.46445 4.16388 2.12078C6.44226 1.43727 9.64975 1 13.2353 1C16.8208 1 20.0283 1.43727 22.3067 2.12078C23.4523 2.46445 24.3065 2.85381 24.852 3.24072C25.4234 3.64604 25.4706 3.90718 25.4706 3.97059Z"
      stroke="#36454F"
      strokeWidth="2"
    />
  </svg>
)
