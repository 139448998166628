import React from 'react'

import createSVGComponent from './createSVGComponent'

export default createSVGComponent(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0481 11.0278C16.3187 10.1175 17.1429 8.65243 17.1429 7C17.1429 4.23828 14.8406 2 12 2C9.15938 2 6.85714 4.23828 6.85714 7C6.85714 8.65243 7.68135 10.1175 8.95193 11.0278C8.99119 11.0559 9.03088 11.0835 9.07098 11.1106C9.31574 11.2757 9.57596 11.4207 9.84911 11.5431C10.5035 11.8364 11.2321 12 12 12C12.7668 12 13.4943 11.8369 14.148 11.5444C14.4222 11.4218 14.6834 11.2763 14.929 11.1106C14.9691 11.0835 15.0088 11.0559 15.0481 11.0278ZM8.29649 13.2509C5.36297 13.3046 3 15.6352 3 18.5V20.125C3 21.1602 3.86384 22 4.92857 22H19.0714C20.1362 22 21 21.1602 21 20.125V18.5C21 15.6352 18.637 13.3046 15.7035 13.2509C15.6691 13.2503 15.6346 13.25 15.6 13.25H14.929C14.0371 13.6484 13.0446 13.875 12 13.875C10.9554 13.875 9.96696 13.6484 9.07098 13.25H8.4C8.36542 13.25 8.33092 13.2503 8.29649 13.2509ZM8.6214 15.4722H8.4C6.62785 15.4722 5.25 16.8617 5.25 18.5V19.7778H18.75V18.5C18.75 16.8617 17.3721 15.4722 15.6 15.4722H15.3798C14.334 15.8727 13.1929 16.0972 12 16.0972C10.8047 16.0972 9.66674 15.8719 8.6214 15.4722ZM12 9.77778C13.6315 9.77778 14.8929 8.50156 14.8929 7C14.8929 5.49844 13.6315 4.22222 12 4.22222C10.3685 4.22222 9.10714 5.49844 9.10714 7C9.10714 8.50156 10.3685 9.77778 12 9.77778Z"
      fill="#536373"
    />
  </svg>
)
