import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { ALERT_TYPE_DANGER, ALERT_TYPES } from './Alert.constants'
import stylesheet from './Alert.scss'

export class Alert extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(ALERT_TYPES),
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    type: ALERT_TYPE_DANGER,
  }

  render() {
    const { type, children, className } = this.props
    return (
      <div
        className={classnames(className, stylesheet.alert, stylesheet[type])}
      >
        {children}
      </div>
    )
  }
}
