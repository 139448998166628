import React from 'react'

const SVG = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 107"
    height="100"
    width="107"
  >
    <rect
      className="cls-1"
      fill="#fff"
      x="16.7"
      y="8.1"
      width="73"
      height="86.9"
      rx="5"
    />
    <g>
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="25.1"
        y="18.2"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="25.1"
        y="28.7"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="44.9"
        y="18.2"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="44.9"
        y="28.7"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="64.6"
        y="18.2"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="64.6"
        y="28.7"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="25.1"
        y="39.2"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="44.7"
        y="39.2"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="64.3"
        y="39.2"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="25.1"
        y="60.2"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="44.7"
        y="60.2"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="64.3"
        y="60.2"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="25.1"
        y="49.7"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="44.7"
        y="49.7"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
      <rect
        className="cls-3"
        fill="#8dc63f"
        opacity="0.6"
        x="64.3"
        y="49.7"
        width="16.8"
        height="2.5"
        rx="1.2"
      />
    </g>
    <rect
      className="cls-3"
      fill="#8dc63f"
      x="6.2"
      y="47.7"
      width="68.1"
      height="32.2"
      rx="4"
    />
    <path
      className="cls-1"
      fill="#fff"
      d="M24 64a5.9 5.9 0 0 1 1.6-4.2 5.5 5.5 0 0 1 4.1-1.6 5.7 5.7 0 0 1 3.6 1.1 5 5 0 0 1 1.9 3.2H33a3.1 3.1 0 0 0-1.2-1.8 3.6 3.6 0 0 0-2.2-.7 3.4 3.4 0 0 0-2.7 1.1 5 5 0 0 0 0 6 3.4 3.4 0 0 0 2.7 1 3.6 3.6 0 0 0 2.2-.6 3.1 3.1 0 0 0 1.2-1.9h2a5 5 0 0 1-1.8 3.2 5.7 5.7 0 0 1-3.6 1.2 5.5 5.5 0 0 1-4.1-1.6A5.9 5.9 0 0 1 24 64Zm16.8 4.3a3.3 3.3 0 0 0 1.8-.4 1.3 1.3 0 0 0 .6-1.2 1.2 1.2 0 0 0-.3-1 2 2 0 0 0-1-.4l-1.2-.4-1.3-.3A4.8 4.8 0 0 1 38 64a2.4 2.4 0 0 1-.9-1 3.3 3.3 0 0 1-.3-1.6A3 3 0 0 1 38 59a4.4 4.4 0 0 1 3-1 4.2 4.2 0 0 1 3 1 3.8 3.8 0 0 1 1.1 2.8h-2a2 2 0 0 0-.5-1.5 2.2 2.2 0 0 0-1.7-.5 2.7 2.7 0 0 0-1.5.4 1.3 1.3 0 0 0-.6 1.1 1 1 0 0 0 .3.9 2.2 2.2 0 0 0 1 .4l1.2.4 1.4.3a4.7 4.7 0 0 1 1.2.6 2.6 2.6 0 0 1 1 1 3.3 3.3 0 0 1 .3 1.6A3 3 0 0 1 44 69a5.4 5.4 0 0 1-3.3 1 4.2 4.2 0 0 1-3.2-1.2 4 4 0 0 1-1-2.7h1.9a2 2 0 0 0 .7 1.5 2.6 2.6 0 0 0 1.7.6Zm13.8-9.9h2.1l-4.4 11.4h-2L46 58.4h2.2l3.2 8.8Z"
    />
  </svg>
)

export default SVG
