export const getScrollOffset = (): { x: number; y: number } => {
  const body = document.body
  const docEl = document.documentElement

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0
  return {
    x: scrollLeft - clientLeft,
    y: scrollTop - clientTop,
  }
}

export const getBoundingBox = (element: Element): DOMRect => {
  // crossbrowser version
  const box = element.getBoundingClientRect()
  const scrollOffset = getScrollOffset()

  const top = box.top + scrollOffset.y
  const left = box.left + scrollOffset.x

  const rect = {
    top: top,
    left: left,
    width: box.width,
    height: box.height,
    bottom: top + box.height,
    right: left + box.width,
    x: left,
    y: top,
  }
  return {
    ...rect,
    // To make typescript happy, implement the full interface of DOMRect
    toJSON: () => rect,
  }
}

export function checkInView(
  container: HTMLElement,
  element: HTMLElement,
  topPctVisible = 1,
  bottomPctVisible = 1
) {
  //Get container properties
  const cTop = container.scrollTop
  const cBottom = cTop + container.clientHeight

  //Get element properties
  const eTop = element.offsetTop - container.offsetTop
  const eBottom = eTop + element.clientHeight

  //Check if in view
  const isTotal = eTop >= cTop && eBottom <= cBottom
  if (isTotal) {
    return true
  }

  let diff = 0
  let pctVisible = 0

  if (eTop < cTop) {
    diff = eBottom - cTop
    pctVisible = diff / element.clientHeight
    if (pctVisible >= topPctVisible) {
      return true
    }
  }

  if (eBottom > cBottom) {
    const diff = cBottom - eTop
    pctVisible = diff / element.clientHeight
    if (pctVisible >= bottomPctVisible) {
      return true
    }
  }

  return false
}
