import CSS from 'csstype'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { AnyObject } from '@voltus/types'
import { domUtils, isTestEnv } from '@voltus/utils'
import shadowsJson from '../../constants/shadows.json'
import { usePointerContext } from '../../context/pointerContext'
import { StyledProps } from '../../utils/styledSystem'
import { Box } from '../Box'

const shadows = shadowsJson.shadows

type AnchorPointVertical = 'top' | 'middle' | 'bottom'
type AnchorPointHorizontal = 'left' | 'center' | 'right'
export type AnchorPointType = `${AnchorPointVertical}-${AnchorPointHorizontal}`
export type ContentAlignType = 'left' | 'center' | 'right'

export enum ContentAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum AnchorPoint {
  TopLeft = 'top-left',
  TopCenter = 'top-center',
  TopRight = 'top-right',
  MiddleLeft = 'middle-left',
  MiddleCenter = 'middle-center',
  MiddleRight = 'middle-right',
  BottomLeft = 'bottom-left',
  BottomCenter = 'bottom-center',
  BottomRight = 'bottom-right',
}

export type Point = {
  x: number
  y: number
}

export interface TooltipProps extends StyledProps {
  /**
   * If true, the tooltip will close when the anchor goes fully offscreen
   */
  closeOnAnchorExit?: boolean
  /**
   * Callback when the tooltip requests to close
   */
  onRequestClose?: () => void
  /**
   * If true, then the tooltip will continuously calculate the anchor
   * position
   */
  watchAnchor?: boolean
  /**
   * Enable/disable enter animation
   */
  isAnimated?: boolean
  /**
   * Set the bg color. By default bg is black
   * and text is white
   */
  bg?: string
  /**
   * Set the text color. By default bg is black
   * and text is white
   */
  color?: string
  /**
   * Set's display: fixed on the container element.
   * By default we use absolute position
   */
  fixed?: boolean
  /**
   * Tells the tooltip where to render in relation to the anchor
   */
  anchorPoint?: AnchorPointType
  /**
   * Aligns the tooltip content body to the left, center, or right
   * of the anchor point
   */
  contentAlign?: ContentAlignType
  /**
   * By default content inside the tooltip is not interactable.
   * Setting `autoPointerEvents={true}`
   */
  autoPointerEvents?: boolean
  /**
   * Content to render inside the tooltip
   */
  children?: React.ReactNode | null
  /**
   * DOM node to mount the tooltip into
   */
  mountAt?: Element | null
  /**
   * Offsets vertical position of the tooltip by a fixed amount
   */
  verticalOffset?: number
  getVerticalOffset?: (args: {
    anchorPosition: string
    vertical: AnchorPointVertical
    horizontal: AnchorPointHorizontal
    arrowCoordinates: Point
  }) => number
  /**
   * Offsets horizontal position of the tooltip by a fixed amount
   */
  horizontalOffset?: number
  getHorizontalOffset?: (args: {
    anchorPosition: string
    vertical: AnchorPointVertical
    horizontal: AnchorPointHorizontal
    arrowCoordinates: Point
  }) => number
  /**
   * When the tooltip collides with bottom of the window,
   * decide if it should stick to the bottom or if it should
   * reposition itself
   */
  rearrangeOnWindowCollision?: boolean
  /**
   * Enable/disable the arrow that points at the anchor
   */
  hideArrow?: boolean
  /**
   * Where to anchor the tooltip. Accepts a DOM node or
   * "mouse" to tell the tooltip to follow the cursor
   */
  anchor?: Element | 'mouse' | Point | null
  /**
   * z index of the container element
   */
  zIndex?: number
  /**
   * Configures white space wrapping
   * By default the tooltip forces no wrapping.
   * This encourages short concise content.
   *
   * But for more complex cases this behavior can cause
   * layout issues.
   *
   * Passing wrap="normal" allows for text to wrap
   */
  wrap?: CSS.Property.WhiteSpace
  /**
   * @deprecated
   * Set a classname on the container element.
   * Prefer to use css props directly, e.g. `ml={2}`
   * or use the `css` proper for things not supported
   * by styled-system
   */
  className?: string
  /**
   * Allows for arbitrary css not supported by styled-system
   */
  css?: AnyObject
}

const ARROW_HEIGHT = 10
const BOTTOM_PADDING = 20
const SCROLL_BAR_WIDTH = 17

const defaultBoundingBox = {
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  width: 0,
  height: 0,
  x: 0,
  y: 0,
} as DOMRect

const getDefaultMountAt = () => {
  return document.body
}

const isPoint = (anchor: Element | 'mouse' | Point | null): anchor is Point => {
  if (!anchor) {
    return false
  }

  // Could probably use `'x' in anchor`, but for super extra duper clarity
  // we just check for the anchor's own keys, not the whole prototype chain.
  // `Element` doesn't have an x or y property in its prototype chain,
  // so this isn't strictly necessary...but it's nice to be explicit
  // that we expect x and y directly on the passed in object.
  return (
    Object.prototype.hasOwnProperty.call(anchor, 'x') &&
    Object.prototype.hasOwnProperty.call(anchor, 'y')
  )
}
export const DEFAULT_Z_INDEX = 10000

/* eslint-disable complexity */
export const Tooltip = React.forwardRef(
  (
    {
      isAnimated = true,
      bg = 'voltus-tooltip-bg',
      anchorPoint = 'bottom-center',
      contentAlign = 'center',
      fixed = false,
      autoPointerEvents = false,
      children,
      mountAt,
      hideArrow = false,
      anchor = null,
      zIndex = DEFAULT_Z_INDEX,
      wrap = 'nowrap',
      verticalOffset = 0,
      horizontalOffset = 0,
      rearrangeOnWindowCollision = true,
      watchAnchor = false,
      css,
      closeOnAnchorExit = false,
      onRequestClose,
      getVerticalOffset,
      getHorizontalOffset,
      className, // deprecated
      ...props
    }: TooltipProps,
    outerRef
  ): JSX.Element => {
    // Cheap way to force disable all animations in a test env.
    // In a more professional place we'd have this hook into some global animation context provider
    // that we could disable animations everywhere with a single prop
    if (isTestEnv()) {
      isAnimated = false
    }

    const containerRef = React.useRef<HTMLElement>()
    React.useImperativeHandle(outerRef, () => containerRef.current)
    const [isMounted, setIsMounted] = React.useState(!isAnimated ? true : false)
    const [localPosition, setLocalPosition] = React.useState(anchorPoint)
    const hasRearranged = React.useRef(false)
    const [anchorBoundingBox, setAnchorPosition] = React.useState<DOMRect>(
      anchor instanceof Element
        ? domUtils.getBoundingBox(anchor)
        : defaultBoundingBox
    )
    const pointerPos = usePointerContext()
    const mousePos = {
      x: pointerPos.clientX,
      y: pointerPos.clientY,
    }

    // We disallow the arrow if following the mouse
    if (anchor === 'mouse' || isPoint(anchor)) {
      hideArrow = true
    }

    React.useLayoutEffect(() => {
      // If anchor point changes
      setLocalPosition(anchorPoint)
    }, [anchorPoint, setLocalPosition])

    const [selfBoundingBox, setSelfBoundingBox] =
      React.useState(defaultBoundingBox)

    React.useEffect(() => {
      if (!isAnimated) {
        return
      }

      setIsMounted(false)
      const timeoutId = setTimeout(() => {
        setIsMounted(true)
      }, 0)

      return () => {
        clearTimeout(timeoutId)
      }
    }, [isAnimated])

    React.useEffect(() => {
      if (!isMounted) {
        return
      }
      if (anchor instanceof Element) {
        // intersection observer to close tooltip when
        // the anchor goes off screen
        const options = {
          threshold: 0,
        }
        const cb = (entries) => {
          if (isMounted) {
            if (
              entries[0].isIntersecting === false ||
              entries[0].intersectionRatio === 0
            ) {
              if (closeOnAnchorExit) {
                onRequestClose?.()
              }
            }
          }
        }
        const observer = new IntersectionObserver(cb, options)
        observer.observe(anchor)

        return () => {
          observer.disconnect()
        }
      }
    }, [isMounted, anchor, closeOnAnchorExit, onRequestClose])

    React.useLayoutEffect(() => {
      let raf: number | undefined = undefined
      const setBox = () => {
        if (anchor instanceof Element) {
          const box = domUtils.getBoundingBox(anchor)
          setAnchorPosition(box)
        }
        if (watchAnchor) {
          raf = window.requestAnimationFrame(setBox)
        }
      }
      raf = window.requestAnimationFrame(setBox)
      return () => {
        if (raf) {
          window.cancelAnimationFrame(raf)
        }
      }
    }, [anchor, watchAnchor])

    React.useLayoutEffect(() => {
      if (containerRef.current) {
        setSelfBoundingBox(
          containerRef.current?.getBoundingClientRect() ?? defaultBoundingBox
        )

        const handleMutation = () => {
          // Force a recalculation if the children change
          setSelfBoundingBox(
            containerRef.current?.getBoundingClientRect() ?? defaultBoundingBox
          )
        }
        const observer = new MutationObserver(handleMutation)
        observer.observe(containerRef.current, {
          childList: true,
          subtree: true,
        })

        return () => {
          observer.disconnect()
        }
      }
    }, [setSelfBoundingBox])

    const moveIntoView = ({ top, left }) => {
      const scrollOffset = domUtils.getScrollOffset()
      const newLocalPosition = localPosition.split('-')
      if (left < 0) {
        left = 10
      } else if (
        left + selfBoundingBox.width >
        window.innerWidth + scrollOffset.x
      ) {
        // Extra 17 to get out of the way of a potential scroll bar
        left = window.innerWidth - selfBoundingBox.width - SCROLL_BAR_WIDTH
      }

      if (top < 0) {
        newLocalPosition[0] = 'bottom'
        top = 10
      } else if (
        top + selfBoundingBox.height >
        window.innerHeight + scrollOffset.y
      ) {
        if (rearrangeOnWindowCollision === true) {
          newLocalPosition[0] = 'top'
          top = top - selfBoundingBox.height - BOTTOM_PADDING
        } else {
          top = window.innerHeight - selfBoundingBox.height
        }
      }

      const newPos = newLocalPosition.join('-')
      if (newPos !== localPosition) {
        if (rearrangeOnWindowCollision === true && !hasRearranged.current) {
          hasRearranged.current = true
          // Wrap in a setTimeout to avoid colliding with the call to setLocalPosition
          // in the useLayoutEffect above.
          setTimeout(() => setLocalPosition(newPos as AnchorPoint), 0)
        }
      }

      return {
        top,
        left,
      }
    }

    const arrowPosition = { x: 0, y: 0 }
    const [vertical, horizontal] = localPosition.split('-') as [
      AnchorPointVertical,
      AnchorPointHorizontal
    ]
    if (anchor === 'mouse') {
      arrowPosition.x = mousePos.x
      arrowPosition.y = mousePos.y
    } else if (isPoint(anchor)) {
      arrowPosition.x = anchor.x
      arrowPosition.y = anchor.y
    } else {
      switch (vertical) {
        case 'top':
          arrowPosition.y = anchorBoundingBox.top - 10
          break
        case 'bottom':
          arrowPosition.y =
            anchorBoundingBox.top + anchorBoundingBox.height + 10
          break
        case 'middle':
          arrowPosition.y = anchorBoundingBox.top + anchorBoundingBox.height / 2
          break
      }
      switch (horizontal) {
        case 'left':
          if (vertical === 'middle') {
            arrowPosition.x = anchorBoundingBox.left - 10
          } else {
            arrowPosition.x = anchorBoundingBox.left
          }
          break
        case 'center':
          arrowPosition.x = anchorBoundingBox.left + anchorBoundingBox.width / 2
          break
        case 'right':
          if (vertical === 'middle') {
            arrowPosition.x =
              anchorBoundingBox.left + anchorBoundingBox.width + 10
          } else {
            arrowPosition.x = anchorBoundingBox.left + anchorBoundingBox.width
          }
          break
      }
    }
    if (getVerticalOffset) {
      arrowPosition.y += getVerticalOffset({
        vertical: vertical,
        horizontal: horizontal,
        anchorPosition: localPosition,
        arrowCoordinates: arrowPosition,
      })
    } else if (verticalOffset) {
      arrowPosition.y += verticalOffset
    }
    if (getHorizontalOffset) {
      arrowPosition.x += getHorizontalOffset({
        vertical: vertical,
        horizontal: horizontal,
        anchorPosition: localPosition,
        arrowCoordinates: arrowPosition,
      })
    } else if (horizontalOffset) {
      arrowPosition.x += horizontalOffset
    }
    // Make sure arrow never overflows the screen
    arrowPosition.x = Math.min(
      document.documentElement.clientWidth - 15,
      arrowPosition.x
    )

    const getVerticalPosition = () => {
      switch (vertical) {
        case 'top':
          return arrowPosition.y - selfBoundingBox.height
        case 'bottom':
          return arrowPosition.y
        case 'middle':
          if (horizontal === 'right') {
            return arrowPosition.y - selfBoundingBox.height / 2
          }
          if (horizontal === 'left') {
            return arrowPosition.y - selfBoundingBox.height / 2
          }
          return arrowPosition.y - 10
        default:
          return 0
      }
    }

    const getHorizontalPosition = () => {
      switch (contentAlign) {
        case 'left':
          if (vertical === 'middle') {
            return arrowPosition.x - selfBoundingBox.width
          }
          return arrowPosition.x - selfBoundingBox.width + ARROW_HEIGHT + 5
        case 'center':
          return arrowPosition.x - selfBoundingBox.width / 2
        case 'right':
          if (vertical === 'middle' && horizontal === 'left') {
            return arrowPosition.x
          }
          if (vertical === 'middle' && horizontal === 'right') {
            return arrowPosition.x
          }
          return arrowPosition.x - ARROW_HEIGHT - 5
      }
    }

    const { top, left } = moveIntoView({
      left: getHorizontalPosition(),
      top: getVerticalPosition() + (hideArrow ? -10 : 0),
    })
    return ReactDOM.createPortal(
      <>
        <Box
          className={className}
          zIndex={zIndex}
          position={fixed ? 'fixed' : 'absolute'}
          opacity={isMounted ? 1 : 0}
          whiteSpace={wrap}
          maxWidth="96vw"
          overflowX="auto"
          css={{
            top: 0,
            left: 0,
            transform: `translate(${left}px, ${top}px)`,
            boxShadow: shadows[1],
            pointerEvents: autoPointerEvents ? 'auto' : 'none',
            transition: 'opacity 130ms ease-out',
            ...css,
          }}
          bg={bg}
          color="white"
          borderRadius="3px"
          ref={containerRef}
          {...props}
        >
          {children}
        </Box>
        {!hideArrow && (
          <Box
            opacity={isMounted ? 1 : 0}
            css={{
              pointerEvents: autoPointerEvents ? 'auto' : 'none',
              transition: 'opacity 130ms ease-out',
            }}
            zIndex={zIndex - 1}
            position={fixed ? 'fixed' : 'absolute'}
            left={arrowPosition.x}
            top={arrowPosition.y}
          >
            <Box
              css={{
                boxShadow: shadows[1],
                transform: `translate(-5px, -5px) rotate(45deg) `,
              }}
              width={ARROW_HEIGHT}
              height={ARROW_HEIGHT}
              bg={bg}
            />
          </Box>
        )}
      </>,
      mountAt ?? getDefaultMountAt()
    )
  }
)

export default Tooltip
