export const POSTCODE_SYNONYMS = [
  'postcode',
  'zipcode',
  'post code',
  'zip code',
  'postal',
  'post',
  'zip',
  'code',
]

export const POSTCODES_MAX_ROWS = 10000

export const INVALID_FILE_ERROR = 'invalidFileError'
