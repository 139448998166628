// This is VERY frustrating
// Nested <Routes /> don't support using absolute paths
// But our app is _ALL_ absolute paths. So, we either refactor how we configure all
// our route path strings, or we do this hack, that allows nested <Routes> to use
// absolute paths.
// https://github.com/remix-run/react-router/issues/8035#issuecomment-997737565
import * as React from 'react'
import { UNSAFE_RouteContext as RouteContext, Routes } from 'react-router-dom'

export function RootRoutes(props) {
  const ctx = React.useContext(RouteContext)

  const value = React.useMemo(
    () => ({
      ...ctx,
      matches: [],
    }),
    [ctx]
  )

  return (
    <RouteContext.Provider value={value}>
      <Routes {...props} />
    </RouteContext.Provider>
  )
}
