import React from 'react'
import { HexColorString } from '@voltus/types'
import colors from '../constants/colors.json'
import createSVGComponent from './createSVGComponent'

type EyeProps = {
  [key: string]: unknown
  color?: HexColorString | string
}

export default createSVGComponent<EyeProps>(
  ({ color = colors.grays[20], ...props }: EyeProps): JSX.Element => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke={color}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <circle cx="12" cy="12" r="2" />
      <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
    </svg>
  )
)
