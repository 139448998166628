import BasePhone from './Phone'
import Call from './PhoneCall'
import Check from './PhoneCheck'
import Incoming from './PhoneIncoming'
import Outgoing from './PhoneOutgoing'
import Pause from './PhonePause'
import Plus from './PhonePlus'
import X from './PhoneX'

type Phone = typeof BasePhone & {
  Incoming: typeof Incoming
  Outgoing: typeof Outgoing
  X: typeof X
  Plus: typeof Plus
  Pause: typeof Pause
  Check: typeof Check
  Call: typeof Call
}
const Phone = BasePhone as unknown as Phone

Phone.Incoming = Incoming
Phone.Outgoing = Outgoing
Phone.X = X
Phone.Plus = Plus
Phone.Pause = Pause
Phone.Check = Check
Phone.Call = Call

export default Phone
