import React from 'react'

const SVG = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="98.000000pt"
    height="50.000000pt"
    viewBox="0 0 663.53 195.75"
  >
    <path
      fill="#fff"
      d="M593.3 178H621a47 47 0 0 0 13.8-2 20.9 20.9 0 0 0 7.1-3.6c4.6-3.8 5.8-8.9 5.5-14.5-.3-7-3.9-12.2-9.7-16a63.9 63.9 0 0 0-18-7c-8.7-2.3-17.7-4-26.5-6.4a71.5 71.5 0 0 1-21-9.2 28.5 28.5 0 0 1-12.7-26.9c.4-7.6 2.6-14.6 7.8-20.3a34.3 34.3 0 0 1 20.3-10.9 54 54 0 0 1 8.8-.6h57c1.1 0 1.5.2 1.4 1.4v12.6c0 1.2-.3 1.4-1.4 1.4h-57.8a19 19 0 0 0-13 4.4c-7.7 6.9-8.5 20.1.6 26.7a44.3 44.3 0 0 0 13.4 6.2c8.2 2.5 16.6 4 25 6 8 1.9 15.9 4 23.2 7.7a38.2 38.2 0 0 1 12.4 9.3 29.8 29.8 0 0 1 6.9 16.4 39 39 0 0 1-2.1 19 30.3 30.3 0 0 1-17.6 17A60.8 60.8 0 0 1 625 193c-8.4.6-16.9.3-25.3.3H561c-1 0-1.3-.2-1.3-1.2v-13c0-1 .3-1.1 1.2-1.1h32.3ZM432 103.8V62c0-1.1.3-1.4 1.4-1.4h13c1.2 0 1.5.3 1.5 1.4v78.6a41 41 0 0 0 10.6 28.4 35 35 0 0 0 22.4 11.4c9.8 1.1 18.8-.9 26.8-6.8 8.3-6 13-14.5 15-24.4a49.2 49.2 0 0 0 .8-9.8V62.2c0-1.2.2-1.6 1.5-1.6h13.2c1 0 1.2.3 1.2 1.2 0 27 .3 54-.1 81a52.4 52.4 0 0 1-52.6 53.1 53.8 53.8 0 0 1-28.6-7.1 51 51 0 0 1-24.4-33.5 61.4 61.4 0 0 1-1.7-14.6ZM65.5 178.6l4.1-10.5c3.9-9.7 7.6-19.4 11.6-29 8-18.9 15.1-38 22.7-56.8l8-20.6a1.4 1.4 0 0 1 1.7-1H129c1 0 1.4 0 1 1.2-2.5 5.9-4.8 11.9-7.1 17.8-13.4 34.4-27 68.7-40.7 103-.7 2-1.6 3.9-2.5 5.8-2 4.2-5.5 6.4-10 7.1a24.6 24.6 0 0 1-9.9-.3 12.5 12.5 0 0 1-9-8L30 135.2 9.5 83 1 61.6c-.3-.7-.3-1 .6-1h16c1 0 1.1.5 1.4 1.1l16.1 40.9q13.4 34 27 67.9l2.7 6.9.6 1.2Z"
      transform="translate(-1 -.2)"
    />

    <path
      fill="#2274a2"
      d="M228.7 172.8a84.7 84.7 0 0 1-21.4 18.4c-4.9 2.7-10 4.6-15.6 4.7s-10.6-1.9-15.4-4.5a77.8 77.8 0 0 1-20-16.5 140.2 140.2 0 0 1-25.6-39.6 37.5 37.5 0 0 1-.2-30.5c3.4-8 9.4-13.8 16.7-18.4A77.2 77.2 0 0 1 156 82a81.7 81.7 0 0 1 29.6-6.3 59.5 59.5 0 0 1 6-.2c0 .8-.6 1.1-1 1.6-3 3.5-5.6 7.3-8.3 11a4.7 4.7 0 0 1-1.2 1.5 91.8 91.8 0 0 0-11.8 2 49.4 49.4 0 0 0-18.3 8.5c-5 3.9-8.5 8.8-9.8 15.2a25.6 25.6 0 0 0 1.7 14.4 108 108 0 0 0 9.4 18 124.2 124.2 0 0 0 12.4 16.5 73.2 73.2 0 0 0 19 16c5.6 3 11 2.9 16.4-.3a63.3 63.3 0 0 0 11.3-8.5 68 68 0 0 0 6.8-6.6 46 46 0 0 0 4.5-5.5 142.3 142.3 0 0 0 12.3-18.5c2.4-4.3 4.8-8.6 6.2-13.4 3.5-12-.7-21.8-10.6-28.6-6.6-4.6-14.2-7-22-8.4-3.4-.6-6.8-.8-10.2-1.2a2 2 0 0 1-.7-.2c-.4-.4 0-.7.3-1l5-5.9a32.1 32.1 0 0 1 4.8-5 1.8 1.8 0 0 1 1.1-.5 64.3 64.3 0 0 1 16 4 68.3 68.3 0 0 1 16.1 9 38 38 0 0 1 13.6 18.5 36.1 36.1 0 0 1 1.6 9c.5 7.7-1.4 14.7-4.6 21.6a110.8 110.8 0 0 1-6 11.3c-2 3.2-4 6.3-6.2 9.4-2.5 3.2-4.7 6.6-7.3 9.7-1.1 1.3-2 2.8-3.4 3.7Z"
      transform="translate(-1 -.2)"
    />

    <path
      fill="#8ac43f"
      d="M222.7 159.7a63.3 63.3 0 0 1-7.3 8.4 21.8 21.8 0 0 1-3.8 3.5 5.8 5.8 0 0 1-2.7-.2 69.3 69.3 0 0 1-26.8-10.3 40.8 40.8 0 0 1-14-15.3 35 35 0 0 1-3.8-14.8 46 46 0 0 1 5.4-22.7 185.5 185.5 0 0 1 9.7-17 6.5 6.5 0 0 1 1.6-2c3.4-4.7 6.7-9.4 10.5-13.8a109.8 109.8 0 0 1 12.7-12.3 60.3 60.3 0 0 1 12.8-8 27.8 27.8 0 0 1 24.6.5 67.4 67.4 0 0 1 17.3 12.4 97.7 97.7 0 0 1 10.9 12c8 10.5 15.2 21.3 20.1 33.5a37.8 37.8 0 0 1-16.3 48.4c-8 5-16.8 7.8-26 9.4a104.2 104.2 0 0 1-18.8 1.4c3.5-4.3 7-8.6 10.2-13.2l.3-.2c1.6-.8 3.4-.7 5.2-1a58 58 0 0 0 22.7-8.1 27.7 27.7 0 0 0 10.6-11.8c2.6-5.8 2.5-11.6.6-17.6s-5-11.4-8.2-16.8a129.6 129.6 0 0 0-15-20.4 64.1 64.1 0 0 0-19.1-15.8 15 15 0 0 0-15.2.2 64 64 0 0 0-11 7.9c-.4.3-.7.7-1.3.7-4.1 3.6-7.4 8-11 12.2.1.6-.3.9-.6 1.2a141 141 0 0 0-14.9 23.7 36.3 36.3 0 0 0-4 12 22.9 22.9 0 0 0 5.9 19 39.6 39.6 0 0 0 16.4 10.4 76.6 76.6 0 0 0 18.7 4c1.2 0 2.5-.2 3.6.5Z"
      transform="translate(-1 -.2)"
    />

    <path
      fill="#fff"
      d="M357.3 92V27.2c0-1.2.3-1.4 1.4-1.4h13.1c1.2 0 1.4.4 1.4 1.5V59c0 1.2.2 1.6 1.5 1.6H417c1 0 1.3.2 1.3 1.3v12.8c0 1-.2 1.3-1.2 1.3h-42.6c-1.1 0-1.5.2-1.5 1.3V155a26.3 26.3 0 0 0 3 13 19 19 0 0 0 15.6 9.9c5.6.6 11.2.1 16.8.2h9c.8 0 1 .2 1 1v13.3c0 .7 0 1-.9 1-7.9 0-15.8.2-23.8-.1-8.4-.3-16.3-2.6-23.1-8s-10.6-12.4-12.3-20.8a53.2 53.2 0 0 1-1-10.7Zm-50.8 4.8v-95c0-1.3.3-1.6 1.6-1.6h13.3c.8 0 1.1.3 1 1a4.3 4.3 0 0 0 0 .6v189.8c0 1.7 0 1.7-1.7 1.7h-13c-1 0-1.2-.3-1.2-1.3V96.8Z"
      transform="translate(-1 -.2)"
    />

    <path
      fill="none"
      stroke="#fff"
      strokeMiterlimit="10"
      strokeWidth="5px"
      d="M210.2 107.5a15.3 15.3 0 1 1-15.4 15 15.3 15.3 0 0 1 15.4-15Z"
      transform="translate(-1 -.2)"
    />
  </svg>
)
export default SVG
