import { pathOr } from 'ramda'
import { put, takeLatest, select } from 'redux-saga/effects'

import api from '../../shared/services/api'
import { getErrorMessageKey } from '../../shared/utils/errorHelpers'
import reportError from '../../shared/utils/reportError'
import { selectReportsData } from '../reports/reports.selectors'
import { sendContactErrorMessages } from './contact.messages'
import { ContactTypes, ContactActions } from './contact.redux'

export function* sendContact({ formData, formik = {} }) {
  try {
    const reportData = yield select(selectReportsData)
    yield api.post('/contact', { ...formData, earningsData: reportData })

    yield put(ContactActions.sendContactSuccess())
  } catch (error) {
    const errorKey = pathOr('defaultError', ['response', 'data'], error)
    const messageKey = getErrorMessageKey(errorKey, sendContactErrorMessages)
    yield put(ContactActions.sendContactFailure(messageKey))

    if (!error.response) {
      /* istanbul ignore next */
      reportError(error)
    }
  } finally {
    if (formik.setSubmitting) {
      formik.setSubmitting(false)
    }
  }
}

export function* watchContact() {
  try {
    yield takeLatest(ContactTypes.SEND_CONTACT, sendContact)
  } catch (error) {
    /* istanbul ignore next */
    reportError(error)
  }
}
