import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 2H12V0.666664H10.6667V2H5.33333V0.666664H4V2H3.33333C2.59333 2 2.00667 2.6 2.00667 3.33333L2 12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM12.6667 12.6667H3.33333V6H12.6667V12.6667ZM12.6667 4.66666H3.33333V3.33333H12.6667V4.66666ZM8 7.33333H4.66667V10.6667H8V7.33333Z"
      fill="#7A8597"
    />
    <path
      opacity="0.3"
      d="M3.33331 3.33334H12.6666V4.66667H3.33331V3.33334Z"
      fill="#7A8597"
    />
  </svg>
)
