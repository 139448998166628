import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import messages from './banner.messages'

export class BannerComponent extends PureComponent {
  render() {
    return (
      <div className="banner__container">
        <h1 className="banner__title">
          <FormattedMessage {...messages.title} />
        </h1>
      </div>
    )
  }
}

export const Banner = injectIntl(BannerComponent)
