import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="layout-sidebar-left-expand">
      <path
        id="Vector"
        d="M5.99984 2.66666V13.3333M9.33317 6.66666L10.6665 7.99999L9.33317 9.33332M2.6665 3.99999C2.6665 3.64637 2.80698 3.30723 3.05703 3.05718C3.30708 2.80713 3.64622 2.66666 3.99984 2.66666H11.9998C12.3535 2.66666 12.6926 2.80713 12.9426 3.05718C13.1927 3.30723 13.3332 3.64637 13.3332 3.99999V12C13.3332 12.3536 13.1927 12.6928 12.9426 12.9428C12.6926 13.1928 12.3535 13.3333 11.9998 13.3333H3.99984C3.64622 13.3333 3.30708 13.1928 3.05703 12.9428C2.80698 12.6928 2.6665 12.3536 2.6665 12V3.99999Z"
        stroke="#36454F"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
