import React from 'react'

import createSVGComponent from './createSVGComponent'

export default createSVGComponent(
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.01 18L21 9L0.01 0L0 7L15 9L0 11L0.01 18Z" fill="none" />
  </svg>
)
