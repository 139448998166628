import { Record, Map, List, fromJS } from 'immutable'
import { createActions, createReducer } from 'reduxsauce'

import { PostcodesFileTypes } from '../postcodesFile/postcodesFile.redux'

export const { Types: ReportsTypes, Creators: ReportsActions } = createActions(
  {
    generate: ['postCodes'],
    generateSuccess: ['data'],
    generateFailure: ['error'],
    save: ['formData', 'formik'],
    saveSuccess: ['token'],
    saveFailure: ['error'],
    getSaved: ['token'],
    getSavedSuccess: ['data'],
    getSavedFailure: ['error'],
    setFrequency: ['value'],
    setResponseTime: ['value'],
  },
  { prefix: 'REPORTS_' }
)

const ReportsRecord = new Record({
  postCodes: List(),
  data: Map(),
  isLoadingData: false,
  frequency: 2,
  responseTime: 0,
  saveError: null,
  saveSuccess: false,
  reportToken: null,
})

export const INITIAL_STATE = new ReportsRecord({})

export const generateHandler = (state, { postCodes }) =>
  state.merge({
    postCodes: fromJS(postCodes),
    isLoadingData: true,
    saveSuccess: false,
    saveError: null,
    frequency: 2,
    responseTime: 0,
  })

export const generateSuccessHandler = (state, { data }) =>
  state.merge({
    data: fromJS(data),
    isLoadingData: false,
  })

export const generateFailureHandler = (state) =>
  state.merge({
    isLoadingData: false,
    data: Map(),
  })

export const getSavedHandler = (state) => state.set('isLoadingData', true)

export const getSavedSuccessHandler = (state, { data }) =>
  state.merge({
    data: fromJS(data),
    isLoadingData: false,
  })

export const getSavedFailureHandler = (state) =>
  state.set('isLoadingData', false)

export const setFrequencyHandler = (state, { value }) =>
  state.set('frequency', value)

export const setResponseTimeHandler = (state, { value }) =>
  state.set('responseTime', value)

export const saveHandler = (state) =>
  state.merge({
    saveError: null,
    saveSuccess: false,
    reportToken: null,
  })

export const saveSuccessHandler = (state, { token }) =>
  state.merge({
    saveSuccess: true,
    reportToken: token,
  })

export const saveFailureHandler = (state, { error }) =>
  state.set('saveError', error)

export const resetPostcodes = (state) => state.set('postCodes', List())

export const reducer = createReducer(INITIAL_STATE, {
  [ReportsTypes.GENERATE]: generateHandler,
  [ReportsTypes.GENERATE_SUCCESS]: generateSuccessHandler,
  [ReportsTypes.GENERATE_FAILURE]: generateFailureHandler,
  [ReportsTypes.GET_SAVED]: getSavedHandler,
  [ReportsTypes.GET_SAVED_SUCCESS]: getSavedSuccessHandler,
  [ReportsTypes.GET_SAVED_FAILURE]: getSavedFailureHandler,
  [ReportsTypes.SET_FREQUENCY]: setFrequencyHandler,
  [ReportsTypes.SET_RESPONSE_TIME]: setResponseTimeHandler,
  [ReportsTypes.SAVE]: saveHandler,
  [ReportsTypes.SAVE_SUCCESS]: saveSuccessHandler,
  [ReportsTypes.SAVE_FAILURE]: saveFailureHandler,
  [PostcodesFileTypes.RESET_DATA]: resetPostcodes,
})
