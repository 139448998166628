import React from 'react'

const SVG = ({ className }) => (
  <svg
    className={className}
    width="15"
    height="18"
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4668 9.05005H2.7668C2.04883 9.05005 1.4668 9.63208 1.4668 10.35V19.45C1.4668 20.168 2.04883 20.75 2.7668 20.75H14.4668C15.1848 20.75 15.7668 20.168 15.7668 19.45V10.35C15.7668 9.63208 15.1848 9.05005 14.4668 9.05005Z"
      stroke="#8DC63F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.06641 9.05V5.8C4.06641 4.59327 4.54578 3.43595 5.39907 2.58266C6.25236 1.72937 7.40967 1.25 8.61641 1.25C9.82314 1.25 10.9805 1.72937 11.8337 2.58266C12.687 3.43595 13.1664 4.59327 13.1664 5.8V9.05"
      stroke="#8DC63F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SVG
