import qs from 'query-string'
import { takeLatest, put } from 'redux-saga/effects'
import { history } from '@voltus/utils'

import reportError from '../../shared/utils/reportError'
import { ReportsActions } from '../reports'
import { StartupTypes } from './startup.redux'

function* retrieveSavedReport() {
  const { token, frequencyIndex, responseTimeIndex } = qs.parse(
    history.location.search
  )
  if (token && frequencyIndex && responseTimeIndex) {
    yield put(ReportsActions.getSaved(token))
    yield put(ReportsActions.setFrequency(parseInt(frequencyIndex, 10)))
    yield put(ReportsActions.setResponseTime(parseInt(responseTimeIndex, 10)))
    history.replace('/report')
  }
}

function* startup() {
  try {
    yield retrieveSavedReport()
  } catch (error) {
    /* istanbul ignore next */
    reportError(error)
  }
}

export function* watchStartup() {
  try {
    yield takeLatest(StartupTypes.STARTUP, startup)
  } catch (error) {
    /* istanbul ignore next */
    reportError(error)
  }
}
