import React from 'react'

import createSVGComponent from './createSVGComponent'

export default createSVGComponent(
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.1875 0.1875C2.87402 0.1875 0.1875 2.87499 0.1875 6.1875C0.1875 9.50194 2.87402 12.1875 6.1875 12.1875C9.50098 12.1875 12.1875 9.50194 12.1875 6.1875C12.1875 2.87499 9.50098 0.1875 6.1875 0.1875ZM6.1875 11.0262C3.51334 11.0262 1.34879 8.86256 1.34879 6.1875C1.34879 3.51423 3.51344 1.34879 6.1875 1.34879C8.86067 1.34879 11.0262 3.51341 11.0262 6.1875C11.0262 8.86161 8.86256 11.0262 6.1875 11.0262ZM8.78211 4.85202C8.78211 6.47424 7.02999 6.49921 7.02999 7.0987V7.25202C7.02999 7.41235 6.9 7.54234 6.73967 7.54234H5.63531C5.47498 7.54234 5.34498 7.41235 5.34498 7.25202V7.04252C5.34498 6.17773 6.00063 5.83202 6.49609 5.55423C6.92095 5.31605 7.18135 5.15405 7.18135 4.83861C7.18135 4.42137 6.64911 4.14443 6.21883 4.14443C5.65781 4.14443 5.39881 4.41 5.03475 4.86948C4.9366 4.99335 4.75749 5.01636 4.63154 4.92087L3.95838 4.41044C3.83482 4.31676 3.80714 4.14271 3.89441 4.01456C4.46603 3.17519 5.19411 2.70363 6.32768 2.70363C7.51488 2.70363 8.78211 3.63034 8.78211 4.85202ZM7.20363 8.89718C7.20363 9.45748 6.7478 9.91331 6.1875 9.91331C5.6272 9.91331 5.17137 9.45748 5.17137 8.89718C5.17137 8.33688 5.6272 7.88105 6.1875 7.88105C6.7478 7.88105 7.20363 8.33688 7.20363 8.89718Z"
      fill="#007E56"
    />
  </svg>
)
