/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  cta: {
    id: 'header.cta',
    defaultMessage: 'Talk to a Voltan',
  },
  cd: {
    id: 'header.contact',
    defaultMessage: 'Contact us',
  },
  login: {
    id: 'header.login',
    defaultMessage: 'Log in',
  },
})
