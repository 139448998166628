const competitors = [
  'enel.com',
  'enelx.com',
  'enernoc.com',
  'enelx.com',
  'enelgroup.com',
  'enelgreenpower.com',
  'cpowerenergymanagement.com',
  'nrg.com',
  'directenergy.com',
  'leap.energy',
  'ohmconnect.com',
  'arcadia.com',
  'enersponse.com',
  'nuenergen.com',
  'rodanenergy.com',
  'polarisenergyservices.com',
  'prioritypower.net',
  'pge.com',
  'sce.com',
  'shellenergy.com',
  'gridbeyond.com',
]

const others = [
  '0-mail.com',
  '027168.com',
  '0815.su',
  '0sg.net',
  '10mail.org',
  '10minutemail.co.za',
  '11mail.com',
  '123.com',
  '123box.net',
  '123india.com',
  '123mail.cl',
  '123mail.org',
  '123qwe.co.uk',
  '126.com',
  '139.com',
  '150mail.com',
  '150ml.com',
  '15meg4free.com',
  '163.com',
  '16mail.com',
  '188.com',
  '189.cn',
  '1ce.us',
  '1chuan.com',
  '1coolplace.com',
  '1freeemail.com',
  '1funplace.com',
  '1internetdrive.com',
  '1mail.ml',
  '1mail.net',
  '1me.net',
  '1mum.com',
  '1musicrow.com',
  '1netdrive.com',
  '1nsyncfan.com',
  '1pad.de',
  '1under.com',
  '1webave.com',
  '1webhighway.com',
  '1zhuan.com',
  '2-mail.com',
  '20email.eu',
  '20mail.in',
  '20mail.it',
  '212.com',
  '21cn.com',
  '24horas.com',
  '2911.net',
  '2980.com',
  '2bmail.co.uk',
  '2d2i.com',
  '2die4.com',
  '2trom.com',
  '3000.it',
  '30minutesmail.com',
  '3126.com',
  '321media.com',
  '33mail.com',
  '37.com',
  '3ammagazine.com',
  '3dmail.com',
  '3email.com',
  '3g.ua',
  '3mail.ga',
  '3xl.net',
  '444.net',
  '4email.com',
  '4email.net',
  '4mg.com',
  '4newyork.com',
  '4warding.net',
  '4warding.org',
  '4x4man.com',
  '50mail.com',
  '60minutemail.com',
  '6ip.us',
  '6mail.cf',
  '6paq.com',
  '74gmail.com',
  '74.ru',
  '7mail.ga',
  '7mail.ml',
  '88.am',
  '8848.net',
  '8mail.ga',
  '8mail.ml',
  '97rock.com',
  '99experts.com',
  'a45.in',
  'aaamail.zzn.com',
  'aamail.net',
  'aapt.net.au',
  'aaronkwok.net',
  'abbeyroadlondon.co.uk',
  'abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyzabcdefghijk.com',
  'abcflash.net',
  'abdulnour.com',
  'aberystwyth.com',
  'about.com',
  'abusemail.de',
  'abv.bg',
  'abwesend.de',
  'abyssmail.com',
  'ac20mail.in',
  'academycougars.com',
  'acceso.or.cr',
  'access4less.net',
  'accessgcc.com',
  'accountant.com',
  'acdcfan.com',
  'ace-of-base.com',
  'acmemail.net',
  'acninc.net',
  'activist.com',
  'adam.com.au',
  'add3000.pp.ua',
  'addcom.de',
  'address.com',
  'adelphia.net',
  'adexec.com',
  'adfarrow.com',
  'adios.net',
  'adoption.com',
  'ados.fr',
  'adrenalinefreak.com',
  'advalvas.be',
  'advantimo.com',
  'aeiou.pt',
  'aemail4u.com',
  'aeneasmail.com',
  'afreeinternet.com',
  'africamail.com',
  'africamel.net',
  'ag.us.to',
  'agoodmail.com',
  'ahaa.dk',
  'ahk.jp',
  'aichi.com',
  'aim.com',
  'aircraftmail.com',
  'airforce.net',
  'airforceemail.com',
  'airpost.net',
  'ajacied.com',
  'ajaxapp.net',
  'ak47.hu',
  'aknet.kg',
  'albawaba.com',
  'alex4all.com',
  'alexandria.cc',
  'algeria.com',
  'alhilal.net',
  'alibaba.com',
  'alice.it',
  'alive.cz',
  'aliyun.com',
  'allergist.com',
  'allmail.net',
  'alloymail.com',
  'allracing.com',
  'allsaintsfan.com',
  'alpenjodel.de',
  'alphafrau.de',
  'alskens.dk',
  'altavista.com',
  'altavista.net',
  'altavista.se',
  'alternativagratis.com',
  'alumni.com',
  'alumnidirector.com',
  'alvilag.hu',
  'amail.com',
  'amazonses.com',
  'amele.com',
  'america.hm',
  'ameritech.net',
  'amnetsal.com',
  'amorki.pl',
  'amrer.net',
  'amuro.net',
  'amuromail.com',
  'ananzi.co.za',
  'andylau.net',
  'anfmail.com',
  'angelfire.com',
  'angelic.com',
  'animail.net',
  'animalhouse.com',
  'animalwoman.net',
  'anjungcafe.com',
  'annsmail.com',
  'ano-mail.net',
  'anonmails.de',
  'anonymous.to',
  'anote.com',
  'another.com',
  'anotherdomaincyka.tk',
  'anotherwin95.com',
  'anti-social.com',
  'antisocial.com',
  'antispam24.de',
  'antongijsen.com',
  'antwerpen.com',
  'anymoment.com',
  'anytimenow.com',
  'aol.com',
  'aon.at',
  'apexmail.com',
  'apmail.com',
  'apollo.lv',
  'aport.ru',
  'aport2000.ru',
  'appraiser.net',
  'approvers.net',
  'arabia.com',
  'arabtop.net',
  'archaeologist.com',
  'arcor.de',
  'arcotronics.bg',
  'arcticmail.com',
  'argentina.com',
  'aristotle.org',
  'army.net',
  'armyspy.com',
  'arnet.com.ar',
  'art-en-ligne.pro',
  'artlover.com',
  'artlover.com.au',
  'as-if.com',
  'asdasd.nl',
  'asean-mail.com',
  'asheville.com',
  'asia-links.com',
  'asia-mail.com',
  'asiafind.com',
  'asianavenue.com',
  'asiancityweb.com',
  'asiansonly.net',
  'asianwired.net',
  'asiapoint.net',
  'ass.pp.ua',
  'assala.com',
  'assamesemail.com',
  'astroboymail.com',
  'astrolover.com',
  'astrosfan.com',
  'astrosfan.net',
  'asurfer.com',
  'atheist.com',
  'athenachu.net',
  'atina.cl',
  'atl.lv',
  'atlaswebmail.com',
  'atmc.net',
  'atozasia.com',
  'atrus.ru',
  'att.net',
  'attglobal.net',
  'attymail.com',
  'au.ru',
  'auctioneer.net',
  'ausi.com',
  'aussiemail.com.au',
  'austin.rr.com',
  'australia.edu',
  'australiamail.com',
  'austrosearch.net',
  'autoescuelanerja.com',
  'autograf.pl',
  'autorambler.ru',
  'avh.hu',
  'avia-tonic.fr',
  'awsom.net',
  'axoskate.com',
  'ayna.com',
  'azazazatashkent.tk',
  'azimiweb.com',
  'azmeil.tk',
  'bachelorboy.com',
  'bachelorgal.com',
  'backpackers.com',
  'backstreet-boys.com',
  'backstreetboysclub.com',
  'bagherpour.com',
  'baldmama.de',
  'baldpapa.de',
  'ballyfinance.com',
  'bangkok.com',
  'bangkok2000.com',
  'bannertown.net',
  'baptistmail.com',
  'baptized.com',
  'barcelona.com',
  'bareed.ws',
  'bartender.net',
  'baseballmail.com',
  'basketballmail.com',
  'batuta.net',
  'baudoinconsulting.com',
  'bboy.zzn.com',
  'bcvibes.com',
  'beddly.com',
  'beeebank.com',
  'beenhad.com',
  'beep.ru',
  'beer.com',
  'beethoven.com',
  'belice.com',
  'belizehome.com',
  'bell.net',
  'bellair.net',
  'bellsouth.net',
  'berlin.com',
  'berlin.de',
  'berlinexpo.de',
  'bestmail.us',
  'betriebsdirektor.de',
  'bettergolf.net',
  'bharatmail.com',
  'big1.us',
  'bigassweb.com',
  'bigblue.net.au',
  'bigboab.com',
  'bigfoot.com',
  'bigfoot.de',
  'bigger.com',
  'biggerbadder.com',
  'bigmailbox.com',
  'bigmir.net',
  'bigpond.au',
  'bigpond.com',
  'bigpond.com.au',
  'bigpond.net',
  'bigpond.net.au',
  'bigramp.com',
  'bigstring.com',
  'bikemechanics.com',
  'bikeracer.com',
  'bikeracers.net',
  'bikerider.com',
  'billsfan.com',
  'billsfan.net',
  'bimla.net',
  'bin-wieder-da.de',
  'bio-muesli.info',
  'birdlover.com',
  'birdowner.net',
  'bisons.com',
  'bitmail.com',
  'bitpage.net',
  'bizhosting.com',
  'bk.ru',
  'blackburnmail.com',
  'blackplanet.com',
  'blader.com',
  'bladesmail.net',
  'blazemail.com',
  'bleib-bei-mir.de',
  'blockfilter.com',
  'blogmyway.org',
  'bluebottle.com',
  'bluehyppo.com',
  'bluemail.ch',
  'bluemail.dk',
  'bluesfan.com',
  'bluewin.ch',
  'blueyonder.co.uk',
  'blushmail.com',
  'blutig.me',
  'bmlsports.net',
  'boardermail.com',
  'boatracers.com',
  'bodhi.lawlita.com',
  'bol.com.br',
  'bolando.com',
  'bollywoodz.com',
  'boltonfans.com',
  'bombdiggity.com',
  'bonbon.net',
  'boom.com',
  'bootmail.com',
  'bootybay.de',
  'bornnaked.com',
  'bostonoffice.com',
  'boun.cr',
  'bounce.net',
  'bounces.amazon.com',
  'bouncr.com',
  'box.az',
  'box.ua',
  'boxbg.com',
  'boxemail.com',
  'boxformail.in',
  'boxfrog.com',
  'boximail.com',
  'boyzoneclub.com',
  'bradfordfans.com',
  'brasilia.net',
  'brazilmail.com',
  'brazilmail.com.br',
  'breadtimes.press',
  'breathe.com',
  'brennendesreich.de',
  'bresnan.net',
  'brew-master.com',
  'brew-meister.com',
  'brfree.com.br',
  'briefemail.com',
  'bright.net',
  'britneyclub.com',
  'brittonsign.com',
  'broadcast.net',
  'brokenvalve.com',
  'brusseler.com',
  'bsdmail.com',
  'btcmail.pw',
  'btconnect.co.uk',
  'btconnect.com',
  'btinternet.com',
  'btopenworld.co.uk',
  'buerotiger.de',
  'buffymail.com',
  'bullsfan.com',
  'bullsgame.com',
  'bumerang.ro',
  'bumpymail.com',
  'bund.us',
  'burnthespam.info',
  'burstmail.info',
  'buryfans.com',
  'business-man.com',
  'businessman.net',
  'busta-rhymes.com',
  'buyersusa.com',
  'bvimailbox.com',
  'byom.de',
  'c2.hu',
  'c2i.net',
  'c3.hu',
  'c4.com',
  'c51vsgq.com',
  'cabacabana.com',
  'cable.comcast.com',
  'cableone.net',
  'caere.it',
  'cairomail.com',
  'calendar-server.bounces.google.com',
  'calidifontain.be',
  'californiamail.com',
  'callnetuk.com',
  'callsign.net',
  'caltanet.it',
  'camidge.com',
  'canada-11.com',
  'canada.com',
  'canadianmail.com',
  'canoemail.com',
  'canwetalk.com',
  'caramail.com',
  'care2.com',
  'careerbuildermail.com',
  'carioca.net',
  'cartelera.org',
  'cartestraina.ro',
  'casablancaresort.com',
  'casema.nl',
  'cash4u.com',
  'cashette.com',
  'casino.com',
  'catcha.com',
  'catchamail.com',
  'catholic.org',
  'catlover.com',
  'cd2.com',
  'celineclub.com',
  'celtic.com',
  'center-mail.de',
  'centermail.at',
  'centermail.de',
  'centermail.info',
  'centoper.it',
  'centralpets.com',
  'centrum.cz',
  'centrum.sk',
  'centurytel.net',
  'certifiedmail.com',
  'cfl.rr.com',
  'cgac.es',
  'cghost.s-a-d.de',
  'chacuo.net',
  'chaiyomail.com',
  'chammy.info',
  'chance2mail.com',
  'chandrasekar.net',
  'charmedmail.com',
  'charter.net',
  'chat.ru',
  'chattown.com',
  'chauhanweb.com',
  'cheatmail.de',
  'chechnya.conf.work',
  'check.com',
  'check1check.com',
  'cheerful.com',
  'chef.net',
  'chek.com',
  'chello.nl',
  'chemist.com',
  'chequemail.com',
  'cheyenneweb.com',
  'chez.com',
  'chickmail.com',
  'china.com',
  'china.net.vg',
  'chinamail.com',
  'chirk.com',
  'chocaholic.com.au',
  'chong-mail.com',
  'chong-mail.net',
  'churchusa.com',
  'cia-agent.com',
  'cia.hu',
  'ciaoweb.it',
  'cicciociccio.com',
  'cincinow.net',
  'cinci.rr.com',
  'citiz.net',
  'citlink.net',
  'citromail.hu',
  'city-of-bath.org',
  'city-of-birmingham.com',
  'city-of-brighton.org',
  'city-of-cambridge.com',
  'city-of-coventry.com',
  'city-of-edinburgh.com',
  'city-of-lichfield.com',
  'city-of-lincoln.com',
  'city-of-liverpool.com',
  'city-of-manchester.com',
  'city-of-nottingham.com',
  'city-of-oxford.com',
  'city-of-swansea.com',
  'city-of-westminster.com',
  'city-of-westminster.net',
  'city-of-york.net',
  'cityofcardiff.net',
  'cityoflondon.org',
  'ckaazaza.tk',
  'claramail.com',
  'classicalfan.com',
  'classicmail.co.za',
  'clear.net.nz',
  'clearwire.net',
  'clerk.com',
  'cliffhanger.com',
  'clixser.com',
  'close2you.net',
  'clrmail.com',
  'club4x4.net',
  'clubalfa.com',
  'clubbers.net',
  'clubducati.com',
  'clubhonda.net',
  'clubmember.org',
  'clubnetnoir.com',
  'clubvdo.net',
  'cluemail.com',
  'cmail.net',
  'cmpmail.com',
  'cnnsimail.com',
  'cntv.cn',
  'codec.ro',
  'coder.hu',
  'coid.biz',
  'coldmail.com',
  'collectiblesuperstore.com',
  'collector.org',
  'collegeclub.com',
  'collegemail.com',
  'colleges.com',
  'columbus.rr.com',
  'columbusrr.com',
  'columnist.com',
  'comcast.net',
  'comic.com',
  'communityconnect.com',
  'comporium.net',
  'comprendemail.com',
  'compuserve.com',
  'computer-freak.com',
  'computer4u.com',
  'computermail.net',
  'conexcol.com',
  'conk.com',
  'connect4free.net',
  'connectbox.com',
  'consultant.com',
  'consumerriot.com',
  'contractor.net',
  'contrasto.cu.cc',
  'cookiemonster.com',
  'cool.br',
  'coole-files.de',
  'coolgoose.ca',
  'coolgoose.com',
  'coolkiwi.com',
  'coollist.com',
  'coolmail.com',
  'coolmail.net',
  'coolsend.com',
  'coolsite.net',
  'cooooool.com',
  'cooperation.net',
  'cooperationtogo.net',
  'copacabana.com',
  'copper.net',
  'cornells.com',
  'cornerpub.com',
  'corporatedirtbag.com',
  'correo.terra.com.gt',
  'cortinet.com',
  'cotas.net',
  'counsellor.com',
  'countrylover.com',
  'cox.com',
  'cox.net',
  'coxinet.net',
  'cracker.hu',
  'crapmail.org',
  'crazedanddazed.com',
  'crazymailing.com',
  'crazysexycool.com',
  'cristianemail.com',
  'critterpost.com',
  'croeso.com',
  'crosshairs.com',
  'crosswinds.net',
  'crwmail.com',
  'cry4helponline.com',
  'cs.com',
  'csinibaba.hu',
  'cuemail.com',
  'curio-city.com',
  'curryworld.de',
  'cute-girl.com',
  'cuteandcuddly.com',
  'cutey.com',
  'cww.de',
  'cyber-africa.net',
  'cyber-innovation.club',
  'cyber-matrix.com',
  'cyber-phone.eu',
  'cyber-wizard.com',
  'cyber4all.com',
  'cyberbabies.com',
  'cybercafemaui.com',
  'cyberdude.com',
  'cyberforeplay.net',
  'cybergal.com',
  'cybergrrl.com',
  'cyberinbox.com',
  'cyberleports.com',
  'cybermail.net',
  'cybernet.it',
  'cyberservices.com',
  'cyberspace-asia.com',
  'cybertrains.org',
  'cyclefanz.com',
  'cynetcity.com',
  'dabsol.net',
  'dadacasa.com',
  'daha.com',
  'dailypioneer.com',
  'dallasmail.com',
  'dangerous-minds.com',
  'dansegulvet.com',
  'dasdasdascyka.tk',
  'data54.com',
  'davegracey.com',
  'dawnsonmail.com',
  'dawsonmail.com',
  'dazedandconfused.com',
  'dbzmail.com',
  'dcemail.com',
  'deadlymob.org',
  'deagot.com',
  'deal-maker.com',
  'dearriba.com',
  'death-star.com',
  'deliveryman.com',
  'deneg.net',
  'depechemode.com',
  'deseretmail.com',
  'desertmail.com',
  'desilota.com',
  'deskpilot.com',
  'destin.com',
  'detik.com',
  'deutschland-net.com',
  'devotedcouples.com',
  'dezigner.ru',
  'dfwatson.com',
  'di-ve.com',
  'die-besten-bilder.de',
  'die-genossen.de',
  'die-optimisten.de',
  'die-optimisten.net',
  'diemailbox.de',
  'digibel.be',
  'digital-filestore.de',
  'diplomats.com',
  'directbox.com',
  'dirtracer.com',
  'discard.email',
  'discard.ga',
  'discard.gq',
  'disciples.com',
  'discofan.com',
  'discoverymail.com',
  'disign-concept.eu',
  'disign-revelation.com',
  'disinfo.net',
  'dispomail.eu',
  'disposable.com',
  'dispose.it',
  'dm.w3internet.co.uk',
  'dmailman.com',
  'dnainternet.net',
  'dnsmadeeasy.com',
  'doclist.bounces.google.com',
  'docmail.cz',
  'docs.google.com',
  'doctor.com',
  'dodgit.org',
  'dodo.com.au',
  'dodsi.com',
  'dog.com',
  'dogit.com',
  'doglover.com',
  'dogmail.co.uk',
  'dogsnob.net',
  'doityourself.com',
  'domforfb1.tk',
  'domforfb2.tk',
  'domforfb3.tk',
  'domforfb4.tk',
  'domforfb5.tk',
  'domforfb6.tk',
  'domforfb7.tk',
  'domforfb8.tk',
  'domozmail.com',
  'doneasy.com',
  'donjuan.com',
  'dontgotmail.com',
  'dontmesswithtexas.com',
  'doramail.com',
  'dostmail.com',
  'dotcom.fr',
  'dotmsg.com',
  'dott.it',
  'download-privat.de',
  'dplanet.ch',
  'dr.com',
  'dragoncon.net',
  'dropmail.me',
  'dropzone.com',
  'drotposta.hu',
  'dubaimail.com',
  'dublin.com',
  'dublin.ie',
  'duck.com',
  'dumpmail.com',
  'dumpmail.de',
  'dumpyemail.com',
  'dunlopdriver.com',
  'dunloprider.com',
  'duno.com',
  'duskmail.com',
  'dutchmail.com',
  'dwp.net',
  'dygo.com',
  'dynamitemail.com',
  'dyndns.org',
  'e-apollo.lv',
  'e-mail.com.tr',
  'e-mail.dk',
  'e-mail.ru',
  'e-mail.ua',
  'e-mailanywhere.com',
  'e-mails.ru',
  'e-tapaal.com',
  'earthalliance.com',
  'earthcam.net',
  'earthdome.com',
  'earthling.net',
  'earthlink.net',
  'earthonline.net',
  'eastcoast.co.za',
  'eastmail.com',
  'easy.to',
  'easypost.com',
  'easytrashmail.com',
  'ec.rr.com',
  'ecardmail.com',
  'ecbsolutions.net',
  'echina.com',
  'ecolo-online.fr',
  'ecompare.com',
  'edmail.com',
  'ednatx.com',
  'edtnmail.com',
  'educacao.te.pt',
  'eelmail.com',
  'ehmail.com',
  'einrot.com',
  'einrot.de',
  'eintagsmail.de',
  'eircom.net',
  'elisanet.fi',
  'elitemail.org',
  'elsitio.com',
  'elvis.com',
  'elvisfan.com',
  'email-fake.gq',
  'email-london.co.uk',
  'email.biz',
  'email.cbes.net',
  'email.com',
  'email.cz',
  'email.ee',
  'email.it',
  'email.nu',
  'email.org',
  'email.ro',
  'email.ru',
  'email.si',
  'email.su',
  'email.ua',
  'email2me.net',
  'email4u.info',
  'emailacc.com',
  'emailaccount.com',
  'emailage.ga',
  'emailage.gq',
  'emailasso.net',
  'emailchoice.com',
  'emailcorner.net',
  'emailem.com',
  'emailengine.net',
  'emailengine.org',
  'emailer.hubspot.com',
  'emailforyou.net',
  'emailgo.de',
  'emailgroups.net',
  'emailinfive.com',
  'emailit.com',
  'emailpinoy.com',
  'emailplanet.com',
  'emailplus.org',
  'emailproxsy.com',
  'emails.ga',
  'emails.incisivemedia.com',
  'emails.ru',
  'emailthe.net',
  'emailto.de',
  'emailuser.net',
  'emailx.net',
  'emailz.ga',
  'emailz.gq',
  'ematic.com',
  'embarqmail.com',
  'emeil.in',
  'emeil.ir',
  'emil.com',
  'eml.cc',
  'eml.pp.ua',
  'end-war.com',
  'enel.net',
  'engineer.com',
  'england.com',
  'england.edu',
  'englandmail.com',
  'epage.ru',
  'epatra.com',
  'ephemail.net',
  'epix.net',
  'epost.de',
  'eposta.hu',
  'eqqu.com',
  'eramail.co.za',
  'eresmas.com',
  'eriga.lv',
  'estranet.it',
  'ethos.st',
  'etoast.com',
  'etrademail.com',
  'etranquil.com',
  'etranquil.net',
  'eudoramail.com',
  'europamel.net',
  'europe.com',
  'europemail.com',
  'euroseek.com',
  'eurosport.com',
  'every1.net',
  'everyday.com.kh',
  'everymail.net',
  'everyone.net',
  'everytg.ml',
  'examnotes.net',
  'excite.co.jp',
  'excite.com',
  'excite.it',
  'execs.com',
  'exemail.com.au',
  'exg6.exghost.com',
  'existiert.net',
  'expressasia.com',
  'extenda.net',
  'extended.com',
  'eyepaste.com',
  'eyou.com',
  'ezcybersearch.com',
  'ezmail.egine.com',
  'ezmail.ru',
  'ezrs.com',
  'f-m.fm',
  'f1fans.net',
  'facebook-email.ga',
  'facebook.com',
  'facebookmail.com',
  'facebookmail.gq',
  'fahr-zur-hoelle.org',
  'fake-email.pp.ua',
  'fake-mail.cf',
  'fake-mail.ga',
  'fake-mail.ml',
  'fakemailz.com',
  'falseaddress.com',
  'fan.com',
  'fansonlymail.com',
  'fansworldwide.de',
  'fantasticmail.com',
  'farang.net',
  'farifluset.mailexpire.com',
  'faroweb.com',
  'fast-email.com',
  'fast-mail.fr',
  'fast-mail.org',
  'fastacura.com',
  'fastchevy.com',
  'fastchrysler.com',
  'fastem.com',
  'fastemail.us',
  'fastemailer.com',
  'fastermail.com',
  'fastest.cc',
  'fastimap.com',
  'fastkawasaki.com',
  'fastmail.ca',
  'fastmail.cn',
  'fastmail.co.uk',
  'fastmail.com',
  'fastmail.com.au',
  'fastmail.es',
  'fastmail.fm',
  'fastmail.im',
  'fastmail.in',
  'fastmail.jp',
  'fastmail.mx',
  'fastmail.net',
  'fastmail.nl',
  'fastmail.se',
  'fastmail.to',
  'fastmail.tw',
  'fastmail.us',
  'fastmailbox.net',
  'fastmazda.com',
  'fastmessaging.com',
  'fastmitsubishi.com',
  'fastnissan.com',
  'fastservice.com',
  'fastsubaru.com',
  'fastsuzuki.com',
  'fasttoyota.com',
  'fastyamaha.com',
  'fatcock.net',
  'fatflap.com',
  'fathersrightsne.org',
  'fax.ru',
  'fbi-agent.com',
  'fbi.hu',
  'fdfdsfds.com',
  'fea.st',
  'federalcontractors.com',
  'feinripptraeger.de',
  'felicitymail.com',
  'femenino.com',
  'fetchmail.co.uk',
  'fettabernett.de',
  'feyenoorder.com',
  'ffanet.com',
  'fiberia.com',
  'ficken.de',
  'fightallspam.com',
  'filipinolinks.com',
  'financemail.net',
  'financier.com',
  'findmail.com',
  'finebody.com',
  'fire-brigade.com',
  'fireman.net',
  'fishburne.org',
  'fishfuse.com',
  'fixmail.tk',
  'fizmail.com',
  'flashbox.5july.org',
  'flashmail.com',
  'flashmail.net',
  'fleckens.hu',
  'flipcode.com',
  'fmail.co.uk',
  'fmailbox.com',
  'fmgirl.com',
  'fmguy.com',
  'fnbmail.co.za',
  'fnmail.com',
  'folkfan.com',
  'foodmail.com',
  'footard.com',
  'footballmail.com',
  'foothills.net',
  'for-president.com',
  'force9.co.uk',
  'forfree.at',
  'forgetmail.com',
  'fornow.eu',
  'forpresident.com',
  'fortuncity.com',
  'fortunecity.com',
  'forum.dk',
  'foxmail.com',
  'fr33mail.info',
  'francemel.fr',
  'free-email.ga',
  'free-online.net',
  'free-org.com',
  'free.com.pe',
  'free.fr',
  'freeaccess.nl',
  'freeaccount.com',
  'freeandsingle.com',
  'freedom.usa.com',
  'freedomlover.com',
  'freegates.be',
  'freeghana.com',
  'freelance-france.eu',
  'freeler.nl',
  'freemail.c3.hu',
  'freemail.com.au',
  'freemail.com.pk',
  'freemail.de',
  'freemail.et',
  'freemail.gr',
  'freemail.hu',
  'freemail.it',
  'freemail.lt',
  'freemail.ms',
  'freemail.nl',
  'freemail.org.mk',
  'freemails.ga',
  'freemeil.gq',
  'freenet.de',
  'freenet.kg',
  'freeola.com',
  'freeola.net',
  'freeserve.co.uk',
  'freestart.hu',
  'freesurf.fr',
  'freesurf.nl',
  'freeuk.com',
  'freeuk.net',
  'freeukisp.co.uk',
  'freeweb.org',
  'freewebemail.com',
  'freeyellow.com',
  'freezone.co.uk',
  'fresnomail.com',
  'freudenkinder.de',
  'freundin.ru',
  'friendlymail.co.uk',
  'friends-cafe.com',
  'friendsfan.com',
  'from-africa.com',
  'from-america.com',
  'from-argentina.com',
  'from-asia.com',
  'from-australia.com',
  'from-belgium.com',
  'from-brazil.com',
  'from-canada.com',
  'from-china.net',
  'from-england.com',
  'from-europe.com',
  'from-france.net',
  'from-germany.net',
  'from-holland.com',
  'from-israel.com',
  'from-italy.net',
  'from-japan.net',
  'from-korea.com',
  'from-mexico.com',
  'from-outerspace.com',
  'from-russia.com',
  'from-spain.net',
  'fromalabama.com',
  'fromalaska.com',
  'fromarizona.com',
  'fromarkansas.com',
  'fromcalifornia.com',
  'fromcolorado.com',
  'fromconnecticut.com',
  'fromdelaware.com',
  'fromflorida.net',
  'fromgeorgia.com',
  'fromhawaii.net',
  'fromidaho.com',
  'fromillinois.com',
  'fromindiana.com',
  'fromiowa.com',
  'fromjupiter.com',
  'fromkansas.com',
  'fromkentucky.com',
  'fromlouisiana.com',
  'frommaine.net',
  'frommaryland.com',
  'frommassachusetts.com',
  'frommiami.com',
  'frommichigan.com',
  'fromminnesota.com',
  'frommississippi.com',
  'frommissouri.com',
  'frommontana.com',
  'fromnebraska.com',
  'fromnevada.com',
  'fromnewhampshire.com',
  'fromnewjersey.com',
  'fromnewmexico.com',
  'fromnewyork.net',
  'fromnorthcarolina.com',
  'fromnorthdakota.com',
  'fromohio.com',
  'fromoklahoma.com',
  'fromoregon.net',
  'frompennsylvania.com',
  'fromrhodeisland.com',
  'fromru.com',
  'fromsouthcarolina.com',
  'fromsouthdakota.com',
  'fromtennessee.com',
  'fromtexas.com',
  'fromthestates.com',
  'fromutah.com',
  'fromvermont.com',
  'fromvirginia.com',
  'fromwashington.com',
  'fromwashingtondc.com',
  'fromwestvirginia.com',
  'fromwisconsin.com',
  'fromwyoming.com',
  'front.ru',
  'frontier.com',
  'frontiernet.net',
  'frostbyte.uk.net',
  'fsmail.net',
  'ftc-i.net',
  'ftml.net',
  'fullmail.com',
  'funkfan.com',
  'fuorissimo.com',
  'furnitureprovider.com',
  'fuse.net',
  'fut.es',
  'fux0ringduh.com',
  'fwnb.com',
  'fxsmails.com',
  'galaxy5.com',
  'galaxyhit.com',
  'gamebox.net',
  'gamegeek.com',
  'gamespotmail.com',
  'gamno.config.work',
  'garbage.com',
  'gardener.com',
  'gawab.com',
  'gaybrighton.co.uk',
  'gaza.net',
  'gazeta.pl',
  'gazibooks.com',
  'gci.net',
  'geecities.com',
  'geek.com',
  'geek.hu',
  'geeklife.com',
  'gelitik.in',
  'gencmail.com',
  'general-hospital.com',
  'gentlemansclub.de',
  'geocities.com',
  'geography.net',
  'geologist.com',
  'geopia.com',
  'germanymail.com',
  'get.pp.ua',
  'get1mail.com',
  'getairmail.cf',
  'getairmail.com',
  'getairmail.ga',
  'getairmail.gq',
  'getonemail.net',
  'ghanamail.com',
  'ghostmail.com',
  'ghosttexter.de',
  'giga4u.de',
  'gigileung.org',
  'girl4god.com',
  'givepeaceachance.com',
  'glay.org',
  'glendale.net',
  'globalfree.it',
  'globalpagan.com',
  'globalsite.com.br',
  'gmail.com',
  'gmail.com.br',
  'gmail.ru',
  'gmx.at',
  'gmx.ch',
  'gmx.com',
  'gmx.de',
  'gmx.li',
  'gmx.net',
  'go.com',
  'go.ro',
  'go.ru',
  'go2net.com',
  'gocollege.com',
  'gocubs.com',
  'goemailgo.com',
  'gofree.co.uk',
  'gol.com',
  'goldenmail.ru',
  'goldmail.ru',
  'goldtoolbox.com',
  'golfemail.com',
  'golfilla.info',
  'golfmail.be',
  'gonavy.net',
  'goodnewsmail.com',
  'goodstick.com',
  'googlegroups.com',
  'googlemail.com',
  'goplay.com',
  'gorillaswithdirtyarmpits.com',
  'gorontalo.net',
  'gospelfan.com',
  'gothere.uk.com',
  'gotmail.com',
  'gotmail.org',
  'gotomy.com',
  'gotti.otherinbox.com',
  'gportal.hu',
  'graduate.org',
  'graffiti.net',
  'gramszu.net',
  'grandmamail.com',
  'grandmasmail.com',
  'graphic-designer.com',
  'grapplers.com',
  'gratisweb.com',
  'greenmail.net',
  'groupmail.com',
  'grr.la',
  'grungecafe.com',
  'gtmc.net',
  'gua.net',
  'guessmail.com',
  'guju.net',
  'gustr.com',
  'guy.com',
  'guy2.com',
  'guyanafriends.com',
  'gyorsposta.com',
  'gyorsposta.hu',
  'h-mail.us',
  'hab-verschlafen.de',
  'habmalnefrage.de',
  'hacccc.com',
  'hackermail.com',
  'hackermail.net',
  'hailmail.net',
  'hairdresser.net',
  'hamptonroads.com',
  'handbag.com',
  'handleit.com',
  'hang-ten.com',
  'hanmail.net',
  'happemail.com',
  'happycounsel.com',
  'happypuppy.com',
  'harakirimail.com',
  'hardcorefreak.com',
  'hartbot.de',
  'hawaii.rr.com',
  'hawaiiantel.net',
  'heartthrob.com',
  'heerschap.com',
  'heesun.net',
  'hehe.com',
  'hello.hu',
  'hello.net.au',
  'hello.to',
  'helter-skelter.com',
  'herediano.com',
  'herono1.com',
  'herp.in',
  'herr-der-mails.de',
  'hetnet.nl',
  'hey.to',
  'hhdevel.com',
  'hidzz.com',
  'highmilton.com',
  'highquality.com',
  'highveldmail.co.za',
  'hilarious.com',
  'hiphopfan.com',
  'hispavista.com',
  'hitmail.com',
  'hitthe.net',
  'hkg.net',
  'hkstarphoto.com',
  'hockeymail.com',
  'hollywoodkids.com',
  'home-email.com',
  'home.de',
  'home.nl',
  'home.no.net',
  'home.ro',
  'home.se',
  'homelocator.com',
  'homemail.com',
  'homestead.com',
  'honduras.com',
  'hongkong.com',
  'hookup.net',
  'hoopsmail.com',
  'hopemail.biz',
  'horrormail.com',
  'hot-mail.gq',
  'hot-shot.com',
  'hot.ee',
  'hotbot.com',
  'hotbrev.com',
  'hotfire.net',
  'hotletter.com',
  'hotmail.ca',
  'hotmail.ch',
  'hotmail.co',
  'hotmail.co.il',
  'hotmail.co.jp',
  'hotmail.co.nz',
  'hotmail.co.uk',
  'hotmail.co.za',
  'hotmail.com',
  'hotmail.com.au',
  'hotmail.com.br',
  'hotmail.com.tr',
  'hotmail.de',
  'hotmail.es',
  'hotmail.fi',
  'hotmail.fr',
  'hotmail.it',
  'hotmail.kg',
  'hotmail.kz',
  'hotmail.nl',
  'hotmail.ru',
  'hotmail.se',
  'hotpop.com',
  'hotpop3.com',
  'hotvoice.com',
  'housemail.com',
  'hsuchi.net',
  'hu2.ru',
  'hughes.net',
  'humanoid.net',
  'humn.ws.gy',
  'hunsa.com',
  'hurting.com',
  'hush.com',
  'hushmail.com',
  'hypernautica.com',
  'i-connect.com',
  'i-france.com',
  'i-mail.com.au',
  'i-p.com',
  'i.am',
  'i.ua',
  'i12.com',
  'i2pmail.org',
  'iamawoman.com',
  'iamwaiting.com',
  'iamwasted.com',
  'iamyours.com',
  'icestorm.com',
  'ich-bin-verrueckt-nach-dir.de',
  'ich-will-net.de',
  'icloud.com',
  'icmsconsultants.com',
  'icq.com',
  'icqmail.com',
  'icrazy.com',
  'id-base.com',
  'ididitmyway.com',
  'idigjesus.com',
  'idirect.com',
  'ieatspam.eu',
  'ieatspam.info',
  'ieh-mail.de',
  'iespana.es',
  'ifoward.com',
  'ig.com.br',
  'ignazio.it',
  'ignmail.com',
  'ihateclowns.com',
  'ihateyoualot.info',
  'iheartspam.org',
  'iinet.net.au',
  'ijustdontcare.com',
  'ikbenspamvrij.nl',
  'ilkposta.com',
  'ilovechocolate.com',
  'ilovejesus.com',
  'ilovetocollect.net',
  'ilse.nl',
  'imaginemail.com',
  'imail.ru',
  'imailbox.com',
  'imap-mail.com',
  'imap.cc',
  'imapmail.org',
  'imel.org',
  'imgof.com',
  'imgv.de',
  'immo-gerance.info',
  'imneverwrong.com',
  'imposter.co.uk',
  'imstations.com',
  'imstressed.com',
  'imtoosexy.com',
  'in-box.net',
  'in2jesus.com',
  'iname.com',
  'inbax.tk',
  'inbound.plus',
  'inbox.com',
  'inbox.net',
  'inbox.ru',
  'inbox.si',
  'inboxalias.com',
  'incamail.com',
  'incredimail.com',
  'indeedemail.com',
  'index.ua',
  'indexa.fr',
  'india.com',
  'indiatimes.com',
  'indo-mail.com',
  'indocities.com',
  'indomail.com',
  'indyracers.com',
  'inerted.com',
  'inet.com',
  'inet.net.au',
  'info-media.de',
  'info-radio.ml',
  'info66.com',
  'infohq.com',
  'infomail.es',
  'infomart.or.jp',
  'infospacemail.com',
  'infovia.com.ar',
  'inicia.es',
  'inmail.sk',
  'inmail24.com',
  'inmano.com',
  'inmynetwork.tk',
  'innocent.com',
  'inorbit.com',
  'inoutbox.com',
  'insidebaltimore.net',
  'insight.rr.com',
  'instant-mail.de',
  'instantemailaddress.com',
  'instantmail.fr',
  'instruction.com',
  'instructor.net',
  'insurer.com',
  'interburp.com',
  'interfree.it',
  'interia.pl',
  'interlap.com.ar',
  'intermail.co.il',
  'internet-e-mail.com',
  'internet-mail.org',
  'internet-police.com',
  'internetbiz.com',
  'internetdrive.com',
  'internetegypt.com',
  'internetemails.net',
  'internetmailing.net',
  'internode.on.net',
  'invalid.com',
  'inwind.it',
  'iobox.com',
  'iobox.fi',
  'iol.it',
  'iol.pt',
  'iowaemail.com',
  'ip3.com',
  'ip4.pp.ua',
  'ip6.pp.ua',
  'ipoo.org',
  'iprimus.com.au',
  'iqemail.com',
  'irangate.net',
  'iraqmail.com',
  'ireland.com',
  'irelandmail.com',
  'iremail.de',
  'irj.hu',
  'iroid.com',
  'isellcars.com',
  'iservejesus.com',
  'islamonline.net',
  'isleuthmail.com',
  'ismart.net',
  'isonfire.com',
  'isp9.net',
  'israelmail.com',
  'ist-allein.info',
  'ist-einmalig.de',
  'ist-ganz-allein.de',
  'ist-willig.de',
  'italymail.com',
  'itloox.com',
  'itmom.com',
  'ivebeenframed.com',
  'ivillage.com',
  'iwan-fals.com',
  'iwmail.com',
  'iwon.com',
  'izadpanah.com',
  'jahoopa.com',
  'jakuza.hu',
  'japan.com',
  'jaydemail.com',
  'jazzandjava.com',
  'jazzfan.com',
  'jazzgame.com',
  'je-recycle.info',
  'jerusalemmail.com',
  'jet-renovation.fr',
  'jetable.de',
  'jetable.pp.ua',
  'jetemail.net',
  'jippii.fi',
  'jmail.co.za',
  'job4u.com',
  'jobbikszimpatizans.hu',
  'joelonsoftware.com',
  'joinme.com',
  'jokes.com',
  'jordanmail.com',
  'journalist.com',
  'jourrapide.com',
  'jovem.te.pt',
  'joymail.com',
  'jpopmail.com',
  'jsrsolutions.com',
  'jubiimail.dk',
  'jump.com',
  'jumpy.it',
  'juniormail.com',
  'junk1e.com',
  'junkmail.com',
  'junkmail.gq',
  'juno.com',
  'justemail.net',
  'justicemail.com',
  'kaazoo.com',
  'kaffeeschluerfer.com',
  'kaffeeschluerfer.de',
  'kaixo.com',
  'kalpoint.com',
  'kansascity.com',
  'kapoorweb.com',
  'karachian.com',
  'karachioye.com',
  'karbasi.com',
  'katamail.com',
  'kayafmmail.co.za',
  'kbjrmail.com',
  'kcks.com',
  'keg-party.com',
  'keinpardon.de',
  'keko.com.ar',
  'kellychen.com',
  'keromail.com',
  'keyemail.com',
  'kgb.hu',
  'khosropour.com',
  'kickassmail.com',
  'killermail.com',
  'kimo.com',
  'kimsdisk.com',
  'kinglibrary.net',
  'kinki-kids.com',
  'kissfans.com',
  'kittymail.com',
  'kitznet.at',
  'kiwibox.com',
  'kiwitown.com',
  'klassmaster.net',
  'km.ru',
  'knol-power.nl',
  'kolumbus.fi',
  'kommespaeter.de',
  'konx.com',
  'korea.com',
  'koreamail.com',
  'kpnmail.nl',
  'krim.ws',
  'krongthip.com',
  'krunis.com',
  'ksanmail.com',
  'ksee24mail.com',
  'kube93mail.com',
  'kukamail.com',
  'kulturbetrieb.info',
  'kumarweb.com',
  'kuwait-mail.com',
  'l33r.eu',
  'la.com',
  'labetteraverouge.at',
  'ladymail.cz',
  'lagerlouts.com',
  'lags.us',
  'lahoreoye.com',
  'lakmail.com',
  'lamer.hu',
  'land.ru',
  'lankamail.com',
  'laoeq.com',
  'laposte.net',
  'lass-es-geschehen.de',
  'last-chance.pro',
  'lastmail.co',
  'latemodels.com',
  'latinmail.com',
  'lavache.com',
  'law.com',
  'lawyer.com',
  'lazyinbox.com',
  'leehom.net',
  'legalactions.com',
  'legalrc.loan',
  'legislator.com',
  'lenta.ru',
  'leonlai.net',
  'letsgomets.net',
  'letterboxes.org',
  'letthemeatspam.com',
  'levele.com',
  'levele.hu',
  'lex.bg',
  'lexis-nexis-mail.com',
  'libero.it',
  'liberomail.com',
  'lick101.com',
  'liebt-dich.info',
  'linkmaster.com',
  'linktrader.com',
  'linuxfreemail.com',
  'linuxmail.org',
  'lionsfan.com.au',
  'liontrucks.com',
  'liquidinformation.net',
  'list.ru',
  'listomail.com',
  'littleapple.com',
  'littleblueroom.com',
  'live.at',
  'live.be',
  'live.ca',
  'live.cl',
  'live.cn',
  'live.co.uk',
  'live.co.za',
  'live.com',
  'live.com.ar',
  'live.com.au',
  'live.com.mx',
  'live.com.pt',
  'live.com.sg',
  'live.de',
  'live.dk',
  'live.fr',
  'live.ie',
  'live.in',
  'live.it',
  'live.jp',
  'live.nl',
  'live.no',
  'live.ru',
  'live.se',
  'liveradio.tk',
  'liverpoolfans.com',
  'llandudno.com',
  'llangollen.com',
  'lmxmail.sk',
  'lobbyist.com',
  'localbar.com',
  'locos.com',
  'login-email.ga',
  'loh.pp.ua',
  'lolfreak.net',
  'lolito.tk',
  'london.com',
  'looksmart.co.uk',
  'looksmart.com',
  'looksmart.com.au',
  'lopezclub.com',
  'louiskoo.com',
  'love.cz',
  'loveable.com',
  'lovecat.com',
  'lovefall.ml',
  'lovefootball.com',
  'lovelygirl.net',
  'lovemail.com',
  'lover-boy.com',
  'lovergirl.com',
  'lovesea.gq',
  'lovethebroncos.com',
  'lovethecowboys.com',
  'loveyouforever.de',
  'lovingjesus.com',
  'lowandslow.com',
  'lr7.us',
  'lroid.com',
  'luso.pt',
  'luukku.com',
  'luv2.us',
  'lvie.com.sg',
  'lycos.co.uk',
  'lycos.com',
  'lycos.es',
  'lycos.it',
  'lycos.ne.jp',
  'lycosmail.com',
  'm-a-i-l.com',
  'm-hmail.com',
  'm4.org',
  'm4ilweb.info',
  'mac.com',
  'macbox.com',
  'macfreak.com',
  'machinecandy.com',
  'macmail.com',
  'madcreations.com',
  'madonnafan.com',
  'madrid.com',
  'maennerversteherin.com',
  'maennerversteherin.de',
  'maffia.hu',
  'magicmail.co.za',
  'magspam.net',
  'mahmoodweb.com',
  'mail.bg',
  'mail-awu.de',
  'mail-box.cz',
  'mail-center.com',
  'mail-central.com',
  'mail-easy.fr',
  'mail-filter.com',
  'mail-me.com',
  'mail-page.com',
  'mail-tester.com',
  'mail.austria.com',
  'mail.az',
  'mail.be',
  'mail.bulgaria.com',
  'mail.by',
  'mail.co.za',
  'mail.com',
  'mail.com.tr',
  'mail.de',
  'mail.ee',
  'mail.entrepeneurmag.com',
  'mail.freetown.com',
  'mail.gr',
  'mail.hitthebeach.com',
  'mail.htl22.at',
  'mail.md',
  'mail.misterpinball.de',
  'mail.nu',
  'mail.org.uk',
  'mail.pf',
  'mail.pt',
  'mail.r-o-o-t.com',
  'mail.ru',
  'mail.sisna.com',
  'mail.svenz.eu',
  'mail.usa.com',
  'mail.vasarhely.hu',
  'mail.wtf',
  'mail114.net',
  'mail15.com',
  'mail2007.com',
  'mail2aaron.com',
  'mail2abby.com',
  'mail2abc.com',
  'mail2actor.com',
  'mail2admiral.com',
  'mail2adorable.com',
  'mail2adoration.com',
  'mail2adore.com',
  'mail2adventure.com',
  'mail2aeolus.com',
  'mail2aether.com',
  'mail2affection.com',
  'mail2afghanistan.com',
  'mail2africa.com',
  'mail2agent.com',
  'mail2aha.com',
  'mail2ahoy.com',
  'mail2aim.com',
  'mail2air.com',
  'mail2airbag.com',
  'mail2airforce.com',
  'mail2airport.com',
  'mail2alabama.com',
  'mail2alan.com',
  'mail2alaska.com',
  'mail2albania.com',
  'mail2alcoholic.com',
  'mail2alec.com',
  'mail2alexa.com',
  'mail2algeria.com',
  'mail2alicia.com',
  'mail2alien.com',
  'mail2allan.com',
  'mail2allen.com',
  'mail2allison.com',
  'mail2alpha.com',
  'mail2alyssa.com',
  'mail2amanda.com',
  'mail2amazing.com',
  'mail2amber.com',
  'mail2america.com',
  'mail2american.com',
  'mail2andorra.com',
  'mail2andrea.com',
  'mail2andy.com',
  'mail2anesthesiologist.com',
  'mail2angela.com',
  'mail2angola.com',
  'mail2ann.com',
  'mail2anna.com',
  'mail2anne.com',
  'mail2anthony.com',
  'mail2anything.com',
  'mail2aphrodite.com',
  'mail2apollo.com',
  'mail2april.com',
  'mail2aquarius.com',
  'mail2arabia.com',
  'mail2arabic.com',
  'mail2architect.com',
  'mail2ares.com',
  'mail2argentina.com',
  'mail2aries.com',
  'mail2arizona.com',
  'mail2arkansas.com',
  'mail2armenia.com',
  'mail2army.com',
  'mail2arnold.com',
  'mail2art.com',
  'mail2artemus.com',
  'mail2arthur.com',
  'mail2artist.com',
  'mail2ashley.com',
  'mail2ask.com',
  'mail2astronomer.com',
  'mail2athena.com',
  'mail2athlete.com',
  'mail2atlas.com',
  'mail2atom.com',
  'mail2attitude.com',
  'mail2auction.com',
  'mail2aunt.com',
  'mail2australia.com',
  'mail2austria.com',
  'mail2azerbaijan.com',
  'mail2baby.com',
  'mail2bahamas.com',
  'mail2bahrain.com',
  'mail2ballerina.com',
  'mail2ballplayer.com',
  'mail2band.com',
  'mail2bangladesh.com',
  'mail2bank.com',
  'mail2banker.com',
  'mail2bankrupt.com',
  'mail2baptist.com',
  'mail2bar.com',
  'mail2barbados.com',
  'mail2barbara.com',
  'mail2barter.com',
  'mail2basketball.com',
  'mail2batter.com',
  'mail2beach.com',
  'mail2beast.com',
  'mail2beatles.com',
  'mail2beauty.com',
  'mail2becky.com',
  'mail2beijing.com',
  'mail2belgium.com',
  'mail2belize.com',
  'mail2ben.com',
  'mail2bernard.com',
  'mail2beth.com',
  'mail2betty.com',
  'mail2beverly.com',
  'mail2beyond.com',
  'mail2biker.com',
  'mail2bill.com',
  'mail2billionaire.com',
  'mail2billy.com',
  'mail2bio.com',
  'mail2biologist.com',
  'mail2black.com',
  'mail2blackbelt.com',
  'mail2blake.com',
  'mail2blind.com',
  'mail2blonde.com',
  'mail2blues.com',
  'mail2bob.com',
  'mail2bobby.com',
  'mail2bolivia.com',
  'mail2bombay.com',
  'mail2bonn.com',
  'mail2bookmark.com',
  'mail2boreas.com',
  'mail2bosnia.com',
  'mail2boston.com',
  'mail2botswana.com',
  'mail2bradley.com',
  'mail2brazil.com',
  'mail2breakfast.com',
  'mail2brian.com',
  'mail2bride.com',
  'mail2brittany.com',
  'mail2broker.com',
  'mail2brook.com',
  'mail2bruce.com',
  'mail2brunei.com',
  'mail2brunette.com',
  'mail2brussels.com',
  'mail2bryan.com',
  'mail2bug.com',
  'mail2bulgaria.com',
  'mail2business.com',
  'mail2buy.com',
  'mail2ca.com',
  'mail2california.com',
  'mail2calvin.com',
  'mail2cambodia.com',
  'mail2cameroon.com',
  'mail2canada.com',
  'mail2cancer.com',
  'mail2capeverde.com',
  'mail2capricorn.com',
  'mail2cardinal.com',
  'mail2cardiologist.com',
  'mail2care.com',
  'mail2caroline.com',
  'mail2carolyn.com',
  'mail2casey.com',
  'mail2cat.com',
  'mail2caterer.com',
  'mail2cathy.com',
  'mail2catlover.com',
  'mail2catwalk.com',
  'mail2cell.com',
  'mail2chad.com',
  'mail2champaign.com',
  'mail2charles.com',
  'mail2chef.com',
  'mail2chemist.com',
  'mail2cherry.com',
  'mail2chicago.com',
  'mail2chile.com',
  'mail2china.com',
  'mail2chinese.com',
  'mail2chocolate.com',
  'mail2christian.com',
  'mail2christie.com',
  'mail2christmas.com',
  'mail2christy.com',
  'mail2chuck.com',
  'mail2cindy.com',
  'mail2clark.com',
  'mail2classifieds.com',
  'mail2claude.com',
  'mail2cliff.com',
  'mail2clinic.com',
  'mail2clint.com',
  'mail2close.com',
  'mail2club.com',
  'mail2coach.com',
  'mail2coastguard.com',
  'mail2colin.com',
  'mail2college.com',
  'mail2colombia.com',
  'mail2color.com',
  'mail2colorado.com',
  'mail2columbia.com',
  'mail2comedian.com',
  'mail2composer.com',
  'mail2computer.com',
  'mail2computers.com',
  'mail2concert.com',
  'mail2congo.com',
  'mail2connect.com',
  'mail2connecticut.com',
  'mail2consultant.com',
  'mail2convict.com',
  'mail2cook.com',
  'mail2cool.com',
  'mail2cory.com',
  'mail2costarica.com',
  'mail2country.com',
  'mail2courtney.com',
  'mail2cowboy.com',
  'mail2cowgirl.com',
  'mail2craig.com',
  'mail2crave.com',
  'mail2crazy.com',
  'mail2create.com',
  'mail2croatia.com',
  'mail2cry.com',
  'mail2crystal.com',
  'mail2cuba.com',
  'mail2culture.com',
  'mail2curt.com',
  'mail2customs.com',
  'mail2cute.com',
  'mail2cutey.com',
  'mail2cynthia.com',
  'mail2cyprus.com',
  'mail2czechrepublic.com',
  'mail2dad.com',
  'mail2dale.com',
  'mail2dallas.com',
  'mail2dan.com',
  'mail2dana.com',
  'mail2dance.com',
  'mail2dancer.com',
  'mail2danielle.com',
  'mail2danny.com',
  'mail2darlene.com',
  'mail2darling.com',
  'mail2darren.com',
  'mail2daughter.com',
  'mail2dave.com',
  'mail2dawn.com',
  'mail2dc.com',
  'mail2dealer.com',
  'mail2deanna.com',
  'mail2dearest.com',
  'mail2debbie.com',
  'mail2debby.com',
  'mail2deer.com',
  'mail2delaware.com',
  'mail2delicious.com',
  'mail2demeter.com',
  'mail2democrat.com',
  'mail2denise.com',
  'mail2denmark.com',
  'mail2dennis.com',
  'mail2dentist.com',
  'mail2derek.com',
  'mail2desert.com',
  'mail2devoted.com',
  'mail2devotion.com',
  'mail2diamond.com',
  'mail2diana.com',
  'mail2diane.com',
  'mail2diehard.com',
  'mail2dilemma.com',
  'mail2dillon.com',
  'mail2dinner.com',
  'mail2dinosaur.com',
  'mail2dionysos.com',
  'mail2diplomat.com',
  'mail2director.com',
  'mail2dirk.com',
  'mail2disco.com',
  'mail2dive.com',
  'mail2diver.com',
  'mail2divorced.com',
  'mail2djibouti.com',
  'mail2doctor.com',
  'mail2doglover.com',
  'mail2dominic.com',
  'mail2dominica.com',
  'mail2dominicanrepublic.com',
  'mail2don.com',
  'mail2donald.com',
  'mail2donna.com',
  'mail2doris.com',
  'mail2dorothy.com',
  'mail2doug.com',
  'mail2dough.com',
  'mail2douglas.com',
  'mail2dow.com',
  'mail2downtown.com',
  'mail2dream.com',
  'mail2dreamer.com',
  'mail2dude.com',
  'mail2dustin.com',
  'mail2dyke.com',
  'mail2dylan.com',
  'mail2earl.com',
  'mail2earth.com',
  'mail2eastend.com',
  'mail2eat.com',
  'mail2economist.com',
  'mail2ecuador.com',
  'mail2eddie.com',
  'mail2edgar.com',
  'mail2edwin.com',
  'mail2egypt.com',
  'mail2electron.com',
  'mail2eli.com',
  'mail2elizabeth.com',
  'mail2ellen.com',
  'mail2elliot.com',
  'mail2elsalvador.com',
  'mail2elvis.com',
  'mail2emergency.com',
  'mail2emily.com',
  'mail2engineer.com',
  'mail2english.com',
  'mail2environmentalist.com',
  'mail2eos.com',
  'mail2eric.com',
  'mail2erica.com',
  'mail2erin.com',
  'mail2erinyes.com',
  'mail2eris.com',
  'mail2eritrea.com',
  'mail2ernie.com',
  'mail2eros.com',
  'mail2estonia.com',
  'mail2ethan.com',
  'mail2ethiopia.com',
  'mail2eu.com',
  'mail2europe.com',
  'mail2eurus.com',
  'mail2eva.com',
  'mail2evan.com',
  'mail2evelyn.com',
  'mail2everything.com',
  'mail2exciting.com',
  'mail2expert.com',
  'mail2fairy.com',
  'mail2faith.com',
  'mail2fanatic.com',
  'mail2fancy.com',
  'mail2fantasy.com',
  'mail2farm.com',
  'mail2farmer.com',
  'mail2fashion.com',
  'mail2fat.com',
  'mail2feeling.com',
  'mail2female.com',
  'mail2fever.com',
  'mail2fighter.com',
  'mail2fiji.com',
  'mail2filmfestival.com',
  'mail2films.com',
  'mail2finance.com',
  'mail2finland.com',
  'mail2fireman.com',
  'mail2firm.com',
  'mail2fisherman.com',
  'mail2flexible.com',
  'mail2florence.com',
  'mail2florida.com',
  'mail2floyd.com',
  'mail2fly.com',
  'mail2fond.com',
  'mail2fondness.com',
  'mail2football.com',
  'mail2footballfan.com',
  'mail2found.com',
  'mail2france.com',
  'mail2frank.com',
  'mail2frankfurt.com',
  'mail2franklin.com',
  'mail2fred.com',
  'mail2freddie.com',
  'mail2free.com',
  'mail2freedom.com',
  'mail2french.com',
  'mail2freudian.com',
  'mail2friendship.com',
  'mail2from.com',
  'mail2fun.com',
  'mail2gabon.com',
  'mail2gabriel.com',
  'mail2gail.com',
  'mail2galaxy.com',
  'mail2gambia.com',
  'mail2games.com',
  'mail2gary.com',
  'mail2gavin.com',
  'mail2gemini.com',
  'mail2gene.com',
  'mail2genes.com',
  'mail2geneva.com',
  'mail2george.com',
  'mail2georgia.com',
  'mail2gerald.com',
  'mail2german.com',
  'mail2germany.com',
  'mail2ghana.com',
  'mail2gilbert.com',
  'mail2gina.com',
  'mail2girl.com',
  'mail2glen.com',
  'mail2gloria.com',
  'mail2goddess.com',
  'mail2gold.com',
  'mail2golfclub.com',
  'mail2golfer.com',
  'mail2gordon.com',
  'mail2government.com',
  'mail2grab.com',
  'mail2grace.com',
  'mail2graham.com',
  'mail2grandma.com',
  'mail2grandpa.com',
  'mail2grant.com',
  'mail2greece.com',
  'mail2green.com',
  'mail2greg.com',
  'mail2grenada.com',
  'mail2gsm.com',
  'mail2guard.com',
  'mail2guatemala.com',
  'mail2guy.com',
  'mail2hades.com',
  'mail2haiti.com',
  'mail2hal.com',
  'mail2handhelds.com',
  'mail2hank.com',
  'mail2hannah.com',
  'mail2harold.com',
  'mail2harry.com',
  'mail2hawaii.com',
  'mail2headhunter.com',
  'mail2heal.com',
  'mail2heather.com',
  'mail2heaven.com',
  'mail2hebe.com',
  'mail2hecate.com',
  'mail2heidi.com',
  'mail2helen.com',
  'mail2hell.com',
  'mail2help.com',
  'mail2helpdesk.com',
  'mail2henry.com',
  'mail2hephaestus.com',
  'mail2hera.com',
  'mail2hercules.com',
  'mail2herman.com',
  'mail2hermes.com',
  'mail2hespera.com',
  'mail2hestia.com',
  'mail2highschool.com',
  'mail2hindu.com',
  'mail2hip.com',
  'mail2hiphop.com',
  'mail2holland.com',
  'mail2holly.com',
  'mail2hollywood.com',
  'mail2homer.com',
  'mail2honduras.com',
  'mail2honey.com',
  'mail2hongkong.com',
  'mail2hope.com',
  'mail2horse.com',
  'mail2hot.com',
  'mail2hotel.com',
  'mail2houston.com',
  'mail2howard.com',
  'mail2hugh.com',
  'mail2human.com',
  'mail2hungary.com',
  'mail2hungry.com',
  'mail2hygeia.com',
  'mail2hyperspace.com',
  'mail2hypnos.com',
  'mail2ian.com',
  'mail2ice-cream.com',
  'mail2iceland.com',
  'mail2idaho.com',
  'mail2idontknow.com',
  'mail2illinois.com',
  'mail2imam.com',
  'mail2in.com',
  'mail2india.com',
  'mail2indian.com',
  'mail2indiana.com',
  'mail2indonesia.com',
  'mail2infinity.com',
  'mail2intense.com',
  'mail2iowa.com',
  'mail2iran.com',
  'mail2iraq.com',
  'mail2ireland.com',
  'mail2irene.com',
  'mail2iris.com',
  'mail2irresistible.com',
  'mail2irving.com',
  'mail2irwin.com',
  'mail2isaac.com',
  'mail2israel.com',
  'mail2italian.com',
  'mail2italy.com',
  'mail2jackie.com',
  'mail2jacob.com',
  'mail2jail.com',
  'mail2jaime.com',
  'mail2jake.com',
  'mail2jamaica.com',
  'mail2james.com',
  'mail2jamie.com',
  'mail2jan.com',
  'mail2jane.com',
  'mail2janet.com',
  'mail2janice.com',
  'mail2japan.com',
  'mail2japanese.com',
  'mail2jasmine.com',
  'mail2jason.com',
  'mail2java.com',
  'mail2jay.com',
  'mail2jazz.com',
  'mail2jed.com',
  'mail2jeffrey.com',
  'mail2jennifer.com',
  'mail2jenny.com',
  'mail2jeremy.com',
  'mail2jerry.com',
  'mail2jessica.com',
  'mail2jessie.com',
  'mail2jesus.com',
  'mail2jew.com',
  'mail2jeweler.com',
  'mail2jim.com',
  'mail2jimmy.com',
  'mail2joan.com',
  'mail2joann.com',
  'mail2joanna.com',
  'mail2jody.com',
  'mail2joe.com',
  'mail2joel.com',
  'mail2joey.com',
  'mail2john.com',
  'mail2join.com',
  'mail2jon.com',
  'mail2jonathan.com',
  'mail2jones.com',
  'mail2jordan.com',
  'mail2joseph.com',
  'mail2josh.com',
  'mail2joy.com',
  'mail2juan.com',
  'mail2judge.com',
  'mail2judy.com',
  'mail2juggler.com',
  'mail2julian.com',
  'mail2julie.com',
  'mail2jumbo.com',
  'mail2junk.com',
  'mail2justin.com',
  'mail2justme.com',
  'mail2k.ru',
  'mail2kansas.com',
  'mail2karate.com',
  'mail2karen.com',
  'mail2karl.com',
  'mail2karma.com',
  'mail2kathleen.com',
  'mail2kathy.com',
  'mail2katie.com',
  'mail2kay.com',
  'mail2kazakhstan.com',
  'mail2keen.com',
  'mail2keith.com',
  'mail2kelly.com',
  'mail2kelsey.com',
  'mail2ken.com',
  'mail2kendall.com',
  'mail2kennedy.com',
  'mail2kenneth.com',
  'mail2kenny.com',
  'mail2kentucky.com',
  'mail2kenya.com',
  'mail2kerry.com',
  'mail2kevin.com',
  'mail2kim.com',
  'mail2kimberly.com',
  'mail2king.com',
  'mail2kirk.com',
  'mail2kiss.com',
  'mail2kosher.com',
  'mail2kristin.com',
  'mail2kurt.com',
  'mail2kuwait.com',
  'mail2kyle.com',
  'mail2kyrgyzstan.com',
  'mail2la.com',
  'mail2lacrosse.com',
  'mail2lance.com',
  'mail2lao.com',
  'mail2larry.com',
  'mail2latvia.com',
  'mail2laugh.com',
  'mail2laura.com',
  'mail2lauren.com',
  'mail2laurie.com',
  'mail2lawrence.com',
  'mail2lawyer.com',
  'mail2lebanon.com',
  'mail2lee.com',
  'mail2leo.com',
  'mail2leon.com',
  'mail2leonard.com',
  'mail2leone.com',
  'mail2leslie.com',
  'mail2letter.com',
  'mail2liberia.com',
  'mail2libertarian.com',
  'mail2libra.com',
  'mail2libya.com',
  'mail2liechtenstein.com',
  'mail2life.com',
  'mail2linda.com',
  'mail2linux.com',
  'mail2lionel.com',
  'mail2lipstick.com',
  'mail2liquid.com',
  'mail2lisa.com',
  'mail2lithuania.com',
  'mail2litigator.com',
  'mail2liz.com',
  'mail2lloyd.com',
  'mail2lois.com',
  'mail2lola.com',
  'mail2london.com',
  'mail2looking.com',
  'mail2lori.com',
  'mail2lost.com',
  'mail2lou.com',
  'mail2louis.com',
  'mail2louisiana.com',
  'mail2lovable.com',
  'mail2love.com',
  'mail2lucky.com',
  'mail2lucy.com',
  'mail2lunch.com',
  'mail2lust.com',
  'mail2luxembourg.com',
  'mail2luxury.com',
  'mail2lyle.com',
  'mail2lynn.com',
  'mail2madagascar.com',
  'mail2madison.com',
  'mail2madrid.com',
  'mail2maggie.com',
  'mail2mail4.com',
  'mail2maine.com',
  'mail2malawi.com',
  'mail2malaysia.com',
  'mail2maldives.com',
  'mail2mali.com',
  'mail2malta.com',
  'mail2mambo.com',
  'mail2man.com',
  'mail2mandy.com',
  'mail2manhunter.com',
  'mail2mankind.com',
  'mail2many.com',
  'mail2marc.com',
  'mail2marcia.com',
  'mail2margaret.com',
  'mail2margie.com',
  'mail2marhaba.com',
  'mail2maria.com',
  'mail2marilyn.com',
  'mail2marines.com',
  'mail2mark.com',
  'mail2marriage.com',
  'mail2married.com',
  'mail2marries.com',
  'mail2mars.com',
  'mail2marsha.com',
  'mail2marshallislands.com',
  'mail2martha.com',
  'mail2martin.com',
  'mail2marty.com',
  'mail2marvin.com',
  'mail2mary.com',
  'mail2maryland.com',
  'mail2mason.com',
  'mail2massachusetts.com',
  'mail2matt.com',
  'mail2matthew.com',
  'mail2maurice.com',
  'mail2mauritania.com',
  'mail2mauritius.com',
  'mail2max.com',
  'mail2maxwell.com',
  'mail2maybe.com',
  'mail2mba.com',
  'mail2me4u.com',
  'mail2mechanic.com',
  'mail2medieval.com',
  'mail2megan.com',
  'mail2mel.com',
  'mail2melanie.com',
  'mail2melissa.com',
  'mail2melody.com',
  'mail2member.com',
  'mail2memphis.com',
  'mail2methodist.com',
  'mail2mexican.com',
  'mail2mexico.com',
  'mail2mgz.com',
  'mail2miami.com',
  'mail2michael.com',
  'mail2michelle.com',
  'mail2michigan.com',
  'mail2mike.com',
  'mail2milan.com',
  'mail2milano.com',
  'mail2mildred.com',
  'mail2milkyway.com',
  'mail2millennium.com',
  'mail2millionaire.com',
  'mail2milton.com',
  'mail2mime.com',
  'mail2mindreader.com',
  'mail2mini.com',
  'mail2minister.com',
  'mail2minneapolis.com',
  'mail2minnesota.com',
  'mail2miracle.com',
  'mail2missionary.com',
  'mail2mississippi.com',
  'mail2missouri.com',
  'mail2mitch.com',
  'mail2model.com',
  'mail2moldova.commail2molly.com',
  'mail2mom.com',
  'mail2monaco.com',
  'mail2money.com',
  'mail2mongolia.com',
  'mail2monica.com',
  'mail2montana.com',
  'mail2monty.com',
  'mail2moon.com',
  'mail2morocco.com',
  'mail2morpheus.com',
  'mail2mors.com',
  'mail2moscow.com',
  'mail2moslem.com',
  'mail2mouseketeer.com',
  'mail2movies.com',
  'mail2mozambique.com',
  'mail2mp3.com',
  'mail2mrright.com',
  'mail2msright.com',
  'mail2museum.com',
  'mail2music.com',
  'mail2musician.com',
  'mail2muslim.com',
  'mail2my.com',
  'mail2myboat.com',
  'mail2mycar.com',
  'mail2mycell.com',
  'mail2mygsm.com',
  'mail2mylaptop.com',
  'mail2mymac.com',
  'mail2mypager.com',
  'mail2mypalm.com',
  'mail2mypc.com',
  'mail2myphone.com',
  'mail2myplane.com',
  'mail2namibia.com',
  'mail2nancy.com',
  'mail2nasdaq.com',
  'mail2nathan.com',
  'mail2nauru.com',
  'mail2navy.com',
  'mail2neal.com',
  'mail2nebraska.com',
  'mail2ned.com',
  'mail2neil.com',
  'mail2nelson.com',
  'mail2nemesis.com',
  'mail2nepal.com',
  'mail2netherlands.com',
  'mail2network.com',
  'mail2nevada.com',
  'mail2newhampshire.com',
  'mail2newjersey.com',
  'mail2newmexico.com',
  'mail2newyork.com',
  'mail2newzealand.com',
  'mail2nicaragua.com',
  'mail2nick.com',
  'mail2nicole.com',
  'mail2niger.com',
  'mail2nigeria.com',
  'mail2nike.com',
  'mail2no.com',
  'mail2noah.com',
  'mail2noel.com',
  'mail2noelle.com',
  'mail2normal.com',
  'mail2norman.com',
  'mail2northamerica.com',
  'mail2northcarolina.com',
  'mail2northdakota.com',
  'mail2northpole.com',
  'mail2norway.com',
  'mail2notus.com',
  'mail2noway.com',
  'mail2nowhere.com',
  'mail2nuclear.com',
  'mail2nun.com',
  'mail2ny.com',
  'mail2oasis.com',
  'mail2oceanographer.com',
  'mail2ohio.com',
  'mail2ok.com',
  'mail2oklahoma.com',
  'mail2oliver.com',
  'mail2oman.com',
  'mail2one.com',
  'mail2onfire.com',
  'mail2online.com',
  'mail2oops.com',
  'mail2open.com',
  'mail2ophthalmologist.com',
  'mail2optometrist.com',
  'mail2oregon.com',
  'mail2oscars.com',
  'mail2oslo.com',
  'mail2painter.com',
  'mail2pakistan.com',
  'mail2palau.com',
  'mail2pan.com',
  'mail2panama.com',
  'mail2paraguay.com',
  'mail2paralegal.com',
  'mail2paris.com',
  'mail2park.com',
  'mail2parker.com',
  'mail2party.com',
  'mail2passion.com',
  'mail2pat.com',
  'mail2patricia.com',
  'mail2patrick.com',
  'mail2patty.com',
  'mail2paul.com',
  'mail2paula.com',
  'mail2pay.com',
  'mail2peace.com',
  'mail2pediatrician.com',
  'mail2peggy.com',
  'mail2pennsylvania.com',
  'mail2perry.com',
  'mail2persephone.com',
  'mail2persian.com',
  'mail2peru.com',
  'mail2pete.com',
  'mail2peter.com',
  'mail2pharmacist.com',
  'mail2phil.com',
  'mail2philippines.com',
  'mail2phoenix.com',
  'mail2phonecall.com',
  'mail2phyllis.com',
  'mail2pickup.com',
  'mail2pilot.com',
  'mail2pisces.com',
  'mail2planet.com',
  'mail2platinum.com',
  'mail2plato.com',
  'mail2pluto.com',
  'mail2pm.com',
  'mail2podiatrist.com',
  'mail2poet.com',
  'mail2poland.com',
  'mail2policeman.com',
  'mail2policewoman.com',
  'mail2politician.com',
  'mail2pop.com',
  'mail2pope.com',
  'mail2popular.com',
  'mail2portugal.com',
  'mail2poseidon.com',
  'mail2potatohead.com',
  'mail2power.com',
  'mail2presbyterian.com',
  'mail2president.com',
  'mail2priest.com',
  'mail2prince.com',
  'mail2princess.com',
  'mail2producer.com',
  'mail2professor.com',
  'mail2protect.com',
  'mail2psychiatrist.com',
  'mail2psycho.com',
  'mail2psychologist.com',
  'mail2qatar.com',
  'mail2queen.com',
  'mail2rabbi.com',
  'mail2race.com',
  'mail2racer.com',
  'mail2rachel.com',
  'mail2rage.com',
  'mail2rainmaker.com',
  'mail2ralph.com',
  'mail2randy.com',
  'mail2rap.com',
  'mail2rare.com',
  'mail2rave.com',
  'mail2ray.com',
  'mail2raymond.com',
  'mail2realtor.com',
  'mail2rebecca.com',
  'mail2recruiter.com',
  'mail2recycle.com',
  'mail2redhead.com',
  'mail2reed.com',
  'mail2reggie.com',
  'mail2register.com',
  'mail2rent.com',
  'mail2republican.com',
  'mail2resort.com',
  'mail2rex.com',
  'mail2rhodeisland.com',
  'mail2rich.com',
  'mail2richard.com',
  'mail2ricky.com',
  'mail2ride.com',
  'mail2riley.com',
  'mail2rita.com',
  'mail2rob.com',
  'mail2robert.com',
  'mail2roberta.com',
  'mail2robin.com',
  'mail2rock.com',
  'mail2rocker.com',
  'mail2rod.com',
  'mail2rodney.com',
  'mail2romania.com',
  'mail2rome.com',
  'mail2ron.com',
  'mail2ronald.com',
  'mail2ronnie.com',
  'mail2rose.com',
  'mail2rosie.com',
  'mail2roy.com',
  'mail2rss.org',
  'mail2rudy.com',
  'mail2rugby.com',
  'mail2runner.com',
  'mail2russell.com',
  'mail2russia.com',
  'mail2russian.com',
  'mail2rusty.com',
  'mail2ruth.com',
  'mail2rwanda.com',
  'mail2ryan.com',
  'mail2sa.com',
  'mail2sabrina.com',
  'mail2safe.com',
  'mail2sagittarius.com',
  'mail2sail.com',
  'mail2sailor.com',
  'mail2sal.com',
  'mail2salaam.com',
  'mail2sam.com',
  'mail2samantha.com',
  'mail2samoa.com',
  'mail2samurai.com',
  'mail2sandra.com',
  'mail2sandy.com',
  'mail2sanfrancisco.com',
  'mail2sanmarino.com',
  'mail2santa.com',
  'mail2sara.com',
  'mail2sarah.com',
  'mail2sat.com',
  'mail2saturn.com',
  'mail2saudi.com',
  'mail2saudiarabia.com',
  'mail2save.com',
  'mail2savings.com',
  'mail2school.com',
  'mail2scientist.com',
  'mail2scorpio.com',
  'mail2scott.com',
  'mail2sean.com',
  'mail2search.com',
  'mail2seattle.com',
  'mail2secretagent.com',
  'mail2senate.com',
  'mail2senegal.com',
  'mail2sensual.com',
  'mail2seth.com',
  'mail2sevenseas.com',
  'mail2sexy.com',
  'mail2seychelles.com',
  'mail2shane.com',
  'mail2sharon.com',
  'mail2shawn.com',
  'mail2ship.com',
  'mail2shirley.com',
  'mail2shoot.com',
  'mail2shuttle.com',
  'mail2sierraleone.com',
  'mail2simon.com',
  'mail2singapore.com',
  'mail2single.com',
  'mail2site.com',
  'mail2skater.com',
  'mail2skier.com',
  'mail2sky.com',
  'mail2sleek.com',
  'mail2slim.com',
  'mail2slovakia.com',
  'mail2slovenia.com',
  'mail2smile.com',
  'mail2smith.com',
  'mail2smooth.com',
  'mail2soccer.com',
  'mail2soccerfan.com',
  'mail2socialist.com',
  'mail2soldier.com',
  'mail2somalia.com',
  'mail2son.com',
  'mail2song.com',
  'mail2sos.com',
  'mail2sound.com',
  'mail2southafrica.com',
  'mail2southamerica.com',
  'mail2southcarolina.com',
  'mail2southdakota.com',
  'mail2southkorea.com',
  'mail2southpole.com',
  'mail2spain.com',
  'mail2spanish.com',
  'mail2spare.com',
  'mail2spectrum.com',
  'mail2splash.com',
  'mail2sponsor.com',
  'mail2sports.com',
  'mail2srilanka.com',
  'mail2stacy.com',
  'mail2stan.com',
  'mail2stanley.com',
  'mail2star.com',
  'mail2state.com',
  'mail2stephanie.com',
  'mail2steve.com',
  'mail2steven.com',
  'mail2stewart.com',
  'mail2stlouis.com',
  'mail2stock.com',
  'mail2stockholm.com',
  'mail2stockmarket.com',
  'mail2storage.com',
  'mail2store.com',
  'mail2strong.com',
  'mail2student.com',
  'mail2studio.com',
  'mail2studio54.com',
  'mail2stuntman.com',
  'mail2subscribe.com',
  'mail2sudan.com',
  'mail2superstar.com',
  'mail2surfer.com',
  'mail2suriname.com',
  'mail2susan.com',
  'mail2suzie.com',
  'mail2swaziland.com',
  'mail2sweden.com',
  'mail2sweetheart.com',
  'mail2swim.com',
  'mail2swimmer.com',
  'mail2swiss.com',
  'mail2switzerland.com',
  'mail2sydney.com',
  'mail2sylvia.com',
  'mail2syria.com',
  'mail2taboo.com',
  'mail2taiwan.com',
  'mail2tajikistan.com',
  'mail2tammy.com',
  'mail2tango.com',
  'mail2tanya.com',
  'mail2tanzania.com',
  'mail2tara.com',
  'mail2taurus.com',
  'mail2taxi.com',
  'mail2taxidermist.com',
  'mail2taylor.com',
  'mail2taz.com',
  'mail2teacher.com',
  'mail2technician.com',
  'mail2ted.com',
  'mail2telephone.com',
  'mail2teletubbie.com',
  'mail2tenderness.com',
  'mail2tennessee.com',
  'mail2tennis.com',
  'mail2tennisfan.com',
  'mail2terri.com',
  'mail2terry.com',
  'mail2test.com',
  'mail2texas.com',
  'mail2thailand.com',
  'mail2therapy.com',
  'mail2think.com',
  'mail2tickets.com',
  'mail2tiffany.com',
  'mail2tim.com',
  'mail2time.com',
  'mail2timothy.com',
  'mail2tina.com',
  'mail2titanic.com',
  'mail2toby.com',
  'mail2todd.com',
  'mail2togo.com',
  'mail2tom.com',
  'mail2tommy.com',
  'mail2tonga.com',
  'mail2tony.com',
  'mail2touch.com',
  'mail2tourist.com',
  'mail2tracey.com',
  'mail2tracy.com',
  'mail2tramp.com',
  'mail2travel.com',
  'mail2traveler.com',
  'mail2travis.com',
  'mail2trekkie.com',
  'mail2trex.com',
  'mail2triallawyer.com',
  'mail2trick.com',
  'mail2trillionaire.com',
  'mail2troy.com',
  'mail2truck.com',
  'mail2trump.com',
  'mail2try.com',
  'mail2tunisia.com',
  'mail2turbo.com',
  'mail2turkey.com',
  'mail2turkmenistan.com',
  'mail2tv.com',
  'mail2tycoon.com',
  'mail2tyler.com',
  'mail2u4me.com',
  'mail2uae.com',
  'mail2uganda.com',
  'mail2uk.com',
  'mail2ukraine.com',
  'mail2uncle.com',
  'mail2unsubscribe.com',
  'mail2uptown.com',
  'mail2uruguay.com',
  'mail2usa.com',
  'mail2utah.com',
  'mail2uzbekistan.com',
  'mail2v.com',
  'mail2vacation.com',
  'mail2valentines.com',
  'mail2valerie.com',
  'mail2valley.com',
  'mail2vamoose.com',
  'mail2vanessa.com',
  'mail2vanuatu.com',
  'mail2venezuela.com',
  'mail2venous.com',
  'mail2venus.com',
  'mail2vermont.com',
  'mail2vickie.com',
  'mail2victor.com',
  'mail2victoria.com',
  'mail2vienna.com',
  'mail2vietnam.com',
  'mail2vince.com',
  'mail2virginia.com',
  'mail2virgo.com',
  'mail2visionary.com',
  'mail2vodka.com',
  'mail2volleyball.com',
  'mail2waiter.com',
  'mail2wallstreet.com',
  'mail2wally.com',
  'mail2walter.com',
  'mail2warren.com',
  'mail2washington.com',
  'mail2wave.com',
  'mail2way.com',
  'mail2waycool.com',
  'mail2wayne.com',
  'mail2webmaster.com',
  'mail2webtop.com',
  'mail2webtv.com',
  'mail2weird.com',
  'mail2wendell.com',
  'mail2wendy.com',
  'mail2westend.com',
  'mail2westvirginia.com',
  'mail2whether.com',
  'mail2whip.com',
  'mail2white.com',
  'mail2whitehouse.com',
  'mail2whitney.com',
  'mail2why.com',
  'mail2wilbur.com',
  'mail2wild.com',
  'mail2willard.com',
  'mail2willie.com',
  'mail2wine.com',
  'mail2winner.com',
  'mail2wired.com',
  'mail2wisconsin.com',
  'mail2woman.com',
  'mail2wonder.com',
  'mail2world.com',
  'mail2worship.com',
  'mail2wow.com',
  'mail2www.com',
  'mail2wyoming.com',
  'mail2xfiles.com',
  'mail2xox.com',
  'mail2yachtclub.com',
  'mail2yahalla.com',
  'mail2yemen.com',
  'mail2yes.com',
  'mail2yugoslavia.com',
  'mail2zack.com',
  'mail2zambia.com',
  'mail2zenith.com',
  'mail2zephir.com',
  'mail2zeus.com',
  'mail2zipper.com',
  'mail2zoo.com',
  'mail2zoologist.com',
  'mail2zurich.com',
  'mail3000.com',
  'mail333.com',
  'mail4trash.com',
  'mail4u.info',
  'mailandftp.com',
  'mailandnews.com',
  'mailas.com',
  'mailasia.com',
  'mailbolt.com',
  'mailbomb.net',
  'mailboom.com',
  'mailbox.as',
  'mailbox.co.za',
  'mailbox.gr',
  'mailbox.hu',
  'mailbox72.biz',
  'mailbox80.biz',
  'mailbr.com.br',
  'mailc.net',
  'mailcan.com',
  'mailcat.biz',
  'mailcc.com',
  'mailchoose.co',
  'mailcity.com',
  'mailclub.fr',
  'mailclub.net',
  'maildrop.cc',
  'maildrop.gq',
  'maildx.com',
  'mailed.ro',
  'mailexcite.com',
  'mailfa.tk',
  'mailfence.com',
  'mailforce.net',
  'mailforspam.com',
  'mailfree.gq',
  'mailfs.com',
  'mailftp.com',
  'mailgenie.net',
  'mailguard.me',
  'mailhaven.com',
  'mailhood.com',
  'mailimate.com',
  'mailinator.com',
  'mailinator.org',
  'mailinator.us',
  'mailinblack.com',
  'mailingaddress.org',
  'mailingweb.com',
  'mailisent.com',
  'mailismagic.com',
  'mailite.com',
  'mailmate.com',
  'mailme.dk',
  'mailme.gq',
  'mailme24.com',
  'mailmight.com',
  'mailmij.nl',
  'mailnator.com',
  'mailnew.com',
  'mailops.com',
  'mailoye.com',
  'mailpanda.com',
  'mailpick.biz',
  'mailpokemon.com',
  'mailpost.zzn.com',
  'mailpride.com',
  'mailproxsy.com',
  'mailpuppy.com',
  'mailquack.com',
  'mailrock.biz',
  'mailroom.com',
  'mailru.com',
  'mailsac.com',
  'mailseal.de',
  'mailsent.net',
  'mailservice.ms',
  'mailshuttle.com',
  'mailslapping.com',
  'mailstart.com',
  'mailstartplus.com',
  'mailsurf.com',
  'mailtag.com',
  'mailtemp.info',
  'mailto.de',
  'mailtothis.com',
  'mailueberfall.de',
  'mailup.net',
  'mailwire.com',
  'mailworks.org',
  'mailzi.ru',
  'mailzilla.org',
  'maktoob.com',
  'malayalamtelevision.net',
  'maltesemail.com',
  'mamber.net',
  'manager.de',
  'mancity.net',
  'mantrafreenet.com',
  'mantramail.com',
  'mantraonline.com',
  'manybrain.com',
  'marchmail.com',
  'mariahc.com',
  'marijuana.com',
  'marijuana.nl',
  'married-not.com',
  'marsattack.com',
  'martindalemail.com',
  'mash4077.com',
  'masrawy.com',
  'matmail.com',
  'mauimail.com',
  'mauritius.com',
  'maxleft.com',
  'maxmail.co.uk',
  'mbox.com.au',
  'mchsi.com',
  'me-mail.hu',
  'me.com',
  'medical.net.au',
  'medscape.com',
  'meetingmall.com',
  'megago.com',
  'megamail.pt',
  'megapoint.com',
  'mehrani.com',
  'mehtaweb.com',
  'meine-dateien.info',
  'meine-diashow.de',
  'meine-fotos.info',
  'meine-urlaubsfotos.de',
  'mekhong.com',
  'melodymail.com',
  'meloo.com',
  'merda.flu.cc',
  'merda.igg.biz',
  'merda.nut.cc',
  'merda.usa.cc',
  'message.hu',
  'message.myspace.com',
  'messages.to',
  'metacrawler.com',
  'metalfan.com',
  'metaping.com',
  'metta.lk',
  'mexicomail.com',
  'mezimages.net',
  'mfsa.ru',
  'mierdamail.com',
  'miesto.sk',
  'mighty.co.za',
  'migmail.net',
  'migmail.pl',
  'migumail.com',
  'miho-nakayama.com',
  'mikrotamanet.com',
  'millionaireintraining.com',
  'millionairemail.com',
  'milmail.com',
  'mindless.com',
  'mindspring.com',
  'minister.com',
  'misery.net',
  'mittalweb.com',
  'mixmail.com',
  'mjfrogmail.com',
  'ml1.net',
  'mlb.bounce.ed10.net',
  'mm.st',
  'mns.ru',
  'moakt.com',
  'mobilbatam.com',
  'mobileninja.co.uk',
  'mochamail.com',
  'mohammed.com',
  'mohmal.com',
  'moldova.cc',
  'moldova.com',
  'moldovacc.com',
  'momslife.com',
  'monemail.com',
  'money.net',
  'montevideo.com.uy',
  'monumentmail.com',
  'moonman.com',
  'moose-mail.com',
  'mor19.uu.gl',
  'mortaza.com',
  'mosaicfx.com',
  'moscowmail.com',
  'most-wanted.com',
  'mostlysunny.com',
  'motormania.com',
  'movemail.com',
  'movieluver.com',
  'mox.pp.ua',
  'mp4.it',
  'mr-potatohead.com',
  'mscold.com',
  'msgbox.com',
  'msn.cn',
  'msn.com',
  'msn.nl',
  'mt2015.com',
  'mt2016.com',
  'mttestdriver.com',
  'muehlacker.tk',
  'muell.icu',
  'muellmail.com',
  'muellemail.com',
  'mundomail.net',
  'munich.com',
  'music.com',
  'musician.org',
  'musicscene.org',
  'muskelshirt.de',
  'muslim.com',
  'muslimsonline.com',
  'mutantweb.com',
  'mvrht.com',
  'my.com',
  'my10minutemail.com',
  'mybox.it',
  'mycabin.com',
  'mycity.com',
  'mycool.com',
  'mydomain.com',
  'mydotcomaddress.com',
  'myfamily.com',
  'myfastmail.com',
  'mygo.com',
  'myiris.com',
  'mymacmail.com',
  'mynamedot.com',
  'mynet.com',
  'mynetaddress.com',
  'mynetstore.de',
  'myownemail.com',
  'myownfriends.com',
  'mypacks.net',
  'mypad.com',
  'mypersonalemail.com',
  'myplace.com',
  'myrambler.ru',
  'myrealbox.com',
  'myremarq.com',
  'myself.com',
  'myspaceinc.net',
  'myspamless.com',
  'mystupidjob.com',
  'mytemp.email',
  'mythirdage.com',
  'myway.com',
  'myworldmail.com',
  'n2.com',
  'n2baseball.com',
  'n2business.com',
  'n2mail.com',
  'n2soccer.com',
  'n2software.com',
  'nabc.biz',
  'nafe.com',
  'nagpal.net',
  'nakedgreens.com',
  'name.com',
  'nameplanet.com',
  'nandomail.com',
  'naplesnews.net',
  'naseej.com',
  'nativestar.net',
  'nativeweb.net',
  'naui.net',
  'naver.com',
  'navigator.lv',
  'navy.org',
  'naz.com',
  'nc.rr.com',
  'nchoicemail.com',
  'neeva.net',
  'nemra1.com',
  'nenter.com',
  'neo.rr.com',
  'nervhq.org',
  'net-c.be',
  'net-c.ca',
  'net-c.cat',
  'net-c.com',
  'net-c.es',
  'net-c.fr',
  'net-c.it',
  'net-c.lu',
  'net-c.nl',
  'net-c.pl',
  'net-pager.net',
  'net-shopping.com',
  'net4b.pt',
  'net4you.at',
  'netbounce.com',
  'netbroadcaster.com',
  'netby.dk',
  'netc.eu',
  'netc.fr',
  'netc.it',
  'netc.lu',
  'netc.pl',
  'netcenter-vn.net',
  'netcmail.com',
  'netcourrier.com',
  'netexecutive.com',
  'netexpressway.com',
  'netgenie.com',
  'netian.com',
  'netizen.com.ar',
  'netlane.com',
  'netlimit.com',
  'netmongol.com',
  'netnet.com.sg',
  'netnoir.net',
  'netpiper.com',
  'netposta.net',
  'netralink.com',
  'netscape.net',
  'netscapeonline.co.uk',
  'netspace.net.au',
  'netspeedway.com',
  'netsquare.com',
  'netster.com',
  'nettaxi.com',
  'nettemail.com',
  'netterchef.de',
  'netti.fi',
  'netzero.com',
  'netzero.net',
  'netzidiot.de',
  'neue-dateien.de',
  'neuro.md',
  'newmail.com',
  'newmail.net',
  'newmail.ru',
  'newsboysmail.com',
  'newyork.com',
  'nextmail.ru',
  'nexxmail.com',
  'nfmail.com',
  'nicebush.com',
  'nicegal.com',
  'nicholastse.net',
  'nicolastse.com',
  'nightmail.com',
  'nikopage.com',
  'nimail.com',
  'ninfan.com',
  'nirvanafan.com',
  'nmail.cf',
  'noavar.com',
  'nonpartisan.com',
  'nonspam.eu',
  'nonspammer.de',
  'norika-fujiwara.com',
  'norikomail.com',
  'northgates.net',
  'nospammail.net',
  'nospamthanks.info',
  'nowhere.org',
  'ntelos.net',
  'ntlhelp.net',
  'ntlworld.com',
  'ntscan.com',
  'null.net',
  'nullbox.info',
  'nur-fuer-spam.de',
  'nus.edu.sg',
  'nwldx.com',
  'nwytg.net',
  'nxt.ru',
  'ny.com',
  'nybella.com',
  'nyc.com',
  'nycmail.com',
  'nzoomail.com',
  'o-tay.com',
  'o2.co.uk',
  'oaklandas-fan.com',
  'oath.com',
  'oceanfree.net',
  'odaymail.com',
  'oddpost.com',
  'odmail.com',
  'office-dateien.de',
  'office-email.com',
  'offroadwarrior.com',
  'oicexchange.com',
  'oida.icu',
  'oikrach.com',
  'okbank.com',
  'okhuman.com',
  'okmad.com',
  'okmagic.com',
  'okname.net',
  'okuk.com',
  'oldies104mail.com',
  'ole.com',
  'olemail.com',
  'olympist.net',
  'olypmall.ru',
  'omaninfo.com',
  'omen.ru',
  'onebox.com',
  'onenet.com.ar',
  'oneoffmail.com',
  'onet.com.pl',
  'onet.eu',
  'onet.pl',
  'oninet.pt',
  'online.ie',
  'online.ms',
  'online.nl',
  'onlinewiz.com',
  'onmilwaukee.com',
  'onobox.com',
  'op.pl',
  'opayq.com',
  'openmailbox.org',
  'operafan.com',
  'operamail.com',
  'opoczta.pl',
  'optician.com',
  'optonline.net',
  'optusnet.com.au',
  'orange.fr',
  'orbitel.bg',
  'orgmail.net',
  'orthodontist.net',
  'osite.com.br',
  'oso.com',
  'otakumail.com',
  'our-computer.com',
  'our-office.com',
  'our.st',
  'ourbrisbane.com',
  'ourklips.com',
  'ournet.md',
  'outgun.com',
  'outlawspam.com',
  'outlook.at',
  'outlook.be',
  'outlook.cl',
  'outlook.co.id',
  'outlook.co.il',
  'outlook.co.nz',
  'outlook.co.th',
  'outlook.com',
  'outlook.com.au',
  'outlook.com.br',
  'outlook.com.gr',
  'outlook.com.pe',
  'outlook.com.tr',
  'outlook.com.vn',
  'outlook.cz',
  'outlook.de',
  'outlook.dk',
  'outlook.es',
  'outlook.fr',
  'outlook.hu',
  'outlook.ie',
  'outlook.in',
  'outlook.it',
  'outlook.jp',
  'outlook.kr',
  'outlook.lv',
  'outlook.my',
  'outlook.nl',
  'outlook.ph',
  'outlook.pt',
  'outlook.sa',
  'outlook.sg',
  'outlook.sk',
  'over-the-rainbow.com',
  'ownmail.net',
  'ozbytes.net.au',
  'ozemail.com.au',
  'pacbell.net',
  'pacific-ocean.com',
  'pacific-re.com',
  'pacificwest.com',
  'packersfan.com',
  'pagina.de',
  'pagons.org',
  'pakistanmail.com',
  'pakistanoye.com',
  'palestinemail.com',
  'pandora.be',
  'papierkorb.me',
  'parkjiyoon.com',
  'parsmail.com',
  'partlycloudy.com',
  'partybombe.de',
  'partyheld.de',
  'partynight.at',
  'parvazi.com',
  'passwordmail.com',
  'pathfindermail.com',
  'pconnections.net',
  'pcpostal.com',
  'pcsrock.com',
  'pcusers.otherinbox.com',
  'pediatrician.com',
  'penpen.com',
  'peoplepc.com',
  'peopleweb.com',
  'pepbot.com',
  'perfectmail.com',
  'perso.be',
  'personal.ro',
  'personales.com',
  'petlover.com',
  'petml.com',
  'pettypool.com',
  'pezeshkpour.com',
  'pfui.ru',
  'phayze.com',
  'phone.net',
  'photo-impact.eu',
  'photographer.net',
  'phpbb.uu.gl',
  'phreaker.net',
  'phus8kajuspa.cu.cc',
  'physicist.net',
  'pianomail.com',
  'pickupman.com',
  'picusnet.com',
  'pigpig.net',
  'pinoymail.com',
  'piracha.net',
  'pisem.net',
  'pjjkp.com',
  'planet.nl',
  'planetaccess.com',
  'planetarymotion.net',
  'planetearthinter.net',
  'planetmail.com',
  'planetmail.net',
  'planetout.com',
  'plasa.com',
  'playersodds.com',
  'playful.com',
  'playstation.sony.com',
  'plus.com',
  'plus.google.com',
  'plusmail.com.br',
  'pmail.net',
  'pobox.hu',
  'pobox.sk',
  'pochta.ru',
  'poczta.fm',
  'poczta.onet.pl',
  'poetic.com',
  'pokemail.net',
  'pokemonpost.com',
  'pokepost.com',
  'polandmail.com',
  'polbox.com',
  'policeoffice.com',
  'politician.com',
  'polizisten-duzer.de',
  'polyfaust.com',
  'pool-sharks.com',
  'poond.com',
  'popaccount.com',
  'popmail.com',
  'popsmail.com',
  'popstar.com',
  'portugalmail.com',
  'portugalmail.pt',
  'portugalnet.com',
  'positive-thinking.com',
  'post.com',
  'post.cz',
  'post.sk',
  'posta.ro',
  'postaccesslite.com',
  'postafree.com',
  'postaweb.com',
  'posteo.at',
  'posteo.be',
  'posteo.ch',
  'posteo.cl',
  'posteo.co',
  'posteo.de',
  'posteo.dk',
  'posteo.es',
  'posteo.gl',
  'posteo.net',
  'posteo.no',
  'posteo.us',
  'postfach.cc',
  'postinbox.com',
  'postino.ch',
  'postmark.net',
  'postmaster.co.uk',
  'postmaster.twitter.com',
  'postpro.net',
  'pousa.com',
  'powerfan.com',
  'pp.inet.fi',
  'praize.com',
  'premium-mail.fr',
  'premiumservice.com',
  'presidency.com',
  'press.co.jp',
  'priest.com',
  'primposta.com',
  'primposta.hu',
  'privy-mail.com',
  'privymail.de',
  'pro.hu',
  'probemail.com',
  'prodigy.net',
  'progetplus.it',
  'programist.ru',
  'programmer.net',
  'programozo.hu',
  'proinbox.com',
  'project2k.com',
  'promessage.com',
  'prontomail.com',
  'protestant.com',
  'protonmail.com',
  'prydirect.info',
  'psv-supporter.com',
  'ptd.net',
  'public-files.de',
  'public.usa.com',
  'publicist.com',
  'pulp-fiction.com',
  'punkass.com',
  'purpleturtle.com',
  'put2.net',
  'pwrby.com',
  'q.com',
  'qatarmail.com',
  'qmail.com',
  'qprfans.com',
  'qq.com',
  'qrio.com',
  'quackquack.com',
  'quakemail.com',
  'qualityservice.com',
  'quantentunnel.de',
  'qudsmail.com',
  'quepasa.com',
  'quickhosts.com',
  'quickmail.nl',
  'quicknet.nl',
  'quickwebmail.com',
  'quiklinks.com',
  'quikmail.com',
  'qv7.info',
  'qwest.net',
  'qwestoffice.net',
  'r-o-o-t.com',
  'raakim.com',
  'racedriver.com',
  'racefanz.com',
  'racingfan.com.au',
  'racingmail.com',
  'radicalz.com',
  'radiku.ye.vc',
  'radiologist.net',
  'ragingbull.com',
  'ralib.com',
  'rambler.ru',
  'ranmamail.com',
  'rastogi.net',
  'ratt-n-roll.com',
  'rattle-snake.com',
  'raubtierbaendiger.de',
  'ravearena.com',
  'ravemail.com',
  'razormail.com',
  'rccgmail.org',
  'rcn.com',
  'realemail.net',
  'reality-concept.club',
  'reallyfast.biz',
  'reallyfast.info',
  'reallymymail.com',
  'realradiomail.com',
  'realtyagent.com',
  'reborn.com',
  'reconmail.com',
  'recycler.com',
  'recyclermail.com',
  'rediff.com',
  'rediffmail.com',
  'rediffmailpro.com',
  'rednecks.com',
  'redseven.de',
  'redsfans.com',
  'regbypass.com',
  'reggaefan.com',
  'registerednurses.com',
  'regspaces.tk',
  'reincarnate.com',
  'religious.com',
  'remail.ga',
  'renren.com',
  'repairman.com',
  'reply.hu',
  'reply.ticketmaster.com',
  'representative.com',
  'rescueteam.com',
  'resgedvgfed.tk',
  'resource.calendar.google.com',
  'resumemail.com',
  'rezai.com',
  'rhyta.com',
  'richmondhill.com',
  'rickymail.com',
  'rin.ru',
  'riopreto.com.br',
  'rklips.com',
  'rn.com',
  'ro.ru',
  'roadrunner.com',
  'roanokemail.com',
  'rock.com',
  'rocketmail.com',
  'rocketship.com',
  'rockfan.com',
  'rodrun.com',
  'rogers.com',
  'rome.com',
  'roosh.com',
  'rootprompt.org',
  'roughnet.com',
  'royal.net',
  'rr.com',
  'rrohio.com',
  'rsub.com',
  'rubyridge.com',
  'runbox.com',
  'rushpost.com',
  'ruttolibero.com',
  'rvshop.com',
  's-mail.com',
  'sabreshockey.com',
  'sacbeemail.com',
  'saeuferleber.de',
  'safe-mail.net',
  'safrica.com',
  'sagra.lu',
  'sags-per-mail.de',
  'sailormoon.com',
  'saintly.com',
  'saintmail.net',
  'sale-sale-sale.com',
  'salehi.net',
  'salesperson.net',
  'samerica.com',
  'samilan.net',
  'sammimail.com',
  'sandelf.de',
  'sanfranmail.com',
  'sanook.com',
  'sapo.pt',
  'saudia.com',
  'savelife.ml',
  'sayhi.net',
  'saynotospams.com',
  'sbcglbal.net',
  'sbcglobal.com',
  'sbcglobal.net',
  'scandalmail.com',
  'scarlet.nl',
  'schafmail.de',
  'schizo.com',
  'schmusemail.de',
  'schoolemail.com',
  'schoolmail.com',
  'schoolsucks.com',
  'schreib-doch-mal-wieder.de',
  'schweiz.org',
  'sci.fi',
  'scientist.com',
  'scifianime.com',
  'scotland.com',
  'scotlandmail.com',
  'scottishmail.co.uk',
  'scottsboro.org',
  'scubadiving.com',
  'seanet.com',
  'search.ua',
  'searchwales.com',
  'sebil.com',
  'seckinmail.com',
  'secret-police.com',
  'secretary.net',
  'secretservices.net',
  'secure-mail.biz',
  'secure-mail.cc',
  'seductive.com',
  'seekstoyboy.com',
  'seguros.com.br',
  'selfdestructingmail.com',
  'send.hu',
  'sendme.cz',
  'sendspamhere.com',
  'sent.as',
  'sent.at',
  'sent.com',
  'sentrismail.com',
  'serga.com.ar',
  'servemymail.com',
  'servermaps.net',
  'sesmail.com',
  'sexmagnet.com',
  'seznam.cz',
  'shahweb.net',
  'shaniastuff.com',
  'shared-files.de',
  'sharedmailbox.org',
  'sharmaweb.com',
  'shaw.ca',
  'she.com',
  'shieldedmail.com',
  'shinedyoureyes.com',
  'shitaway.cf',
  'shitaway.cu.cc',
  'shitaway.ga',
  'shitaway.gq',
  'shitaway.ml',
  'shitaway.tk',
  'shitaway.usa.cc',
  'shitmail.de',
  'shitmail.org',
  'shitware.nl',
  'shockinmytown.cu.cc',
  'shootmail.com',
  'shortmail.com',
  'shotgun.hu',
  'showslow.de',
  'shuf.com',
  'sialkotcity.com',
  'sialkotian.com',
  'sialkotoye.com',
  'sify.com',
  'silkroad.net',
  'sina.cn',
  'sina.com',
  'sinamail.com',
  'singapore.com',
  'singles4jesus.com',
  'singmail.com',
  'singnet.com.sg',
  'sinnlos-mail.de',
  'siteposter.net',
  'skafan.com',
  'skeefmail.com',
  'skim.com',
  'skizo.hu',
  'skrx.tk',
  'sky.com',
  'skynet.be',
  'slamdunkfan.com',
  'slave-auctions.net',
  'slingshot.com',
  'slippery.email',
  'slipry.net',
  'slo.net',
  'slotter.com',
  'smap.4nmv.ru',
  'smapxsmap.net',
  'smashmail.de',
  'smellrear.com',
  'smileyface.comsmithemail.net',
  'smoothmail.com',
  'sms.at',
  'snail-mail.net',
  'snakebite.com',
  'snakemail.com',
  'sndt.net',
  'sneakemail.com',
  'snet.net',
  'sniper.hu',
  'snkmail.com',
  'snoopymail.com',
  'snowboarding.com',
  'snowdonia.net',
  'socamail.com',
  'socceramerica.net',
  'soccermail.com',
  'soccermomz.com',
  'social-mailer.tk',
  'socialworker.net',
  'sociologist.com',
  'sofort-mail.de',
  'sofortmail.de',
  'softhome.net',
  'sogou.com',
  'sohu.com',
  'sol.dk',
  'solar-impact.pro',
  'solcon.nl',
  'soldier.hu',
  'solution4u.com',
  'solvemail.info',
  'songwriter.net',
  'sonnenkinder.org',
  'soodomail.com',
  'soon.com',
  'soulfoodcookbook.com',
  'sp.nl',
  'space-bank.com',
  'space-man.com',
  'space-ship.com',
  'space-travel.com',
  'space.com',
  'spacemart.com',
  'spacetowns.com',
  'spacewar.com',
  'spainmail.com',
  'spam.2012-2016.ru',
  'spamavert.com',
  'spambob.com',
  'spambob.org',
  'spambog.net',
  'spambooger.com',
  'spam.care',
  'spamcero.com',
  'spamdecoy.net',
  'spameater.com',
  'spameater.org',
  'spamex.com',
  'spamfree24.info',
  'spamfree24.net',
  'spamgoes.in',
  'spaminator.de',
  'spamkill.info',
  'spaml.com',
  'spamoff.de',
  'spamstack.net',
  'spartapiet.com',
  'spazmail.com',
  'speedemail.net',
  'speedpost.net',
  'speedrules.com',
  'speedrulz.com',
  'speedymail.org',
  'sperke.net',
  'spils.com',
  'spinfinder.com',
  'spl.at',
  'spoko.pl',
  'spoofmail.de',
  'sportemail.com',
  'sportsmail.com',
  'sporttruckdriver.com',
  'spray.no',
  'spray.se',
  'spybox.de',
  'spymac.com',
  'sraka.xyz',
  'srilankan.net',
  'ssl-mail.com',
  'st-davids.net',
  'stade.fr',
  'stalag13.com',
  'stargateradio.com',
  'starmail.com',
  'starmail.org',
  'starmedia.com',
  'starplace.com',
  'starspath.com',
  'start.com.au',
  'startkeys.com',
  'stinkefinger.net',
  'stipte.nl',
  'stoned.com',
  'stones.com',
  'stop-my-spam.pp.ua',
  'stopdropandroll.com',
  'storksite.com',
  'streber24.de',
  'streetwisemail.com',
  'stribmail.com',
  'strompost.com',
  'strongguy.com',
  'student.su',
  'studentcenter.org',
  'stuffmail.de',
  'subram.com',
  'sudanmail.net',
  'sudolife.me',
  'sudolife.net',
  'sudomail.biz',
  'sudomail.com',
  'sudomail.net',
  'sudoverse.com',
  'sudoverse.net',
  'sudoweb.net',
  'sudoworld.com',
  'sudoworld.net',
  'suhabi.com',
  'suisse.org',
  'sukhumvit.net',
  'sunpoint.net',
  'sunrise-sunset.com',
  'sunsgame.com',
  'sunumail.sn',
  'suomi24.fi',
  'superdada.com',
  'supereva.it',
  'supermail.ru',
  'superrito.com',
  'superstachel.de',
  'surat.com',
  'surf3.net',
  'surfree.com',
  'surfy.net',
  'surgical.net',
  'surimail.com',
  'survivormail.com',
  'susi.ml',
  'svk.jp',
  'swbell.net',
  'sweb.cz',
  'swedenmail.com',
  'sweetville.net',
  'sweetxxx.de',
  'swift-mail.com',
  'swiftdesk.com',
  'swingeasyhithard.com',
  'swingfan.com',
  'swipermail.zzn.com',
  'swirve.com',
  'swissinfo.org',
  'swissmail.com',
  'swissmail.net',
  'switchboardmail.com',
  'switzerland.org',
  'sx172.com',
  'syom.com',
  'syriamail.com',
  't-online.de',
  't.psh.me',
  't2mail.com',
  'tafmail.com',
  'takuyakimura.com',
  'talk21.com',
  'talkcity.com',
  'talkinator.com',
  'tamil.com',
  'tampabay.rr.com',
  'tankpolice.com',
  'tatanova.com',
  'tbwt.com',
  'tcc.on.ca',
  'tds.net',
  'teachermail.net',
  'teachers.org',
  'teamdiscovery.com',
  'teamtulsa.net',
  'tech-center.com',
  'tech4peace.org',
  'techemail.com',
  'techie.com',
  'technisamail.co.za',
  'technologist.com',
  'techscout.com',
  'techspot.com',
  'teenagedirtbag.com',
  'tele2.nl',
  'telebot.com',
  'telefonica.net',
  'teleline.es',
  'telenet.be',
  'telepac.pt',
  'telerymd.com',
  'teleworm.us',
  'telfort.nl',
  'telfortglasvezel.nl',
  'telinco.net',
  'telkom.net',
  'telpage.net',
  'telstra.com',
  'telstra.com.au',
  'temp-mail.com',
  'temp-mail.de',
  'temp.headstrong.de',
  'tempail.com',
  'tempemail.biz',
  'tempmail.us',
  'tempmail2.com',
  'tempmaildemo.com',
  'tempmailer.com',
  'temporarioemail.com.br',
  'temporaryemail.us',
  'tempthe.net',
  'tempymail.com',
  'temtulsa.net',
  'tenchiclub.com',
  'tenderkiss.com',
  'tennismail.com',
  'terminverpennt.de',
  'terra.cl',
  'terra.com',
  'terra.com.ar',
  'terra.com.br',
  'terra.es',
  'test.com',
  'test.de',
  'tfanus.com.er',
  'tfz.net',
  'thai.com',
  'thaimail.com',
  'thaimail.net',
  'thanksnospam.info',
  'the-african.com',
  'the-airforce.com',
  'the-aliens.com',
  'the-american.com',
  'the-animal.com',
  'the-army.com',
  'the-astronaut.com',
  'the-beauty.com',
  'the-big-apple.com',
  'the-biker.com',
  'the-boss.com',
  'the-brazilian.com',
  'the-canadian.com',
  'the-canuck.com',
  'the-captain.com',
  'the-chinese.com',
  'the-country.com',
  'the-cowboy.com',
  'the-davis-home.com',
  'the-dutchman.com',
  'the-eagles.com',
  'the-englishman.com',
  'the-fastest.net',
  'the-fool.com',
  'the-frenchman.com',
  'the-galaxy.net',
  'the-genius.com',
  'the-gentleman.com',
  'the-german.com',
  'the-gremlin.com',
  'the-hooligan.com',
  'the-italian.com',
  'the-japanese.com',
  'the-lair.com',
  'the-madman.com',
  'the-mailinglist.com',
  'the-marine.com',
  'the-master.com',
  'the-mexican.com',
  'the-ministry.com',
  'the-monkey.com',
  'the-newsletter.net',
  'the-pentagon.com',
  'the-police.com',
  'the-prayer.com',
  'the-professional.com',
  'the-quickest.com',
  'the-russian.com',
  'the-snake.com',
  'the-spaceman.com',
  'the-stock-market.com',
  'the-student.net',
  'the-whitehouse.net',
  'the-wild-west.com',
  'the18th.com',
  'thecoolguy.com',
  'thecriminals.com',
  'thedoghousemail.com',
  'thedorm.com',
  'theend.hu',
  'theglobe.com',
  'thegolfcourse.com',
  'thegooner.com',
  'theheadoffice.com',
  'theinternetemail.com',
  'thelanddownunder.com',
  'themail.com',
  'themillionare.net',
  'theoffice.net',
  'theplate.com',
  'thepokerface.com',
  'thepostmaster.net',
  'theraces.com',
  'theracetrack.com',
  'therapist.net',
  'thestreetfighter.com',
  'theteebox.com',
  'thewatercooler.com',
  'thewebpros.co.uk',
  'thewizzard.com',
  'thewizzkid.com',
  'thezhangs.net',
  'thirdage.com',
  'thisgirl.com',
  'thraml.com',
  'throwam.com',
  'thundermail.com',
  'tidni.com',
  'timein.net',
  'tiscali.at',
  'tiscali.be',
  'tiscali.co.uk',
  'tiscali.it',
  'tiscali.lu',
  'tiscali.se',
  'tkcity.com',
  'tmail.ws',
  'toast.com',
  'toke.com',
  'tom.com',
  'toolsource.com',
  'toomail.biz',
  'toothfairy.com',
  'topchat.com',
  'topgamers.co.uk',
  'topletter.com',
  'topmail-files.de',
  'topmail.com.ar',
  'topsurf.com',
  'torchmail.com',
  'torontomail.com',
  'tortenboxer.de',
  'totalmail.de',
  'totalmusic.net',
  'townisp.com',
  'tpg.com.au',
  'trash-amil.com',
  'trash-mail.ga',
  'trash-mail.ml',
  'trash2010.com',
  'trash2011.com',
  'trashdevil.de',
  'trashymail.net',
  'travel.li',
  'trayna.com',
  'trialbytrivia.com',
  'trickmail.net',
  'trimix.cn',
  'tritium.net',
  'trmailbox.com',
  'tropicalstorm.com',
  'truckerz.com',
  'truckracer.com',
  'truckracers.com',
  'trust-me.com',
  'truthmail.com',
  'tsamail.co.za',
  'ttml.co.in',
  'tunisiamail.com',
  'turboprinz.de',
  'turboprinzessin.de',
  'turkey.com',
  'turual.com',
  'tut.by',
  'tvstar.com',
  'twc.com',
  'twcny.com',
  'twinstarsmail.com',
  'tx.rr.com',
  'tycoonmail.com',
  'typemail.com',
  'u14269.ml',
  'u2club.com',
  'ua.fm',
  'uae.ac',
  'uaemail.com',
  'ubbi.com',
  'ubbi.com.br',
  'uboot.com',
  'uk2.net',
  'uk2k.com',
  'uk2net.com',
  'uk7.net',
  'uk8.net',
  'ukbuilder.com',
  'ukcool.com',
  'ukdreamcast.com',
  'ukmail.org',
  'ukmax.com',
  'ukr.net',
  'uku.co.uk',
  'ultra.fyi',
  'ultapulta.com',
  'ultrapostman.com',
  'ummah.org',
  'umpire.com',
  'unbounded.com',
  'unforgettable.com',
  'uni.de',
  'unican.es',
  'unihome.com',
  'unitybox.de',
  'universal.pt',
  'uno.ee',
  'uno.it',
  'unofree.it',
  'unterderbruecke.de',
  'uol.com.ar',
  'uol.com.br',
  'uol.com.co',
  'uol.com.mx',
  'uol.com.ve',
  'uole.com',
  'uole.com.ve',
  'uolmail.com',
  'uomail.com',
  'upc.nl',
  'upcmail.nl',
  'upf.org',
  'uplipht.com',
  'ureach.com',
  'urgentmail.biz',
  'urhen.com',
  'uroid.com',
  'usa.com',
  'usa.net',
  'usaaccess.net',
  'usanetmail.com',
  'used-product.fr',
  'usermail.com',
  'username.e4ward.com',
  'usma.net',
  'usmc.net',
  'uswestmail.net',
  'uymail.com',
  'uyuyuy.com',
  'v-sexi.com',
  'vaasfc4.tk',
  'vahoo.com',
  'valemail.net',
  'vampirehunter.com',
  'varbizmail.com',
  'vcmail.com',
  'velnet.co.uk',
  'velocall.com',
  'verizon.net',
  'verizonmail.com',
  'verlass-mich-nicht.de',
  'versatel.nl',
  'veryfast.biz',
  'veryrealemail.com',
  'veryspeedy.net',
  'vfemail.net',
  'vickaentb.tk',
  'videotron.ca',
  'viditag.com',
  'viewcastmedia.com',
  'viewcastmedia.net',
  'vinbazar.com',
  'violinmakers.co.uk',
  'vip.126.com',
  'vip.21cn.com',
  'vip.citiz.net',
  'vip.gr',
  'vip.onet.pl',
  'vip.qq.com',
  'vip.sina.com',
  'vipmail.ru',
  'virgilio.it',
  'virgin.net',
  'virginbroadband.com.au',
  'virginmedia.com',
  'virtualmail.com',
  'visitmail.com',
  'visitweb.com',
  'visto.com',
  'visualcities.com',
  'vivavelocity.com',
  'vivianhsu.net',
  'vjtimail.com',
  'vkcode.ru',
  'vnet.citiz.net',
  'vnn.vn',
  'vodafone.nl',
  'vodafonethuis.nl',
  'volcanomail.com',
  'vollbio.de',
  'volloeko.de',
  'vomoto.com',
  'vorsicht-bissig.de',
  'vorsicht-scharf.de',
  'vote-democrats.com',
  'vote-hillary.com',
  'vote-republicans.com',
  'vote4gop.org',
  'votenet.com',
  'vp.pl',
  'vr9.com',
  'vubby.com',
  'w3.to',
  'wahoye.com',
  'walala.org',
  'wales2000.net',
  'walkmail.net',
  'walkmail.ru',
  'wam.co.za',
  'wanadoo.es',
  'wanadoo.fr',
  'war-im-urlaub.de',
  'warmmail.com',
  'warpmail.net',
  'warrior.hu',
  'waumail.com',
  'wazabi.club',
  'wbdet.com',
  'wearab.net',
  'web-contact.info',
  'web-emailbox.eu',
  'web-ideal.fr',
  'web-mail.com.ar',
  'web-mail.pp.ua',
  'web-police.com',
  'web.de',
  'webave.com',
  'webcammail.com',
  'webcity.ca',
  'webcontact-france.eu',
  'webdream.com',
  'webindia123.com',
  'webjump.com',
  'webm4il.info',
  'webmail.co.yu',
  'webmail.co.za',
  'webmail.hu',
  'webmails.com',
  'webname.com',
  'webprogramming.com',
  'webstation.com',
  'websurfer.co.za',
  'webtopmail.com',
  'webuser.in',
  'wee.my',
  'weedmail.com',
  'weekmail.com',
  'weekonline.com',
  'wefjo.grn.cc',
  'weg-werf-email.de',
  'wegas.ru',
  'wegwerf-emails.de',
  'wegwerfmail.info',
  'wegwerpmailadres.nl',
  'wehshee.com',
  'weibsvolk.de',
  'weibsvolk.org',
  'weinenvorglueck.de',
  'welsh-lady.com',
  'westnet.com',
  'westnet.com.au',
  'wetrainbayarea.com',
  'wfgdfhj.tk',
  'whale-mail.com',
  'whartontx.com',
  'whatiaas.com',
  'whatpaas.com',
  'wheelweb.com',
  'whipmail.com',
  'whoever.com',
  'whoopymail.com',
  'whtjddn.33mail.com',
  'wi.rr.com',
  'wi.twcbc.com',
  'wickmail.net',
  'wideopenwest.com',
  'wildmail.com',
  'wilemail.com',
  'will-hier-weg.de',
  'windowslive.com',
  'windrivers.net',
  'windstream.net',
  'wingnutz.com',
  'winmail.com.au',
  'winning.com',
  'wir-haben-nachwuchs.de',
  'wir-sind-cool.org',
  'wirsindcool.de',
  'witty.com',
  'wiz.cc',
  'wkbwmail.com',
  'wmail.cf',
  'wo.com.cn',
  'woh.rr.com',
  'wolf-web.com',
  'wolke7.net',
  'wollan.info',
  'wombles.com',
  'women-at-work.org',
  'wongfaye.com',
  'wooow.it',
  'worker.com',
  'workmail.com',
  'worldemail.com',
  'worldnet.att.net',
  'wormseo.cn',
  'wosaddict.com',
  'wouldilie.com',
  'wovz.cu.cc',
  'wowgirl.com',
  'wowmail.com',
  'wowway.com',
  'wp.pl',
  'wptamail.com',
  'wrexham.net',
  'writeme.com',
  'writemeback.com',
  'wrongmail.com',
  'wtvhmail.com',
  'wwdg.com',
  'www.com',
  'www.e4ward.com',
  'www2000.net',
  'wx88.net',
  'wxs.net',
  'x-mail.net',
  'x-networks.net',
  'x5g.com',
  'xagloo.com',
  'xaker.ru',
  'xing886.uu.gl',
  'xmastime.com',
  'xms.nl',
  'xmsg.com',
  'xoom.com',
  'xoxox.cc',
  'xpressmail.zzn.com',
  'xs4all.nl',
  'xsecurity.org',
  'xsmail.com',
  'xtra.co.nz',
  'xuno.com',
  'xww.ro',
  'xy9ce.tk',
  'xyzfree.net',
  'xzapmail.com',
  'y7mail.com',
  'ya.ru',
  'yada-yada.com',
  'yaho.com',
  'yahoo.ae',
  'yahoo.at',
  'yahoo.be',
  'yahoo.ca',
  'yahoo.ch',
  'yahoo.cn',
  'yahoo.co',
  'yahoo.co.id',
  'yahoo.co.il',
  'yahoo.co.in',
  'yahoo.co.jp',
  'yahoo.co.kr',
  'yahoo.co.nz',
  'yahoo.co.th',
  'yahoo.co.uk',
  'yahoo.co.za',
  'yahoo.com',
  'yahoo.com.ar',
  'yahoo.com.au',
  'yahoo.com.br',
  'yahoo.com.cn',
  'yahoo.com.co',
  'yahoo.com.hk',
  'yahoo.com.is',
  'yahoo.com.mx',
  'yahoo.com.my',
  'yahoo.com.ph',
  'yahoo.com.ru',
  'yahoo.com.sg',
  'yahoo.com.tr',
  'yahoo.com.tw',
  'yahoo.com.vn',
  'yahoo.cz',
  'yahoo.de',
  'yahoo.dk',
  'yahoo.es',
  'yahoo.fi',
  'yahoo.fr',
  'yahoo.gr',
  'yahoo.hu',
  'yahoo.ie',
  'yahoo.in',
  'yahoo.it',
  'yahoo.jp',
  'yahoo.nl',
  'yahoo.no',
  'yahoo.pl',
  'yahoo.pt',
  'yahoo.ro',
  'yahoo.ru',
  'yahoo.se',
  'yahoofs.com',
  'yalla.com',
  'yalla.com.lb',
  'yalook.com',
  'yam.com',
  'yandex.com',
  'yandex.pl',
  'yandex.ru',
  'yandex.ua',
  'yapost.com',
  'yapped.net',
  'yawmail.com',
  'yeah.net',
  'yebox.com',
  'yehey.com',
  'yemenmail.com',
  'yepmail.net',
  'yert.ye.vc',
  'yesey.net',
  'yifan.net',
  'ymail.com',
  'yogotemail.com',
  'yomail.info',
  'yopmail.com',
  'yopmail.pp.ua',
  'yopolis.com',
  'yopweb.com',
  'youareadork.com',
  'youmailr.com',
  'your-house.com',
  'your-mail.com',
  'yourinbox.com',
  'yourlifesucks.cu.cc',
  'yourlover.net',
  'yourname.freeservers.com',
  'yournightmare.com',
  'yours.com',
  'yourssincerely.com',
  'yoursubdomain.zzn.com',
  'yourteacher.net',
  'yourwap.com',
  'yuuhuu.net',
  'yyhmail.com',
  'z1p.biz',
  'za.com',
  'zahadum.com',
  'zaktouni.fr',
  'zeepost.nl',
  'zetmail.com',
  'zhaowei.net',
  'zhouemail.510520.org',
  'ziggo.nl',
  'zionweb.org',
  'zip.net',
  'zipido.com',
  'ziplip.com',
  'zipmail.com',
  'zipmail.com.br',
  'zipmax.com',
  'zmail.ru',
  'zoemail.com',
  'zoemail.org',
  'zoho.com',
  'zohomail.com',
  'zomg.info',
  'zonnet.nl',
  'zoominternet.net',
  'zubee.com',
  'zuvio.com',
  'zuzzurello.com',
  'zwallet.com',
  'zweb.in',
  'zxcv.com',
  'zxcvbnm.com',
  'zybermail.com',
  'zydecofan.com',
  'zzn.com',
  'zzom.co.uk',
  'zzz.com',
  'zzz.pl',
]

export default others.concat(competitors)
