import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9166 7.08323C16.8996 7.06615 16.8827 7.04892 16.8662 7.03154L14.8117 4.97712C14.8117 4.97712 15.8398 6.00515 16.047 4.85781C16.0759 4.13619 16.3658 3.42309 16.9167 2.87216C18.0796 1.70928 19.965 1.70928 21.1278 2.87216C22.2907 4.03503 22.2907 5.92042 21.1278 7.08329C20.5769 7.63423 19.8638 7.92415 19.1422 7.95305C17.9949 8.16022 19.0229 9.18825 19.0229 9.18825L16.9685 7.13383C16.9511 7.11728 16.9339 7.10047 16.9168 7.08341L20.426 10.5926C21.2012 11.3678 21.2012 12.6248 20.426 13.4L13.4074 20.4186C12.6322 21.1938 11.3752 21.1938 10.6 20.4186L3.58144 13.4C2.80619 12.6248 2.80619 11.3678 3.58144 10.5926L7.09072 7.08329C7.07362 7.1004 7.05677 7.11766 7.04017 7.13507L4.98576 9.18947C4.98576 9.18947 6.0138 8.16144 6.22097 9.30877C6.24987 10.0304 6.53979 10.7435 7.09072 11.2944C8.25359 12.4573 10.139 12.4573 11.3019 11.2944C12.4647 10.1316 12.4647 8.24617 11.3019 7.08329C10.7509 6.53236 10.0378 6.24244 9.3162 6.21354C8.16886 6.00637 9.1969 4.97834 9.1969 4.97834L7.14248 7.03275C7.12507 7.04935 7.10782 7.0662 7.09072 7.08329L10.6 3.57401C11.3752 2.79876 12.6322 2.79876 13.4074 3.57401L16.9166 7.08323Z"
      fill="nnone"
    />
  </svg>
)
