import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0038 7.49956V10.8329M10.0038 14.1662H10.0121M8.53709 3.29706L1.51876 15.0137C1.37416 15.2643 1.29766 15.5484 1.29688 15.8377C1.2961 16.127 1.37106 16.4115 1.51431 16.6629C1.65756 16.9142 1.86411 17.1237 2.11342 17.2705C2.36274 17.4173 2.64613 17.4962 2.93543 17.4996H16.9729C17.2622 17.4962 17.5456 17.4173 17.7949 17.2705C18.0442 17.1237 18.2508 16.9142 18.394 16.6629C18.5373 16.4115 18.6123 16.127 18.6115 15.8377C18.6107 15.5484 18.5342 15.2643 18.3896 15.0137L11.3704 3.29706C11.2226 3.05372 11.0146 2.85255 10.7665 2.71294C10.5184 2.57334 10.2385 2.5 9.95376 2.5C9.66905 2.5 9.38914 2.57334 9.14101 2.71294C8.89288 2.85255 8.68489 3.05372 8.53709 3.29706Z"
      stroke="#B62814"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
