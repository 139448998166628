import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import Logo from './footer.logo_svg'
import messages from './footer.messages'

export class FooterComponent extends PureComponent {
  render() {
    return (
      <footer className="footer__container">
        <div className="footer__wrapper">
          <Logo />
          <p className="footer__copyright">
            <FormattedMessage {...messages.copyright} />
          </p>
        </div>
      </footer>
    )
  }
}

export const Footer = injectIntl(FooterComponent)
