import { defineMessages } from 'react-intl'

export const REQUIRED_FIELD_ERROR = 'requiredFieldError'
export const INVALID_EMAIL_ERROR = 'invalidEmailError'

export default defineMessages({
  optional: {
    id: 'forms.optional',
    defaultMessage: 'Optional',
  },
  [REQUIRED_FIELD_ERROR]: {
    id: 'forms.requiredFieldError',
    defaultMessage: 'This field is required',
  },
  [INVALID_EMAIL_ERROR]: {
    id: 'forms.invalidEmailError',
    defaultMessage: 'Invalid email',
  },
})
