import React from 'react'
import createSVGComponent from './createSVGComponent'

export default createSVGComponent(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="square">
      <path
        id="Vector"
        d="M2.66667 3.33333C2.66667 3.15652 2.7369 2.98695 2.86193 2.86193C2.98695 2.7369 3.15652 2.66667 3.33333 2.66667H12.6667C12.8435 2.66667 13.013 2.7369 13.1381 2.86193C13.2631 2.98695 13.3333 3.15652 13.3333 3.33333V12.6667C13.3333 12.8435 13.2631 13.013 13.1381 13.1381C13.013 13.2631 12.8435 13.3333 12.6667 13.3333H3.33333C3.15652 13.3333 2.98695 13.2631 2.86193 13.1381C2.7369 13.013 2.66667 12.8435 2.66667 12.6667V3.33333Z"
        stroke="#36454F"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
