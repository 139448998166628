import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
      fill="#0EBF75"
    />
    <path
      d="M16.9999 24.219L27.5939 13.578L28.9999 14.984L16.9999 26.984L11.4219 21.406L12.7809 20L16.9999 24.219Z"
      fill="white"
    />
  </svg>
)
