/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  next: {
    id: 'navButtons.next',
    defaultMessage: 'Next Step',
  },
  back: {
    id: 'navButtons.back',
    defaultMessage: 'Previous Step',
  },
  submit: {
    id: 'navButtons.submit',
    defaultMessage: 'Get Details',
  },
})
