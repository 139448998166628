import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'columnSelector.title',
    defaultMessage: 'Column containing zipcode',
  },
  ourBestGuess: {
    id: 'columnSelector.ourBestGuess',
    defaultMessage: 'Our best guess',
  },
  exampleValues: {
    id: 'columnSelector.exampleValues',
    defaultMessage: 'Example values',
  },
  emptyTitlePlaceholder: {
    id: 'columnSelector.emptyTitlePlaceholder',
    defaultMessage: 'Untitled column',
  },
})
