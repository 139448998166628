import React from 'react'
import createSVGComponent from './createSVGComponent'

export default createSVGComponent(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5829 17.8624C15.1513 19.014 13.332 19.7033 11.3516 19.7033C6.73916 19.7033 3 15.9641 3 11.3516C3 6.73916 6.73916 3 11.3516 3C15.9641 3 19.7033 6.73916 19.7033 11.3516C19.7033 13.332 19.0141 15.1513 17.8624 16.5828L21.735 20.4555C22.0884 20.8088 22.0884 21.3817 21.735 21.735C21.3817 22.0883 20.8088 22.0883 20.4555 21.735L16.5829 17.8624ZM17.8938 11.3516C17.8938 14.9648 14.9648 17.8938 11.3516 17.8938C7.73853 17.8938 4.80952 14.9648 4.80952 11.3516C4.80952 7.73853 7.73853 4.80952 11.3516 4.80952C14.9648 4.80952 17.8938 7.73853 17.8938 11.3516Z"
      fill="black"
    />
  </svg>
)
