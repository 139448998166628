import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0026 2.5L6.66927 8.33333H13.3359L10.0026 2.5Z"
      stroke="#657385"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6693 14.1667C11.6693 14.8297 11.9327 15.4656 12.4015 15.9344C12.8703 16.4033 13.5062 16.6667 14.1693 16.6667C14.8323 16.6667 15.4682 16.4033 15.937 15.9344C16.4059 15.4656 16.6693 14.8297 16.6693 14.1667C16.6693 13.5036 16.4059 12.8677 15.937 12.3989C15.4682 11.9301 14.8323 11.6667 14.1693 11.6667C13.5062 11.6667 12.8703 11.9301 12.4015 12.3989C11.9327 12.8677 11.6693 13.5036 11.6693 14.1667Z"
      stroke="#657385"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33594 12.5C3.33594 12.279 3.42374 12.067 3.58002 11.9107C3.7363 11.7545 3.94826 11.6667 4.16927 11.6667H7.5026C7.72362 11.6667 7.93558 11.7545 8.09186 11.9107C8.24814 12.067 8.33594 12.279 8.33594 12.5V15.8333C8.33594 16.0543 8.24814 16.2663 8.09186 16.4226C7.93558 16.5789 7.72362 16.6667 7.5026 16.6667H4.16927C3.94826 16.6667 3.7363 16.5789 3.58002 16.4226C3.42374 16.2663 3.33594 16.0543 3.33594 15.8333V12.5Z"
      stroke="#657385"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
