import React from 'react'

import createSVGComponent from './createSVGComponent'

export default createSVGComponent(
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="12" fill="white" />
    <rect
      x="4"
      width="12"
      height="4"
      transform="rotate(90 4 0)"
      fill="#E31D1C"
    />
    <rect
      x="16"
      width="12"
      height="4"
      transform="rotate(90 16 0)"
      fill="#E31D1C"
    />
    <path
      d="M8.20588 9.86765H7.79412L7.89706 8.22059C7.89124 8.13385 7.8496 8.11105 7.69118 8.11765L6.04412 8.32353C6.04412 8.32353 6.35294 7.91176 6.35294 7.70588C6.35294 7.5 4.5 6.16176 4.5 6.16176C4.5 6.16176 4.91176 6.05882 5.01471 5.95588C5.11765 5.85294 4.60294 4.72059 4.60294 4.72059C4.60294 4.72059 5.64906 5.13235 5.73529 5.02941C5.82153 4.92647 5.94118 4.51471 5.94118 4.51471C5.94118 4.51471 6.76471 5.44118 6.97059 5.44118C7.17647 5.44118 6.55882 3.17647 6.55882 3.17647C6.55882 3.17647 7.07353 3.58824 7.27941 3.58824C7.48529 3.58824 8 2.25 8 2.25C8 2.25 8.51471 3.58824 8.61765 3.58824C8.72059 3.58824 9.44118 3.17647 9.44118 3.17647C9.44118 3.17647 8.92647 5.33824 9.02941 5.44118C9.13235 5.54412 10.0588 4.51471 10.0588 4.51471C10.0588 4.51471 10.1618 4.92647 10.2647 5.02941C10.3676 5.13235 11.3971 4.72059 11.3971 4.72059C11.3971 4.72059 10.8824 5.85294 10.9853 5.95588C11.0882 6.05882 11.5 6.16176 11.5 6.16176C11.5 6.16176 9.64706 7.5 9.64706 7.70588C9.64706 7.91176 9.85294 8.32353 9.85294 8.32353L8.30882 8.11765C8.18471 8.08206 8.14168 8.10907 8.10294 8.22059L8.20588 9.86765Z"
      fill="#E31D1C"
    />
  </svg>
)
