import FontFaceObserver from 'fontfaceobserver'
import React from 'react'
import { createRoot } from 'react-dom/client'

// Needed for redux-saga es6 generator support
import '@babel/polyfill'

// Import all the third party stuff
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import 'normalize.css/normalize.css'
import './theme/index.scss'
import configureStore from './modules/store'
import { Graph } from './shared/services/graph'
import UnsupportedBrowserDetection from './shared/utils/unsupportedBrowserDetection'

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {})

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(
  () => {
    document.body.classList.add('fontLoaded')
  },
  () => {
    document.body.classList.remove('fontLoaded')
  }
)
Graph.initialize()

// Create redux store with history
// this uses the singleton history provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const history = useRouterHistory(createBrowserHistory)();`
const initialState = {}
const store = configureStore(initialState)

if (process.env.NODE_ENV === 'development') {
  if (!window.__REDUX_DEVTOOLS_EXTENSION__) {
    const DevToolsComponent =
      require('./shared/utils/devtools.component').default
    const devToolsRoot = window.document.createElement('div')

    window.document.body.appendChild(devToolsRoot)
    const root = createRoot(devToolsRoot)

    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <DevToolsComponent />
        </Provider>
      </React.StrictMode>
    )
  }
}

const render = () => {
  const NextApp = require('./routes').default

  const root = createRoot(document.getElementById('app'))
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <NextApp />
      </BrowserRouter>
    </Provider>
  )
}

const initApp = () => {
  const detection = new UnsupportedBrowserDetection()
  if (!detection.isSupported()) {
    return
  }

  // Chunked polyfill for browsers without Intl support
  if (!window.Intl) {
    new Promise((resolve) => {
      resolve(require('intl'))
    })
      .then(() => Promise.all([require('intl/locale-data/jsonp/en.js')]))
      .then(() => render())
      .catch((err) => {
        throw err
      })
  } else {
    render()
  }
}

initApp()
