import { path, pathOr } from 'ramda'
import { put, takeLatest, select, delay } from 'redux-saga/effects'

import api from '../../shared/services/api'
import { submitSalesforceWebToLeadForm } from '../../shared/services/salesforceWebToLead'
import { getErrorMessageKey } from '../../shared/utils/errorHelpers'
import reportError from '../../shared/utils/reportError'
import { MIN_PROCESSING_TIME } from './reports.constants'
import { saveReportErrorMessages } from './reports.messages'
import { ReportsTypes, ReportsActions } from './reports.redux'
import {
  selectReportsPostCodes,
  selectReportsFrequency,
  selectReportsResponseTime,
  selectReportsData,
  selectReportsAreProgramsAvailable,
} from './reports.selectors'

function* generateReport({ postCodes }) {
  try {
    const beforeRequestTime = new Date().getTime()
    const { data } = yield api.post('/potential-earnings', {
      zipcodes: postCodes,
    })
    const afterRequestTime = new Date().getTime()

    const missingProcessingTime = Math.max(
      MIN_PROCESSING_TIME - (afterRequestTime - beforeRequestTime),
      0
    )
    yield delay(missingProcessingTime)

    yield put(ReportsActions.generateSuccess(data))
  } catch (error) {
    yield put(ReportsActions.generateFailure(path(['response', 'data'], error)))

    if (!error.response) {
      yield reportError(error)
    }
  }
}

export function* save({ formData, formik = {} }) {
  try {
    const postCodes = yield select(selectReportsPostCodes)
    const frequency = yield select(selectReportsFrequency)
    const responseTime = yield select(selectReportsResponseTime)
    const reportData = yield select(selectReportsData)
    const areProgramsAvailable = yield select(selectReportsAreProgramsAvailable)

    const { data } = yield api.post('/save-report', {
      ...formData,
      zipcodes: postCodes,
      frequencyIndex: frequency,
      responseTimeIndex: responseTime,
      earningsData: reportData,
      shouldSendReportEmail: areProgramsAvailable,
    })

    yield put(ReportsActions.saveSuccess(data.token))

    submitSalesforceWebToLeadForm({
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      company: formData.company,
      reportToken: data.token,
      frequency,
      responseTime,
    })
  } catch (error) {
    const errorKey = pathOr('defaultError', ['response', 'data'], error)
    const messageKey = getErrorMessageKey(errorKey, saveReportErrorMessages)
    yield put(ReportsActions.saveFailure(messageKey))
    if (!error.response) {
      reportError(error)
    }
  } finally {
    if (formik.setSubmitting) {
      formik.setSubmitting(false)
    }
  }
}

export function* getSaved({ token }) {
  try {
    const { data } = yield api.get(`/saved-report/${token}`)

    return yield put(ReportsActions.getSavedSuccess(data))
  } catch (error) {
    if (error.response) {
      return yield put(ReportsActions.getSavedFailure(error.response.data))
    }

    return yield reportError(error)
  }
}

export function* watchReports() {
  try {
    yield takeLatest(ReportsTypes.GENERATE, generateReport)
    yield takeLatest(ReportsTypes.SAVE, save)
    yield takeLatest(ReportsTypes.GET_SAVED, getSaved)
  } catch (error) {
    /* istanbul ignore next */
    reportError(error)
  }
}
