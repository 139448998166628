import { defineMessages } from 'react-intl'

export default defineMessages({
  firstNameLabel: {
    id: 'contactForm.field.firstName.label',
    defaultMessage: 'First name',
  },
  lastNameLabel: {
    id: 'contactForm.field.lastName.label',
    defaultMessage: 'Last name',
  },
  emailLabel: {
    id: 'contactForm.field.email.label',
    defaultMessage: 'Email address',
  },
  phoneLabel: {
    id: 'contactForm.field.phone.label',
    defaultMessage: 'Phone number',
  },
  companyNameLabel: {
    id: 'contactForm.field.companyName.label',
    defaultMessage: 'Company Name',
  },
})
