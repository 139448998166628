import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage } from 'react-intl'

import { sendContactErrorMessages } from '../../modules/contact/contact.messages'
import { Alert } from '../../shared/components/alert/alert.component'
import { ALERT_TYPE_SUCCESS } from '../../shared/components/alert/alert.constants'
import { ContactForm } from '../../shared/components/contactForm'
import { renderWhenNotNil, renderWhenTrue } from '../../shared/utils/rendering'
import messages from './contact.messages'

export class Contact extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    submitForm: PropTypes.func.isRequired,
    sendSuccess: PropTypes.bool,
    formError: PropTypes.string,
  }

  static onSubmit = (values, { props, ...formik }) => {
    props.sendContact(values, formik)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleSubmitClick = () => {
    this.props.submitForm()
  }

  renderFormError = renderWhenNotNil((formError) => (
    <Alert className="contact__error">
      <FormattedMessage {...sendContactErrorMessages[formError]} />
    </Alert>
  ))

  renderSuccess = renderWhenTrue(() => {
    const { intl } = this.props
    return (
      <div className="contact__success-container">
        <Alert type={ALERT_TYPE_SUCCESS} className="contact__success-alert">
          <FormattedMessage {...messages.successAlert} />
        </Alert>

        <h1 className="contact__title">
          <FormattedMessage {...messages.thankYou} />
        </h1>

        <p>
          <FormattedMessage {...messages.successDescription} />
        </p>

        <p>
          <a
            href={intl.formatMessage(messages.buttonLink)}
            className="button button--large contact__success-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage {...messages.buttonText} />
          </a>
        </p>
      </div>
    )
  })

  renderForm = renderWhenTrue(() => {
    const { formError, isSubmitting } = this.props
    return (
      <div className="contact__form-container">
        {this.renderFormError(formError)}

        <h1 className="contact__title">
          <FormattedMessage {...messages.title} />
        </h1>

        <p className="contact__description">
          <FormattedMessage {...messages.description} />
        </p>

        <ContactForm isSubmitting={isSubmitting} />

        <button
          type="submit"
          className="button contact__submit"
          onClick={this.handleSubmitClick}
          disabled={isSubmitting}
        >
          <FormattedMessage {...messages.submit} />
        </button>
      </div>
    )
  })

  render() {
    const { sendSuccess } = this.props
    return (
      <div className="contact__container">
        <Helmet title={this.props.intl.formatMessage(messages.pageTitle)} />
        <div className="contact">
          {this.renderSuccess(sendSuccess)}
          {this.renderForm(!sendSuccess)}
        </div>
      </div>
    )
  }
}
