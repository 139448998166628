import PropTypes from 'prop-types'
import qs from 'query-string'
import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage } from 'react-intl'

import { saveReportErrorMessages } from '../../../modules/reports/reports.messages'
import { Alert } from '../../../shared/components/alert'
import { ALERT_TYPE_SUCCESS } from '../../../shared/components/alert/alert.constants'
import { ContactForm } from '../../../shared/components/contactForm'
import { NavigationButtons } from '../../../shared/components/navigationButtons'
import {
  renderWhenNotNil,
  renderWhenTrue,
} from '../../../shared/utils/rendering'
import { HOME_ROUTES } from '../home.constants'
import messages from './learnMore.messages'

export class LearnMore extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    searchParams: PropTypes.object.isRequired,
    submitForm: PropTypes.func.isRequired,
    saveSuccess: PropTypes.bool.isRequired,
    reportsData: PropTypes.object.isRequired,
    frequency: PropTypes.number.isRequired,
    responseTime: PropTypes.number.isRequired,
    isSubmitting: PropTypes.bool,
    errors: PropTypes.object,
    formError: PropTypes.string,
    reportToken: PropTypes.string,
    setReportToken: PropTypes.func.isRequired,
    setFrequency: PropTypes.func.isRequired,
    setResponseTime: PropTypes.func.isRequired,
    generateReport: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isSubmitting: false,
  }

  static onSubmit = (values, { props, ...formik }) => {
    props.saveReport(values, formik)
  }

  get reportLink() {
    const { reportToken, frequency, responseTime } = this.props
    const queryParams = qs.stringify({
      token: reportToken,
      frequencyIndex: frequency,
      responseTimeIndex: responseTime,
    })
    return `${window.location.origin}?${queryParams}`
  }

  state = {
    displaySubmissionErrorMessage: false,
  }

  componentDidMount() {
    const { navigate } = this.props

    // This allows users to land directly on step 2 with pre-populated post codes
    this.bootstrapPostCodesFromParams()

    if (!this.props.reportsData.size) {
      navigate(HOME_ROUTES.siteInfo)
    }

    window.scrollTo(0, 0)
  }

  bootstrapPostCodesFromParams = () => {
    const { searchParams, generateReport } = this.props
    const postCodes = searchParams.getAll('post-code')
    if (postCodes.length) {
      generateReport(postCodes)
    }
  }

  handleBackClick = () => {
    this.props.navigate(HOME_ROUTES.siteInfo)
  }

  handleSubmitClick = () => {
    if (!this.props.isSubmitting) {
      this.props.submitForm()
      if (this.hasValidationError()) {
        this.setState({ displaySubmissionErrorMessage: true })
      } else if (!this.props.formError) {
        this.props.navigate(HOME_ROUTES.report)
      }
    }
  }

  hasValidationError = () => {
    return Object.keys(this.props.errors).length > 0
  }

  renderFormError = renderWhenNotNil((formError) => (
    <Alert className="learn-more__error">
      <FormattedMessage {...saveReportErrorMessages[formError]} />
    </Alert>
  ))

  renderSuccess = renderWhenTrue(() => {
    return (
      <div className="learn-more__success-container">
        <Alert
          type={ALERT_TYPE_SUCCESS}
          className="learn-more__success-alert learn-more__column"
        >
          <FormattedMessage {...messages.successAlert} />
        </Alert>

        <h1 className="learn-more__title learn-more__column">
          <FormattedMessage {...messages.thankYou} />
        </h1>

        <p className="learn-more__paragraph learn-more__column">
          <FormattedMessage {...messages.emailDescription} />
        </p>

        <div className="learn-more__report-link-container">
          <a href={this.reportLink} className="learn-more__report-link">
            {this.reportLink}
          </a>
        </div>

        <p className="learn-more__paragraph learn-more__column">
          <FormattedMessage {...messages.voltanInTouch} />
        </p>
      </div>
    )
  })

  renderForm = () => {
    const { formError, isSubmitting } = this.props
    return (
      <div className="learn-more__form-container learn-more__column">
        {this.renderFormError(formError)}

        <h1 className="learn-more__title">
          <FormattedMessage {...messages.title} />
        </h1>

        <p className="learn-more__description">
          <FormattedMessage {...messages.description} />
        </p>

        <ContactForm isSubmitting={isSubmitting} />
      </div>
    )
  }

  renderFooter = () => (
    <NavigationButtons
      onBack={this.handleBackClick}
      onSubmit={this.handleSubmitClick}
      isSubmitting={this.props.isSubmitting}
    />
  )

  render() {
    return (
      <div className="learn-more__container">
        <Helmet title={this.props.intl.formatMessage(messages.pageTitle)} />
        {this.state.displaySubmissionErrorMessage ? (
          <Alert className="learn-more__error">
            Please update the specified fields and try again.
          </Alert>
        ) : null}
        <div className="learn-more">{this.renderForm()}</div>
        {this.renderFooter()}
      </div>
    )
  }
}
