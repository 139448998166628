import React, { PureComponent } from 'react'

import { Alert as CoreAlert } from '@voltus/core-components'

export class Alert extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return <CoreAlert {...this.props} />
  }
}
