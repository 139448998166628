import React from 'react'

const SVG = ({ className }) => (
  <svg
    className={className}
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.60547 1.48145L6.64281 6.51879L11.6802 1.48145"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SVG
