import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3086 16.1367L9.4414 17.0039C9.07422 17.3711 8.48047 17.3711 8.11719 17.0039L0.523437 9.41406C0.15625 9.04687 0.15625 8.45312 0.523437 8.08984L8.11328 0.499998C8.48047 0.132811 9.07422 0.132811 9.4375 0.499998L10.3047 1.36719C10.6758 1.73828 10.668 2.34375 10.2891 2.70703L5.58594 7.1875L18.8125 7.1875C19.332 7.1875 19.75 7.60547 19.75 8.125V9.375C19.75 9.89453 19.332 10.3125 18.8125 10.3125H5.58594L10.293 14.7969C10.6758 15.1602 10.6836 15.7656 10.3086 16.1367Z"
      fill="#36454F"
    />
  </svg>
)
