import {
  POSTCODES_MAX_ROWS,
  INVALID_FILE_ERROR,
} from './postcodesFile.constants'

export const onFileLoaded = (resolve, reject) => (e) => {
  const text = e.target.result
  const hasDoubleQuotes = text.indexOf('"') > -1
  const rowsLength = text.indexOf('\n') > -1 ? text.match(/\n/g).length : 1
  if (hasDoubleQuotes && rowsLength > POSTCODES_MAX_ROWS) {
    reject({ code: INVALID_FILE_ERROR })
  }
  resolve()
}

export const validateFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = onFileLoaded(resolve, reject)

    reader.readAsText(file)
  })
}
