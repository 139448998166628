import { useEffect } from 'react'
import { Any } from '@voltus/types'
import keyboard from '../keyboard'

export function useKeyboardHandler(
  cb: (evt: KeyboardEvent) => void,
  deps: Array<Any> = [],
  listenerKey = 'keyup'
) {
  useEffect(() => {
    const listener = (evt) => {
      cb(evt)
    }
    window.addEventListener(listenerKey, listener)
    return () => {
      window.removeEventListener(listenerKey, listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export function useEscapeKey(
  cb: (evt: KeyboardEvent) => void,
  deps: Array<Any> = [],
  listenerKey = 'keyup'
) {
  useKeyboardHandler(
    (code) => {
      if (keyboard.isEscapeKey(code)) {
        cb(code)
      }
    },
    deps,
    listenerKey
  )
}

export function useEnterKey(
  cb: (evt: KeyboardEvent) => void,
  deps: Array<Any> = [],
  listenerKey = 'keyup'
) {
  useKeyboardHandler(
    (code) => {
      if (keyboard.isEnterKey(code)) {
        cb(code)
      }
    },
    deps,
    listenerKey
  )
}
