import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="38"
    height="32"
    viewBox="0 0 38 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.4996 30.5727L35.4312 31L1.35059 3.009L2.41894 2.58167L36.4996 30.5727Z"
      fill="#36454F"
      stroke="#36454F"
      strokeMiterlimit="10"
    />
    <path
      d="M33.081 22.2396C32.7605 23.4148 32.3331 24.4832 31.5853 25.4447L31.2647 25.872H31.1579C29.0212 28.2224 25.8161 29.5045 21.97 29.3976C16.5214 29.3976 11.3933 26.7267 8.08137 22.4533C0.709691 12.6244 0.923365 2.7955 1.0302 2.36816V1.94081L1.45754 1.83398C1.88489 1.72714 12.1411 -0.730087 22.5042 3.32968C27.0981 5.14588 30.9442 9.52616 32.5468 14.868C33.5083 17.2183 33.6151 19.7824 33.081 22.2396ZM30.9442 24.1627C32.2263 21.9191 32.5468 18.6072 31.4784 15.1885C30.0896 10.1672 26.4571 6.10741 22.1837 4.39803C13.3163 0.979287 4.34211 2.36816 2.20539 2.90234C2.31223 4.93222 2.73957 13.4791 9.04289 21.9191C12.1411 25.9789 16.9487 28.2224 21.97 28.4361C25.7093 28.4361 28.7007 27.2609 30.4101 24.8037L30.9442 24.1627Z"
      fill="#36454F"
      stroke="#36454F"
      strokeMiterlimit="10"
    />
    <path
      d="M17 14.5333L11.069 15L11 14.2667L16.1724 13.8667L15.4828 8.06666L16.1724 8L17 14.5333Z"
      fill="#36454F"
      stroke="#36454F"
      strokeMiterlimit="10"
    />
    <path
      d="M26.1346 22.492L16.8398 23.3467V22.1715L24.8525 21.4236L23.7842 12.1289H24.8525L26.1346 22.492Z"
      fill="#36454F"
      stroke="#36454F"
      strokeMiterlimit="10"
    />
  </svg>
)
