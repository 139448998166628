import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 16L6.5 10.5" stroke="black" strokeLinejoin="round" />
    <path
      d="M11 9.5L10.5 14.5L2.5 6.5L7.5 6M11 9.5L7.5 6M11 9.5L16 4.5L12.5 1L11 2.5L7.5 6"
      stroke="black"
    />
  </svg>
)
