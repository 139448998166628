import { all, fork } from 'redux-saga/effects'

import reportError from '../shared/utils/reportError'
import { watchContact } from './contact/contact.sagas'
import { watchPostcodesFile } from './postcodesFile/postcodesFile.sagas'
import { watchReports } from './reports/reports.sagas'
import { watchStartup } from './startup/startup.sagas'
//<-- IMPORT MODULE SAGA -->

export default function* rootSaga() {
  try {
    yield all([
      fork(watchStartup),
      fork(watchReports),
      fork(watchPostcodesFile),
      fork(watchContact),
      //<-- INJECT MODULE SAGA -->
    ])
  } catch (e) {
    yield reportError(e)
  }
}
