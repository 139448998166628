import styled, { StyledComponent } from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { StyledProps, propTransformer, css } from '../../utils/styledSystem'

type StyledComponentWithProps = StyledComponent<StyledProps>

//
// Box component use styled system props, like shortcuts: mt/mr/mb/ml for margins etc.
// more: https://styled-system.com/api
//
const Box: StyledComponentWithProps = styled('div', {
  shouldForwardProp,
})(propTransformer, css) as StyledComponentWithProps

Box.displayName = 'Box'

export { Box }
