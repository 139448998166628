import { withFormik } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import * as Yup from 'yup'

import {
  PostcodesFileActions,
  selectIsFileSet,
  selectFileMeta,
  selectValues,
  selectDataSize,
  selectPreview,
  selectPostcodeColumn,
  selectColumnNames,
} from '../../../modules/postcodesFile'
import {
  ReportsActions,
  selectReportsPostCodes,
} from '../../../modules/reports'
import { ZIP_CODE_PATTERN } from '../../../modules/reports/reports.constants'
import { POST_CODES_REQUIRED_ERROR } from './postCodesForm/postCodesForm.messages'
import { SiteInfo } from './siteInfo.component'

const mapStateToProps = createStructuredSelector({
  postCodes: selectReportsPostCodes,
  isFileSet: selectIsFileSet,
  fileMeta: selectFileMeta,
  fileValues: selectValues,
  fileDataSize: selectDataSize,
  filePreview: selectPreview,
  fileColumnNames: selectColumnNames,
  postcodeColumn: selectPostcodeColumn,
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      generateReport: ReportsActions.generate,
      parseFile: PostcodesFileActions.parseFile,
      resetFile: PostcodesFileActions.resetData,
      setPostcodeColumn: PostcodesFileActions.setPostcodeColumn,
    },
    dispatch
  )

const BaseSiteInfo = (props) => {
  const navigate = useNavigate()
  const intl = useIntl()

  return <SiteInfo navigate={navigate} intl={intl} {...props} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    enableReinitialize: true,
    handleSubmit: SiteInfo.onSubmit,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      postCodes: Yup.array()
        .compact((value) => !ZIP_CODE_PATTERN.test(value))
        .min(1, POST_CODES_REQUIRED_ERROR)
        .required(POST_CODES_REQUIRED_ERROR),
    }),
    mapPropsToValues: (props) => {
      const postCodes = props.postCodes.toArray()
      return {
        postCodes: postCodes.length ? postCodes : [''],
      }
    },
  })(BaseSiteInfo)
)
