import * as Yup from 'yup'
import formsMessages from '../forms/forms.messages'
import disallowedDomains from './disallowedDomains'

export const FIRST_NAME_FIELD_NAME = 'firstName'
export const LAST_NAME_FIELD_NAME = 'lastName'
export const EMAIL_FIELD_NAME = 'email'
export const PHONE_FIELD_NAME = 'phone'
export const COMPANY_FIELD_NAME = 'company'

export const CONTACT_FORM_VALIDATION = {
  [FIRST_NAME_FIELD_NAME]: Yup.string()
    .required(formsMessages.requiredFieldError)
    .nullable(),
  [LAST_NAME_FIELD_NAME]: Yup.string()
    .required(formsMessages.requiredFieldError)
    .nullable(),
  [EMAIL_FIELD_NAME]: Yup.string()
    .email(formsMessages.invalidEmailError)
    .required(formsMessages.requiredFieldError)
    .test(
      'allowedEmail',
      'Please enter a valid business email address.',
      (value) => {
        return !disallowedDomains.some((domain) => {
          return value?.includes(domain)
        })
      }
    )
    .nullable(),
  [PHONE_FIELD_NAME]: Yup.string()
    .required(formsMessages.requiredFieldError)
    .nullable(),
  [COMPANY_FIELD_NAME]: Yup.string()
    .required(formsMessages.requiredFieldError)
    .nullable(),
}

export const CONTACT_FORM_INITIAL_VALUES = {
  [FIRST_NAME_FIELD_NAME]: '',
  [LAST_NAME_FIELD_NAME]: '',
  [EMAIL_FIELD_NAME]: '',
  [PHONE_FIELD_NAME]: '',
  [COMPANY_FIELD_NAME]: '',
}
