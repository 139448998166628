/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'learnMore.pageTitle',
    defaultMessage: 'Learn more',
  },
  title: {
    id: 'learnMore.title',
    defaultMessage: "You're almost there!",
  },
  description: {
    id: 'learnMore.description',
    defaultMessage:
      'Fill out your info to receive a customized earnings estimate report that shows your estimated ' +
      'revenue based on available programs, operational requirements, event frequency, and response time.',
  },
  successAlert: {
    id: 'learnMore.successAlert',
    defaultMessage: "We've received your request.",
  },
  thankYou: {
    id: 'learnMore.thankYou',
    defaultMessage: 'Thank You!',
  },
  emailDescription: {
    id: 'learnMore.emailDescription',
    defaultMessage:
      'We’ll  email you a copy of this report and you can use this link' +
      ' to share it with a colleague or get back to it for your own reference anytime.',
  },
  voltanInTouch: {
    id: 'learnMore.voltanInTouch',
    defaultMessage:
      'A Voltan will be in touch soon about how you can start earning cash' +
      ' for your operational flexibility.',
  },
})
