/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'report.pageTitle',
    defaultMessage: 'Report',
  },
  title: {
    id: 'report.title',
    defaultMessage:
      'Congratulations!  Voltus has cash generating programs in your area.',
  },
  description: {
    id: 'report.description',
    defaultMessage:
      'We’ll email you a copy of this report for your reference. A Voltus expert will also be in touch to outline a path to cash.',
  },
  loader: {
    id: 'report.loader',
    defaultMessage: 'Hang tight while we find programs in your area.',
  },
  error: {
    id: 'report.error',
    defaultMessage: 'Something went wrong, please try again.',
  },
  goBack: {
    id: 'report.goBack',
    defaultMessage: 'Go Back',
  },
})
