import React from 'react'
import { HexColorString } from '@voltus/types'
import colors from '../constants/colors.json'
import createSVGComponent from './createSVGComponent'

type EyeProps = {
  [key: string]: unknown
  color?: HexColorString | string
}

export default createSVGComponent<EyeProps>(
  ({ color = colors.grays[20], ...props }: EyeProps): JSX.Element => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke={color}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <line x1="3" y1="3" x2="21" y2="21" />
      <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
      <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
    </svg>
  )
)
