import { List, Map } from 'immutable'
import { createSelector } from 'reselect'

import { STATES } from './reports.constants'

export const selectReportsDomain = (state) => state.get('reports')

export const selectReportsPostCodes = createSelector(
  selectReportsDomain,
  (state) => state.get('postCodes')
)

export const selectReportsData = createSelector(selectReportsDomain, (state) =>
  state.get('data')
)

export const selectReportsHasData = createSelector(
  selectReportsData,
  (state) => !!state.size
)

export const selectReportsIsLoadingData = createSelector(
  selectReportsDomain,
  (state) => state.get('isLoadingData')
)

export const selectReportsFrequency = createSelector(
  selectReportsDomain,
  (state) => state.get('frequency')
)

export const selectReportsResponseTime = createSelector(
  selectReportsDomain,
  (state) => state.get('responseTime')
)

export const selectReportSaveSuccess = createSelector(
  selectReportsDomain,
  (state) => state.get('saveSuccess')
)

export const selectReportToken = createSelector(selectReportsDomain, (state) =>
  state.get('reportToken')
)

export const selectReportSaveError = createSelector(
  selectReportsDomain,
  (state) => state.get('saveError')
)

export const selectReportsStates = createSelector(selectReportsData, (data) =>
  data.get('states', List())
)

export const selectReportsAllStatesLabels = createSelector(
  selectReportsStates,
  (states) => states.map((state) => state.get('name'))
)

export const selectReportsAllStatesEarnings = createSelector(
  selectReportsStates,
  selectReportsFrequency,
  selectReportsResponseTime,
  (states, frequency, responseTime) =>
    states.map((state) =>
      state.getIn(['values', frequency, responseTime, 'earnings'])
    )
)

export const selectReportsAllStatesPrograms = createSelector(
  selectReportsStates,
  selectReportsFrequency,
  selectReportsResponseTime,
  (states, frequency, responseTime) =>
    states.map((state) =>
      state.getIn(['values', frequency, responseTime, 'programs'])
    )
)

export const selectReportsAreProgramsAvailable = createSelector(
  selectReportsStates,
  (states) =>
    states.some((state) =>
      state
        .get('values')
        .some((values) => values.some((value) => value.get('programs')))
    )
)

export const selectReportsStatesIndexes = createSelector(
  selectReportsAllStatesPrograms,
  (programs) =>
    programs
      .map((program, index) => Map({ program, index }))
      .filter((item) => !!item.get('program'))
      .map((item) => item.get('index'))
)

export const selectReportsStatesLabels = createSelector(
  selectReportsAllStatesLabels,
  selectReportsStatesIndexes,
  (allLabels, indexes) => indexes.map((index) => allLabels.get(index))
)

export const selectReportsStatesAbbreviations = createSelector(
  selectReportsStatesLabels,
  (labels) =>
    labels.map((label) => (STATES[label] ? STATES[label].code : label))
)

export const selectReportsStatesEarnings = createSelector(
  selectReportsAllStatesEarnings,
  selectReportsStatesIndexes,
  (allEarnings, indexes) => indexes.map((index) => allEarnings.get(index))
)

export const selectReportsStatesPrograms = createSelector(
  selectReportsAllStatesPrograms,
  selectReportsStatesIndexes,
  (allPrograms, indexes) => indexes.map((index) => allPrograms.get(index))
)

export const selectReportsTotalSites = createSelector(
  selectReportsData,
  (data) => data.get('totalSites', 0)
)

export const selectReportsTotalEarnings = createSelector(
  selectReportsStates,
  selectReportsFrequency,
  selectReportsResponseTime,
  (states, frequency, responseTime) =>
    states.reduce(
      (prev, state) =>
        prev + state.getIn(['values', frequency, responseTime, 'earnings']),
      0
    )
)

export const selectReportsTotalPrograms = createSelector(
  selectReportsStates,
  selectReportsFrequency,
  selectReportsResponseTime,
  (states, frequency, responseTime) =>
    states.reduce(
      (prev, state) =>
        prev + state.getIn(['values', frequency, responseTime, 'programs']),
      0
    )
)
