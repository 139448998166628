import React from 'react'
import { AnyObject, HexColorString } from '@voltus/types'

type IconProps = {
  [key: string]: unknown
  color?: HexColorString | 'string'
}

function createSVGComponent<P = AnyObject>(
  SVG: React.ReactNode | ((props: P & IconProps) => JSX.Element)
) {
  return React.forwardRef<HTMLElement, P & IconProps>(function IconForwardedRef(
    props,
    ref
  ) {
    if (React.isValidElement(SVG)) {
      return React.cloneElement(SVG, {
        ...props,
        ref,
      })
    }

    if (typeof SVG === 'function') {
      return <SVG ref={ref} {...props} />
    }

    return null
  })
}

export default createSVGComponent
