import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'csvFileUploader.title',
    defaultMessage: 'Rather upload a CSV?',
  },
  hint: {
    id: 'csvFileUploader.hint',
    defaultMessage: 'Drag a file here or {link}',
  },
  browse: {
    id: 'csvFileUploader.browse',
    defaultMessage: 'browse your computer',
  },
  dropHere: {
    id: 'csvFileUploader.dropHere',
    defaultMessage: 'Drop file here',
  },
  rejected: {
    id: 'csvFileUploader.rejected',
    defaultMessage: 'Some files are not supported',
  },
})
