import cx from 'classnames'
import React, { PureComponent } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'

import Chevron from './header.chevron_svg'
import Lock from './header.lock_svg'
import LogoGray from './header.logo_svg'
import messages from './header.messages'
import urls from './header.urls'

const DROPDOWN_MENU_KEYS = {
  WHO_WE_HELP: 'who_we_help',
  ABOUT: 'about',
  LEARN: 'learn',
}
export class HeaderComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isMobileMenuOpen: false,
      openDropdown: '',
    }
    this.handleTap = this.handleTap.bind(this)
    this.createHandleToggle = this.createHandleToggle.bind(this)
    this.handleWindowResize = this.handleWindowResize.bind(this)
  }

  get mobileTrayClassNames() {
    return cx('header__tray-mobile', {
      'header__tray-mobile--open': this.state.isMobileMenuOpen,
    })
  }

  componentDidMount() {
    // need to close the mobile menu when the app goes from mobile to desktop widths
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize() {
    const tabletBreakPoint = 1024
    if (window.innerWidth >= tabletBreakPoint && this.state.isMobileMenuOpen) {
      this.setState({ isMobileMenuOpen: false })
    }
  }

  handleTap() {
    this.setState(() => {
      return {
        isMobileMenuOpen: !this.state.isMobileMenuOpen,
      }
    })
  }

  createHandleToggle(dropdownMenuKey) {
    return () => {
      this.setState((state) => {
        if (state.openDropdown === dropdownMenuKey) {
          return { openDropdown: '' }
        }
        return { openDropdown: dropdownMenuKey }
      })
    }
  }

  render() {
    return (
      <div>
        <div className="header__container-mobile">
          <a href={urls.home}>
            <LogoGray />
          </a>
          <div className="header__right-mobile" onClick={this.handleTap}>
            <div
              className={cx('header_hamburger-menu', {
                open: this.state.isMobileMenuOpen,
              })}
            />
          </div>
        </div>
        <div className={this.mobileTrayClassNames}>
          <div className="header__tray-nav-main">
            <div className="header__tray-menu">
              <a
                href={urls.home}
                className="header__tray-menu-item header__tray-link-text"
              >
                Home
              </a>
              <a
                href={urls.hwh}
                className="header__tray-menu-item header__tray-link-text"
              >
                How we help
              </a>
              <div
                className="header__tray-menu-item"
                onClick={this.createHandleToggle(
                  DROPDOWN_MENU_KEYS.WHO_WE_HELP
                )}
                onMouseEnter={() =>
                  this.setState({
                    openDropdown: DROPDOWN_MENU_KEYS.WHO_WE_HELP,
                  })
                }
                onMouseLeave={() => {
                  this.setState({
                    openDropdown: '',
                  })
                }}
              >
                <div
                  className={cx('header__tray-menu-dropdown', {
                    'open-dropdown':
                      this.state.openDropdown ===
                      DROPDOWN_MENU_KEYS.WHO_WE_HELP,
                  })}
                >
                  <div className="header__tray-link-text header__tray-menu-dropdown-button">
                    Who we help <Chevron className=" chevron-icon" />
                  </div>
                  <div className="header__tray-menu-dropdown-menu">
                    <a
                      href={urls.wwh_c}
                      className="header__tray-link-text header__dropdown-menu-item"
                    >
                      Customers
                    </a>
                    <a
                      href={urls.wwh_p}
                      className="header__tray-link-text header__dropdown-menu-item"
                    >
                      Partners
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="header__tray-menu-item"
                onClick={this.createHandleToggle(DROPDOWN_MENU_KEYS.ABOUT)}
                onMouseEnter={() =>
                  this.setState({
                    openDropdown: DROPDOWN_MENU_KEYS.ABOUT,
                  })
                }
                onMouseLeave={() => {
                  this.setState({
                    openDropdown: '',
                  })
                }}
              >
                <div
                  className={cx('header__tray-menu-dropdown', {
                    'open-dropdown':
                      this.state.openDropdown === DROPDOWN_MENU_KEYS.ABOUT,
                  })}
                >
                  <div className="header__tray-link-text header__tray-menu-dropdown-button">
                    About Us <Chevron className="chevron-icon" />
                  </div>
                  <div className="header__tray-menu-dropdown-menu">
                    <a
                      href={urls.abt_m}
                      className="header__tray-link-text header__dropdown-menu-item"
                    >
                      Our Mission
                    </a>
                    <a
                      href={urls.abt_t}
                      className="header__tray-link-text header__dropdown-menu-item"
                    >
                      The Team
                    </a>
                    <a
                      href={urls.abt_j}
                      className="header__tray-link-text header__dropdown-menu-item"
                    >
                      Join Us
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="header__tray-menu-item"
                onClick={this.createHandleToggle(DROPDOWN_MENU_KEYS.LEARN)}
                onMouseEnter={() =>
                  this.setState({
                    openDropdown: DROPDOWN_MENU_KEYS.LEARN,
                  })
                }
                onMouseLeave={() => {
                  this.setState({
                    openDropdown: '',
                  })
                }}
              >
                <div
                  className={cx('header__tray-menu-dropdown', {
                    'open-dropdown':
                      this.state.openDropdown === DROPDOWN_MENU_KEYS.LEARN,
                  })}
                >
                  <div className="header__tray-link-text header__tray-menu-dropdown-button">
                    Learn <Chevron className="chevron-icon" />
                  </div>
                  <div className="header__tray-menu-dropdown-menu">
                    <a
                      href={urls.lrn_v}
                      className="header__tray-link-text header__dropdown-menu-item"
                    >
                      Vlog
                    </a>
                  </div>
                </div>
              </div>
              <div className="header__tray-menu-item ">
                <a
                  href={urls.hwh}
                  className="header__tray-link-text header__tray-link-item-lock"
                >
                  <Lock className="lock-icon" />
                  <FormattedMessage {...messages.login} />
                </a>
              </div>
              <div className="header__tray-menu-item">
                <button id="open-chat" className="header__tray-contact-us">
                  <FormattedMessage {...messages.cd} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="header__container">
          <div className="header__wrapper ">
            <div className="header__left">
              <a href={urls.home}>
                <LogoGray />
              </a>
            </div>
            <div className="header__menu-container">
              <ul className="header__menu">
                <li className="header__menu-item">
                  <a href={urls.home} className="header__link-text">
                    Home
                  </a>
                </li>
                <li className="header__menu-item">
                  <a href={urls.hwh} className="header__link-text">
                    How We Help
                  </a>
                </li>
                <li className="header__menu-item">
                  <div className="header__dropdown">
                    <div className="header__link-text">
                      Who We Help <Chevron className=" chevron-icon" />
                    </div>
                    <div className="header__dropdown-menu">
                      <a
                        href={urls.wwh_c}
                        className="header__dropdown-menu-item"
                      >
                        Customers
                      </a>

                      <a
                        href={urls.wwh_p}
                        className="header__dropdown-menu-item"
                      >
                        Partners
                      </a>
                    </div>
                  </div>
                </li>
                <li className="header__menu-item">
                  <div className="header__dropdown">
                    <div className="header__link-text">
                      About Us <Chevron className="chevron-icon" />
                    </div>
                    <div className="header__dropdown-menu">
                      <a
                        href={urls.abt_m}
                        className=" header__dropdown-menu-item"
                      >
                        Our Mission
                      </a>
                      <a
                        href={urls.abt_t}
                        className="header__dropdown-menu-item"
                      >
                        The Team
                      </a>
                      <a
                        href={urls.abt_j}
                        className="header__dropdown-menu-item"
                      >
                        Join Us
                      </a>
                    </div>
                  </div>
                </li>
                <li className="header__menu-item">
                  <div className="header__dropdown">
                    <div className="header__link-text">
                      Learn
                      <Chevron className="chevron-icon" />
                    </div>

                    <div className="header__dropdown-menu">
                      <a
                        href={urls.lrn_v}
                        className="header__dropdown-menu-item"
                      >
                        Vlog
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="header__login-button-container">
              <a href={urls.val}>
                <button
                  type="button"
                  className="button header__button header__login-button"
                >
                  <Lock className="lock-icon" />
                  <FormattedMessage {...messages.login} />
                </button>
              </a>
              <button
                id="open-chat"
                type="button"
                className="button header__button"
              >
                <FormattedMessage {...messages.cd} />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const Header = injectIntl(HeaderComponent)
