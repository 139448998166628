import { identity, memoizeWith, range } from 'ramda'

import { shuffle } from './array'

export const COLORS_PALETTE = [
  '#00CB6A',
  '#008080',
  '#00CCFF',
  '#2D7DF0',
  '#2076A5',
  '#004964',
  '#00AAD5',
  '#04B691',
  '#67A5FF',
  '#019B52',
  '#234F8D',
  '#0DE8BB',
  '#002150',
  '#006450',
  '#E7B222',
  '#FFE000',
  '#E1FA09',
]

export const drawColors = memoizeWith(identity, (count) => {
  const duplicates = Math.ceil(count / COLORS_PALETTE.length)
  const randomPalette = shuffle(COLORS_PALETTE)
  let resultPalette = []

  range(0, duplicates).forEach(
    () => (resultPalette = resultPalette.concat(randomPalette))
  )

  return resultPalette.slice(0, count)
})
