import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.65364 7.06885L13.6337 3.08879C14.1221 2.60038 14.1221 1.80851 13.6337 1.3197L12.7491 0.435155C12.2607 -0.0532547 11.4689 -0.0532547 10.9801 0.435155L7 4.41521L3.01994 0.435155C2.53153 -0.0532547 1.73966 -0.0532547 1.25085 0.435155L0.366307 1.3197C-0.122102 1.80811 -0.122102 2.59998 0.366307 3.08879L4.34636 7.06885L0.366307 11.0489C-0.122102 11.5373 -0.122102 12.3292 0.366307 12.818L1.25085 13.7025C1.73926 14.191 2.53153 14.191 3.01994 13.7025L7 9.72249L10.9801 13.7025C11.4685 14.191 12.2607 14.191 12.7491 13.7025L13.6337 12.818C14.1221 12.3296 14.1221 11.5377 13.6337 11.0489L9.65364 7.06885Z"
      fill="#36454F"
    />
  </svg>
)
