import classnames from 'classnames'
import * as React from 'react'
import { AnyObject } from '@voltus/types'
import colors from '../../constants/colors.json'
import { StyledProps } from '../../utils/styledSystem'
import { Box } from '../Box'
import { WithTooltip, WithTooltipProps } from '../Tooltip'

export type IconButtonProps = StyledProps & {
  css?: AnyObject
  className?: string
  children: React.ReactNode
  disabledHoverBg?: string
  isStrokeBased?: boolean
  color?: string
  hoverColor?: string
  tooltip?: WithTooltipProps['content']
  tooltipProps?: Omit<WithTooltipProps, 'content' | 'children'>
}

const MaybeTooltip = ({
  children,
  tooltip,
  ...props
}: Omit<WithTooltipProps, 'children' | 'content'> & {
  tooltip: WithTooltipProps['content']
  children: React.ReactElement
}) => {
  if (!tooltip) {
    return children
  }

  return (
    <WithTooltip
      stopPropOnClick={false}
      preventDefaultOnClick={false}
      openDelay={400}
      content={tooltip}
      enabled={!!tooltip}
      {...props}
    >
      {children}
    </WithTooltip>
  )
}

const IconButton = React.forwardRef<HTMLElement, IconButtonProps>(
  function IconButtonForwardRef(
    {
      isStrokeBased = false,
      className,
      css,
      children,
      width = 33,
      height = 33,
      disabledHoverBg = colors.grays['20'],
      color = colors.textColor.link,
      hoverColor = colors.grays['10'],
      tooltip,
      tooltipProps = {},
      ...props
    },
    ref
  ) {
    const styles = isStrokeBased
      ? {
          outline: 'none',
          color,
          '&:disabled,&:disabled:hover,&:disabled:active,&:disabled:focus-visible':
            {
              color: disabledHoverBg,
              cursor: 'default',
            },
          '&:hover,&:focus-visible': {
            backgroundColor: hoverColor,
            borderRadius: '50%',
          },
        }
      : {
          '*': {
            outline: 'none',
            fill: color,
          },
          '&:disabled,&:disabled:hover,&:disabled:active,&:disabled:focus-visible':
            {
              cursor: 'default',
              '*': {
                fill: hoverColor,
              },
            },
          '&:disabled:hover,&:disabled:active,&:disabled:focus-visible': {
            backgroundColor: disabledHoverBg,
          },
          '&:hover,&:focus-visible': {
            backgroundColor: hoverColor,
            borderRadius: '50%',
          },
        }

    const [ctx, setCtx] = React.useState({ hovering: false })
    const [hovering, setHovering] = React.useState(false)
    React.useEffect(() => {
      setCtx({
        hovering,
      })
    }, [hovering])

    return (
      <IconButtonContext.Provider value={ctx}>
        <Box ref={ref}>
          <MaybeTooltip tooltip={tooltip} {...tooltipProps}>
            <Box
              as="button"
              border="none"
              cursor="pointer"
              background="none"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onMouseEnter={() => {
                setHovering(true)
              }}
              onMouseLeave={() => {
                setHovering(false)
              }}
              width={width}
              height={height}
              p={0}
              className={classnames(className)}
              css={{
                transition: 'color 0.2s ease',
                ...styles,
                ...css,
              }}
              {...props}
            >
              {children}
            </Box>
          </MaybeTooltip>
        </Box>
      </IconButtonContext.Provider>
    )
  }
)

export const IconButtonContext = React.createContext({ hovering: false })

export default IconButton

export { IconButton }
