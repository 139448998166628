import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="5" width="5.53846" height="6.65" rx="1" fill="#39454E" />
    <rect x="3" y="12.35" width="5.53846" height="6.65" rx="1" fill="#39454E" />
    <rect
      x="9.23071"
      y="12.35"
      width="5.53846"
      height="6.65"
      rx="1"
      fill="#39454E"
    />
    <rect
      x="15.4616"
      y="12.35"
      width="5.53846"
      height="6.65"
      rx="1"
      fill="#39454E"
    />
    <rect
      x="15.4616"
      y="5"
      width="5.53846"
      height="6.65"
      rx="1"
      fill="#39454E"
    />
    <rect
      x="9.23071"
      y="5"
      width="5.53846"
      height="6.65"
      rx="1"
      fill="#39454E"
    />
  </svg>
)
