import { defineMessages } from 'react-intl'

export default defineMessages({
  defaultError: {
    id: 'postcodesFile.defaultError',
    defaultMessage: 'Unknown error occured, try again.',
  },
  invalidFileError: {
    id: 'postcodesFile.invalidFileError',
    defaultMessage:
      '<strong>There was a problem processing your upload.</strong><br />' +
      'Make sure your file is CSV format and has 10,000 lines or less.',
  },
  tooManyFilesError: {
    id: 'csvFileUploader.tooManyFilesError',
    defaultMessage:
      '<strong>There was a problem processing your upload.</strong><br />' +
      'Too many files dropped, only 1 allowed.',
  },
})
