import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0752 6.47595V10.8869H1.81964V2.06437H6.44707V0.791107H1.81964C1.07424 0.791107 0.483887 1.38353 0.483887 2.06437V10.8869C0.483887 11.5677 1.07424 12.1602 1.81964 12.1602H11.0752C11.7894 12.1602 12.4109 11.5677 12.4109 10.8869V6.47595H11.0752ZM12.4109 0.791739H7.78348L7.78282 0.791107V2.06437H10.1436L3.65231 8.25195L4.5839 9.13995L11.0752 2.95237V5.20269H12.4109V0.791739Z"
      fill="#000000"
    />
  </svg>
)
