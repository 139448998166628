import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'

import { renderWhenNotNil } from '../../utils/rendering'
import messages from './navigationButtons.messages'

export class NavigationButtons extends PureComponent {
  static propTypes = {
    isSubmitting: PropTypes.bool,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    isSubmitting: false,
  }

  renderBackButton = renderWhenNotNil(() => (
    <button
      type="button"
      className="button button--large navButtons__back-button"
      onClick={this.props.onBack}
    >
      <FormattedMessage {...messages.back} />
    </button>
  ))

  renderNextButton = renderWhenNotNil(() => (
    <button
      type="button"
      className="button button--filled button--large navButtons__next-button"
      onClick={this.props.onNext}
    >
      <FormattedMessage {...messages.next} />
    </button>
  ))

  renderSubmitButton = renderWhenNotNil(() => (
    <button
      type="button"
      className="button button--filled button--large navButtons__submit-button"
      onClick={this.props.onSubmit}
      disabled={this.props.isSubmitting}
    >
      <FormattedMessage {...messages.submit} />
    </button>
  ))

  render() {
    return (
      <div className="navButtons__container">
        <div className="navButtons__back-button-container">
          {this.renderBackButton(this.props.onBack)}
        </div>

        <div className="navButtons__next-button-container">
          {this.renderNextButton(this.props.onNext)}
          {this.renderSubmitButton(this.props.onSubmit)}
        </div>
      </div>
    )
  }
}

export const NavButtons = injectIntl(NavigationButtons)
