import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'
import { injectIntl, FormattedMessage } from 'react-intl'

import messages from './notFound.messages'

export class NotFoundComponent extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div className="not-found__container">
        <Helmet title={this.props.intl.formatMessage(messages.pageTitle)} />

        <h1 className="not-found__title">
          <FormattedMessage {...messages.title} />
        </h1>
      </div>
    )
  }
}

export const NotFound = injectIntl(NotFoundComponent)
