import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="#007E56"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.5054 10.778L13.5001 9.77803C13.6555 9.62178 13.9259 9.73116 13.9259 9.95615V14.4999C13.9259 15.328 13.2576 15.9999 12.4339 15.9999H1.49206C0.66832 15.9999 0 15.328 0 14.4999V3.4999C0 2.67178 0.66832 1.9999 1.49206 1.9999H9.99372C10.2144 1.9999 10.3263 2.26865 10.1709 2.42803L9.17619 3.42803C9.12956 3.4749 9.06739 3.4999 8.99901 3.4999H1.49206V14.4999H12.4339V10.953C12.4339 10.8874 12.4587 10.8249 12.5054 10.778ZM17.3732 4.47178L9.21038 12.678L6.40033 12.9905C5.58591 13.0812 4.89272 12.3905 4.98287 11.5655L5.29372 8.74053L13.4565 0.534277C14.1684 -0.181348 15.3185 -0.181348 16.0272 0.534277L17.3701 1.88428C18.0819 2.5999 18.0819 3.75928 17.3732 4.47178ZM14.302 5.4374L12.496 3.62178L6.7205 9.43115L6.49358 11.4718L8.52341 11.2437L14.302 5.4374ZM16.3163 2.94678L14.9735 1.59678C14.846 1.46865 14.6378 1.46865 14.5134 1.59678L13.5529 2.5624L15.3589 4.37803L16.3194 3.4124C16.4438 3.28115 16.4438 3.0749 16.3163 2.94678Z" />
  </svg>
)
