import classnames from 'classnames'
import PropTypes from 'prop-types'
import { omit, has } from 'ramda'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import errorIcon from '../../../../images/sprites/errorIcon.png'
import {
  renderWhenNotNil,
  renderWhenTrue,
  renderWhenTrueOtherwise,
} from '../../../utils/rendering'
import formsMessages from '../forms.messages'

export class TextInput extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    field: PropTypes.object,
    form: PropTypes.object,
    className: PropTypes.string,
    showOptional: PropTypes.bool,
    label: PropTypes.node,
    name: PropTypes.string,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    type: 'text',
    className: '',
    showOptional: false,
    inline: false,
    disabled: false,
  }

  get error() {
    const {
      field: { name },
      form: { errors = {} },
    } = this.props
    return errors[name]
  }

  get touched() {
    const {
      field: { name },
      form: { touched = {} },
    } = this.props

    return touched[name]
  }

  get shouldShowError() {
    return this.error && this.touched
  }

  renderOptionalText = renderWhenTrue(() => (
    <span className="input__optional">
      <FormattedMessage {...formsMessages.optional} />
    </span>
  ))

  renderLabel = renderWhenNotNil(() => {
    const { name, label, showOptional } = this.props
    return (
      <label htmlFor={name} className="input__label">
        {label}
        {this.renderOptionalText(showOptional)}
      </label>
    )
  })

  renderErrorMessage = renderWhenTrue(() => {
    return (
      <div className="input__error-message">
        {renderWhenTrueOtherwise(
          () => <FormattedMessage {...this.error} />,
          () => this.error
        )(has('id', this.error))}
      </div>
    )
  })

  render() {
    const { className, field, inline, label, disabled, ...props } = this.props
    const inputProps = omit(
      ['showOptional', 'form', 'errorMessages', 'intl'],
      props
    )

    return (
      <div
        className={classnames('input__container', 'input__container--text', {
          'input__container--inline': !!inline,
        })}
      >
        {this.renderLabel(label)}
        <div className={classnames('input-text__wrapper', className)}>
          <input
            className={classnames('input-text', className, {
              'input-text--error': this.shouldShowError,
              'input-text--disabled': !!disabled,
            })}
            {...field}
            {...inputProps}
            value={field.value || ''}
            disabled={!!disabled}
          />
          {this.shouldShowError ? (
            <img className="input-text--error-icon" src={errorIcon} />
          ) : null}
        </div>
        {this.renderErrorMessage(this.shouldShowError)}
      </div>
    )
  }
}
