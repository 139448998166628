/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export const POST_CODES_REQUIRED_ERROR = 'postCodesRequiredError'

export const messages = defineMessages({
  addAnother: {
    id: 'postCodesForm.addAnother',
    defaultMessage: 'Add another?',
  },
  [POST_CODES_REQUIRED_ERROR]: {
    id: 'postCodesForm.postCodesRequiredError',
    defaultMessage: 'Provide at least one correct post code',
  },
})
