import numeral from 'numeral'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Box, WithTooltip } from '@voltus/core-components'
import {
  DISPATCH_FREQUENCY_RANGE_VALUES,
  RESPONSE_TIME_RANGE_VALUES,
} from '../../../../modules/reports/reports.constants'
import { RangeValue } from '../../../../shared/components/rangeValue'
import InfoButton from './reportData.InfoButtons_svg'
import messages from './reportData.messages'

export class ReportDataComponent extends PureComponent {
  static propTypes = {
    totalEarnings: PropTypes.number.isRequired,
    totalPrograms: PropTypes.number.isRequired,
    totalSites: PropTypes.number.isRequired,
    frequency: PropTypes.number.isRequired,
    responseTime: PropTypes.number.isRequired,
    setFrequency: PropTypes.func.isRequired,
    setResponseTime: PropTypes.func.isRequired,
  }

  get totalEarnings() {
    return numeral(this.props.totalEarnings).format('($0.[0]a)').toUpperCase()
  }

  handleFrequencyChange = this.props.setFrequency

  handleResponseTimeChange = this.props.setResponseTime

  render() {
    return (
      <div className="report-data__container">
        <div className="report-data-tile-row first">
          <div className="report-data-tile tile-earning-potentials">
            <div className="report-data-tile-moreInfo-modal">
              <h3 className="report-data-tile__title row-1 ">
                <FormattedMessage {...messages.totalEarnings} />
              </h3>
              <WithTooltip
                maxWidth={275}
                wrap="wrap"
                anchorPoint="top-center"
                contentAlign="left"
                hideArrow="true"
                css={{ top: '10px' }}
                content={() => (
                  <Box
                    py={2}
                    px={2}
                    bg="white"
                    className="report-data-tile-moreInfo-hint-content"
                  >
                    <FormattedMessage {...messages.totalHint} />
                  </Box>
                )}
              >
                <div className="report-data-tile-moreInfo-hint">
                  <InfoButton className="report-data-tile-info-btn" />
                </div>
              </WithTooltip>
            </div>

            <div className="report-data-tile__value">{this.totalEarnings}</div>
          </div>
          <div className="report-data-tile tile-programs">
            <h3 className="report-data-tile__title row-1">
              <FormattedMessage {...messages.totalPrograms} />
            </h3>
            <div className="report-data-tile__value">
              {this.props.totalPrograms}
            </div>
          </div>
          <div className="report-data-tile tile-sites">
            <h3 className="report-data-tile__title row-1">
              <FormattedMessage {...messages.totalSites} />
            </h3>
            <div className="report-data-tile__value">
              {this.props.totalSites}
            </div>
          </div>
        </div>

        <div className="report-data-tile-row">
          <div className="report-data-tile tile-dispatch">
            <h3 className="report-data-tile__title row-2">
              <FormattedMessage {...messages.dispatchFrequency} />
            </h3>
            <h4 className="report-data-tile__subtitle">
              <FormattedMessage {...messages.eventsPerYear} />
            </h4>
            <RangeValue
              className="report-data-tile__range-value"
              values={DISPATCH_FREQUENCY_RANGE_VALUES}
              value={this.props.frequency}
              onChange={this.handleFrequencyChange}
            />
          </div>

          <div className="report-data-tile tile-response">
            <h3 className="report-data-tile__title row-2">
              <FormattedMessage {...messages.responseTime} />
            </h3>
            <h4 className="report-data-tile__subtitle">
              <FormattedMessage {...messages.energyConsumption} />
            </h4>
            <RangeValue
              className="report-data-tile__range-value"
              values={RESPONSE_TIME_RANGE_VALUES}
              value={this.props.responseTime}
              onChange={this.handleResponseTimeChange}
            />
          </div>
        </div>
      </div>
    )
  }
}

export const ReportData = injectIntl(ReportDataComponent)
