import { ifElse, has, prop, always } from 'ramda'

export const getErrorMessage = (
  errorKey,
  messages,
  defaultKey = 'defaultError'
) => ifElse(has(errorKey), prop(errorKey), prop(defaultKey))(messages)

export const getErrorMessageKey = (
  errorKey,
  messages,
  defaultKey = 'defaultError'
) => ifElse(has(errorKey), always(errorKey), always(defaultKey))(messages)
