/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'siteInfo.pageTitle',
    defaultMessage: 'Site info',
  },
  formTitle: {
    id: 'postCodesForm.formTitle',
    defaultMessage: 'Find out how much you can earn.',
  },
  formSubtitle: {
    id: 'postCodesForm.formSubtitle',
    defaultMessage:
      "Provide a post code for each of your sites then sit back and we'll do the hard work of identifying all of the programs you qualify for.",
  },
  fileInfoHeader: {
    id: 'postCodesForm.formHeader',
    defaultMessage: 'Submit Post Codes Individually',
  },
  fileInfoTitle: {
    id: 'postCodesForm.fileInfoTitle',
    defaultMessage: "Let's make sure we're in sync.",
  },
  fileInfoSubtitle: {
    id: 'postCodesForm.fileInfoSubtitle',
    defaultMessage:
      'Import a CSV file that contains the post code for each of your sites.',
  },
})
