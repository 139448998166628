/**
 * Generic function for error reporting.
 * Use it in all sagas
 * @param error
 * @returns {null}
 */
export default (error) => {
  console.error(error) //eslint-disable-line
  return null
}
