import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4875 10.4333C17.5773 8.88182 17.1829 7.34072 16.3588 6.02309C15.5347 4.70546 14.3216 3.67636 12.8873 3.07807C11.453 2.47978 9.8682 2.34184 8.35206 2.68332C6.83593 3.0248 5.46332 3.82883 4.42397 4.98427C3.38463 6.13971 2.72989 7.58948 2.55027 9.13318C2.37065 10.6769 2.67501 12.2383 3.42128 13.6015C4.16756 14.9647 5.31888 16.0624 6.7161 16.7429C8.11332 17.4234 9.68743 17.653 11.2208 17.4M2.99998 7.5H17M2.99998 12.5H12.0833M9.58336 2.5C8.17948 4.74968 7.4352 7.34822 7.4352 10C7.4352 12.6518 8.17948 15.2503 9.58336 17.5M10.4167 2.5C12.3442 5.58833 12.975 9.04667 12.3083 12.5M15.8333 13.3333L14.1667 15.8333H17.5L15.8333 18.3333"
      stroke="#657385"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
