import classnames from 'classnames'
import { Field, FieldArray } from 'formik'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import React, { PureComponent } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'

import { getFormFieldErrorMessage } from '../../../../shared/components/forms/forms.helpers'
import { KEY_ENTER } from '../../../../shared/utils/keys'
import { renderWhenTrue } from '../../../../shared/utils/rendering'
import { messages, POST_CODES_REQUIRED_ERROR } from './postCodesForm.messages'

export class PostCodesFormComponent extends PureComponent {
  static propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  }

  get errorMessages() {
    return {
      [POST_CODES_REQUIRED_ERROR]: messages[POST_CODES_REQUIRED_ERROR],
    }
  }

  getValueById = (id) => this.props.values.postCodes[id]

  addNewValue = (arrayHelpers, value = '') => {
    arrayHelpers.push(value)
  }

  shouldRenderAddAnotherButton = (id) =>
    !!this.props.values.postCodes[id] &&
    id === this.props.values.postCodes.length - 1

  shouldShowError = () => {
    const { touched, errors } = this.props
    return !!touched.postCodes && !!errors.postCodes
  }

  handleChange = (id) => (event) => {
    const updatedPostCodes = this.props.values.postCodes.slice(0)
    updatedPostCodes[id] = event.target.value.replace(/\s/g, '')
    this.props.setFieldValue('postCodes', updatedPostCodes)
  }

  handleBlur = (arrayHelpers, id) => () => {
    const { form, name } = arrayHelpers
    form.setFieldTouched(`${name}.${id}`, true)

    const value = this.getValueById(id)
    if (this.props.values.postCodes.length > 1 && isEmpty(value)) {
      arrayHelpers.remove(id)
    }
  }

  handleKeyPress = (arrayHelpers, id) => (event) => {
    const input = event.target
    const { postCodes } = this.props.values
    if (event.key === KEY_ENTER) {
      if (id === postCodes.length - 1 && input.value !== '') {
        this.addNewValue(arrayHelpers)
      }
      input.blur()
    }
  }

  handleAddAnotherClick = (arrayHelpers) => () => {
    this.addNewValue(arrayHelpers)
  }

  renderAddAnotherButton = (arrayHelpers) =>
    renderWhenTrue(() => (
      <button
        type="button"
        className="button--filled post-codes-form__add-another"
        onClick={this.handleAddAnotherClick(arrayHelpers)}
      >
        <span className="post-codes-form__add-another-text">
          <FormattedMessage {...messages.addAnother} />
        </span>
      </button>
    ))

  renderError = renderWhenTrue(() => {
    const { intl, errors } = this.props
    return (
      <div className="post-codes-form__error input__error-message">
        {getFormFieldErrorMessage(intl, this.errorMessages, errors.postCodes)}
      </div>
    )
  })

  renderPostcodesInputs = (arrayHelpers) => {
    const { values } = this.props
    return values.postCodes.map((code, id) => (
      <div key={id} className="post-codes-form__input-container">
        <Field
          type="tel"
          inputMode="numeric"
          name={`postCodes.${id}`}
          onChange={this.handleChange(id)}
          onKeyPress={this.handleKeyPress(arrayHelpers, id)}
          onBlur={this.handleBlur(arrayHelpers, id)}
          className={classnames('post-codes-form__input', {
            'post-codes-form__input--with-button':
              this.shouldRenderAddAnotherButton(id),
          })}
          autoFocus={id > 0}
        />
        {this.renderAddAnotherButton(arrayHelpers)(
          this.shouldRenderAddAnotherButton(id)
        )}
      </div>
    ))
  }

  render() {
    return (
      <div className="post-codes-form">
        <div className="post-codes-form__inputs-wrapper">
          <FieldArray name="postCodes" render={this.renderPostcodesInputs} />
        </div>

        {this.renderError(this.shouldShowError())}
      </div>
    )
  }
}

export const PostCodesForm = injectIntl(PostCodesFormComponent)
