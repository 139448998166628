import React from 'react'
import createSVGComponent from './createSVGComponent'

export default createSVGComponent(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="refresh">
      <path
        id="Vector"
        d="M20 11C19.7554 9.24023 18.9391 7.60967 17.6766 6.35951C16.4142 5.10934 14.7758 4.30893 13.0137 4.08156C11.2516 3.8542 9.46362 4.21249 7.9252 5.10126C6.38678 5.99002 5.18325 7.35995 4.5 9.00001M4 5.00001V9.00001H8M4 13C4.24456 14.7598 5.06093 16.3903 6.32336 17.6405C7.58579 18.8907 9.22424 19.6911 10.9863 19.9184C12.7484 20.1458 14.5364 19.7875 16.0748 18.8988C17.6132 18.01 18.8168 16.6401 19.5 15M20 19V15H16"
        stroke="#36454F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
