const KEYS = {
  ESCAPE: {
    keyCode: 27,
    key: 'Escape',
    code: 'Escape',
  },
  TAB: {
    keyCode: 9,
    key: 'Tab',
    code: 'Tab',
  },
  ENTER: {
    keyCode: 13,
    key: 'Enter',
    code: 'Enter',
  },
  ARROW_LEFT: {
    keyCode: 37,
    key: 'ArrowLeft',
    code: 'ArrowLeft',
  },
  ARROW_RIGHT: {
    keyCode: 39,
    key: 'ArrowRight',
    code: 'ArrowRight',
  },
  ARROW_UP: {
    keyCode: 38,
    key: 'ArrowUp',
    code: 'ArrowUp',
  },
  ARROW_DOWN: {
    keyCode: 40,
    key: 'ArrowDown',
    code: 'ArrowDown',
  },
  SPACE: {
    keyCode: 32,
    key: ' ',
    code: 'Space',
  },
}

const makeKeyboard = () => {
  const isKey = (evt, keyConfig) => {
    return Object.entries(keyConfig).some(([key, value]) => {
      if (evt[key] === value) {
        return true
      }
    })
  }

  return {
    KEYS,
    isKey,
    isEscapeKey: (evt) => isKey(evt, KEYS.ESCAPE),
    isEnterKey: (evt) => isKey(evt, KEYS.ENTER),
    isSpaceKey: (evt) => isKey(evt, KEYS.SPACE),
    isArrowDownKey: (evt) => isKey(evt, KEYS.ARROW_DOWN),
    isArrowUpKey: (evt) => isKey(evt, KEYS.ARROW_UP),
    isArrowKeyRight: (evt) => isKey(evt, KEYS.ARROW_RIGHT),
    isArrowKeyLeft: (evt) => isKey(evt, KEYS.ARROW_LEFT),
  }
}

export default makeKeyboard()
