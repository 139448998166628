import { defineMessages } from 'react-intl'

export const dispatchFrequencyMessages = defineMessages({
  frequencyFirstLabel: {
    id: 'report.dispatchFrequency.frequencyFirstLabel',
    defaultMessage: '< 5',
  },
  frequencyFirstTitle: {
    id: 'report.dispatchFrequency.frequencyFirstTitle',
    defaultMessage: 'Infrequent',
  },
  frequencySecondLabel: {
    id: 'report.dispatchFrequency.frequencySecondLabel',
    defaultMessage: '5-10',
  },
  frequencySecondTitle: {
    id: 'report.dispatchFrequency.frequencySecondTitle',
    defaultMessage: 'Moderate',
  },
  frequencyThirdLabel: {
    id: 'report.dispatchFrequency.frequencyThirdLabel',
    defaultMessage: '> 10',
  },
  frequencyThirdTitle: {
    id: 'report.dispatchFrequency.frequencyThirdTitle',
    defaultMessage: 'Frequent',
  },
})

export const responseTimeMessages = defineMessages({
  responseFirstLabel: {
    id: 'report.responseTime.responseFirstLabel',
    defaultMessage: '> 4hrs',
  },
  responseFirstTitle: {
    id: 'report.responseTime.responseFirstTitle',
    defaultMessage: 'Long',
  },
  responseSecondLabel: {
    id: 'report.responseTime.responseSecondLabel',
    defaultMessage: '1-4hrs',
  },
  responseSecondTitle: {
    id: 'report.responseTime.responseSecondTitle',
    defaultMessage: 'Moderate',
  },
  responseThirdLabel: {
    id: 'report.responseTime.responseThirdLabel',
    defaultMessage: '< 1hr',
  },
  responseThirdTitle: {
    id: 'report.responseTime.responseThirdTitle',
    defaultMessage: 'Fast',
  },
})

export const saveReportErrorMessages = defineMessages({
  defaultError: {
    id: 'report.saveErrorMessages.defaultError',
    defaultMessage:
      'Oops... something went wrong on our end. Mind resubmitting the form?',
  },
})
