import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#FD6042" />
    <path d="M20 9.03226L29.6774 25.8065H10.3226L20 9.03226Z" fill="white" />
    <line
      x1="19.8549"
      y1="16.7742"
      x2="19.8549"
      y2="20.6452"
      stroke="#FD6042"
    />
    <line
      x1="19.8549"
      y1="21.9355"
      x2="19.8549"
      y2="23.2258"
      stroke="#FD6042"
    />
  </svg>
)
