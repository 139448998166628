import { List } from 'immutable'
import { pipe, when, mergeRight, is, has } from 'ramda'

import reportError from '../../utils/reportError'
import defaultMessages from './forms.messages'

export const getFormFieldErrorMessage = (intl, customMessages, error) => {
  if (!error) {
    return ''
  }

  try {
    const messages = mergeRight(defaultMessages, customMessages)
    const errorMessage = pipe(
      when(is(List), (errors) => errors.first()),
      when(is(Array), ([firstError]) => firstError)
    )(error)
    if (has(errorMessage, messages)) {
      return intl.formatMessage(messages[errorMessage])
    }
    return errorMessage
  } catch (e) {
    reportError(e)
  }
  return ''
}
