/* eslint-disable max-len */
import React from 'react'

const SVG = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="130px"
    height="40px"
    viewBox="0 0 663.53 195.75"
  >
    <path
      fill="#404041"
      d="M593.26,178c9.26,0,18.53,0,27.79,0a47,47,0,0,0,13.88-1.93,20.9,20.9,0,0,0,7.12-3.71c4.53-3.77,5.76-8.81,5.47-14.41-.36-7.08-3.87-12.26-9.75-16-5.51-3.47-11.66-5.33-17.88-7-8.81-2.35-17.79-4-26.57-6.49-7.4-2.09-14.56-4.72-21-9.11-9.55-6.52-13.26-15.71-12.67-26.95.4-7.58,2.58-14.56,7.74-20.25a34.29,34.29,0,0,1,20.3-10.91,54,54,0,0,1,8.8-.63q28.49,0,57,0c1.11,0,1.48.23,1.46,1.41-.08,4.19-.09,8.38,0,12.57,0,1.21-.39,1.38-1.46,1.38q-28.91,0-57.82,0a19,19,0,0,0-12.9,4.48c-7.76,6.85-8.59,20.07.52,26.68a44.25,44.25,0,0,0,13.37,6.15c8.22,2.5,16.67,4,25,6,8,1.9,15.91,4,23.29,7.75a38.24,38.24,0,0,1,12.32,9.31,29.84,29.84,0,0,1,6.92,16.34,39,39,0,0,1-2.11,19c-3.29,8.32-9.48,13.72-17.6,17.09A60.79,60.79,0,0,1,625.09,193c-8.42.58-16.85.26-25.28.29-12.92,0-25.84,0-38.76,0-.94,0-1.27-.2-1.26-1.21q.09-6.48,0-12.95c0-1,.28-1.16,1.19-1.15Q577.12,178,593.26,178Z"
      transform="translate(-0.94 -0.24)"
    />
    <path
      fill="#404041"
      d="M432,103.82q0-20.91,0-41.83c0-1.1.27-1.38,1.38-1.37q6.54.1,13.08,0c1.11,0,1.43.25,1.43,1.41,0,26.19-.11,52.37,0,78.55.05,10.71,3.29,20.4,10.64,28.43a34.91,34.91,0,0,0,22.35,11.35c9.78,1.14,18.84-.84,26.83-6.74,8.28-6.1,12.91-14.5,14.91-24.46a49.16,49.16,0,0,0,.88-9.76q0-38.59,0-77.19c0-1.19.19-1.63,1.53-1.6,4.4.1,8.8.07,13.21,0,1,0,1.14.28,1.14,1.18,0,27,.29,54-.12,81-.29,19-8,34.44-24.26,45-8.57,5.56-18.15,7.95-28.32,8.11a53.8,53.8,0,0,1-28.6-7.11c-13-7.59-20.87-19-24.41-33.49A61.39,61.39,0,0,1,432,140.67Z"
      transform="translate(-0.94 -0.24)"
    />
    <path
      fill="#404041"
      d="M65.49,178.63l4.16-10.53c3.85-9.69,7.52-19.45,11.57-29.06,7.9-18.79,15.13-37.85,22.69-56.78,2.73-6.84,5.44-13.69,8.07-20.56a1.45,1.45,0,0,1,1.64-1.07q7.66.06,15.33,0c1,0,1.36.06.89,1.23-2.42,5.94-4.76,11.91-7.08,17.89-13.38,34.4-27.05,68.69-40.63,103-.77,1.93-1.62,3.85-2.52,5.73-2,4.22-5.49,6.42-10,7.15a24.56,24.56,0,0,1-9.88-.33,12.49,12.49,0,0,1-9.07-7.93Q40.35,161.23,30,135.08T9.47,83C6.66,75.88,3.91,68.74,1.11,61.62c-.29-.75-.27-1,.63-1,5.32,0,10.64,0,16,0,.89,0,1.1.47,1.35,1.11q8.06,20.45,16.11,40.87,13.44,33.95,26.92,67.86c.91,2.31,1.85,4.61,2.79,6.91C65,177.74,65.22,178.09,65.49,178.63Z"
      transform="translate(-0.94 -0.24)"
    />
    <path
      fill="#2274a2"
      d="M228.71,172.81c-6.29,7.14-13.12,13.64-21.46,18.37-4.81,2.73-9.91,4.66-15.54,4.69s-10.66-1.84-15.46-4.51c-7.67-4.26-14.11-10-19.95-16.5a140.23,140.23,0,0,1-25.6-39.6c-4.21-10-4.64-20.29-.24-30.45,3.48-8,9.43-13.83,16.75-18.37A77.16,77.16,0,0,1,156,82c9.4-4.3,19.39-5.7,29.56-6.32a59.5,59.5,0,0,1,6-.16c.12.76-.54,1.08-.91,1.53-3,3.56-5.61,7.38-8.34,11.13a4.67,4.67,0,0,1-1.22,1.4,91.84,91.84,0,0,0-11.76,2.06,49.44,49.44,0,0,0-18.32,8.42c-5.07,3.92-8.56,8.86-9.79,15.25a25.65,25.65,0,0,0,1.65,14.39,108.06,108.06,0,0,0,9.47,17.92,124.22,124.22,0,0,0,12.33,16.54c5.56,6.27,11.65,11.92,19.05,16,5.56,3.1,11,2.9,16.41-.31a63.26,63.26,0,0,0,11.25-8.4,68,68,0,0,0,6.79-6.68,45.93,45.93,0,0,0,4.48-5.44,142.31,142.31,0,0,0,12.33-18.53c2.4-4.32,4.84-8.63,6.23-13.41,3.52-12.08-.69-21.74-10.57-28.57-6.66-4.6-14.2-6.94-22.08-8.38-3.35-.61-6.75-.84-10.12-1.25a2,2,0,0,1-.73-.17c-.44-.46,0-.75.25-1,1.67-2,3.36-4,5.09-5.91a32.12,32.12,0,0,1,4.77-5,1.8,1.8,0,0,1,1.08-.56,64.3,64.3,0,0,1,16,4.07A68.3,68.3,0,0,1,241,89.56c6.36,4.89,11.1,10.91,13.57,18.58a36.15,36.15,0,0,1,1.64,9c.45,7.61-1.39,14.67-4.57,21.51a110.84,110.84,0,0,1-6,11.3c-2,3.21-4.08,6.35-6.23,9.44-2.47,3.2-4.68,6.61-7.32,9.68C231,170.36,230.14,171.85,228.71,172.81Z"
      transform="translate(-0.94 -0.24)"
    />
    <path
      fill="#8ac43f"
      d="M222.69,159.68a63.3,63.3,0,0,1-7.28,8.4,21.81,21.81,0,0,1-3.79,3.52,5.75,5.75,0,0,1-2.7-.2c-9.58-1.72-18.65-4.82-26.78-10.27a40.77,40.77,0,0,1-14-15.36A35.05,35.05,0,0,1,164.28,131c-.32-8.11,1.8-15.57,5.39-22.74a185.47,185.47,0,0,1,9.7-17,6.53,6.53,0,0,1,1.68-1.94c3.38-4.68,6.64-9.44,10.44-13.8a109.78,109.78,0,0,1,12.67-12.29A60.28,60.28,0,0,1,217,55.18c8.3-3.76,16.43-3.33,24.6.55a67.37,67.37,0,0,1,17.33,12.39,97.71,97.71,0,0,1,10.87,12c7.91,10.43,15.15,21.3,20.1,33.48,4,9.82,4.49,19.83.35,29.78-3.38,8.11-9.28,14-16.64,18.63-8,5-16.81,7.8-26,9.35a104.22,104.22,0,0,1-18.83,1.49c3.51-4.33,7-8.66,10.18-13.27.06-.1.19-.15.29-.22,1.67-.75,3.49-.67,5.24-1,8.07-1.32,15.79-3.59,22.71-8.09a27.67,27.67,0,0,0,10.62-11.75c2.6-5.77,2.51-11.66.62-17.61s-5.06-11.45-8.25-16.84A129.63,129.63,0,0,0,255.24,83.7a64.13,64.13,0,0,0-19.19-15.82c-5.15-2.79-10.12-2.52-15.19.21a64,64,0,0,0-11,7.87c-.37.31-.66.77-1.22.78-4.17,3.59-7.48,8-11,12.14.09.58-.37.89-.66,1.27a141,141,0,0,0-14.86,23.67,36.28,36.28,0,0,0-4,12,22.86,22.86,0,0,0,5.88,19,39.6,39.6,0,0,0,16.44,10.4,76.61,76.61,0,0,0,18.7,4C220.28,159.24,221.57,159,222.69,159.68Z"
      transform="translate(-0.94 -0.24)"
    />
    <path
      fill="#404041"
      d="M357.3,91.91q0-32.31,0-64.62c0-1.15.31-1.41,1.42-1.39,4.37.06,8.73.07,13.09,0,1.21,0,1.38.4,1.38,1.46,0,10.58,0,21.16-.05,31.74,0,1.21.23,1.6,1.54,1.59,14.12,0,28.25,0,42.37,0,1.06,0,1.4.19,1.38,1.33-.08,4.27-.06,8.55,0,12.82,0,1-.27,1.21-1.23,1.21-14.21,0-28.41,0-42.62,0-1.1,0-1.44.22-1.44,1.39q.06,38.84,0,77.68A26.32,26.32,0,0,0,376,167.86c3.36,6.17,8.77,9.23,15.58,9.93,5.59.57,11.21.14,16.81.22q4.49.06,9,0c.83,0,1,.23,1,1,0,4.44,0,8.88,0,13.32,0,.64-.07,1-.85,1-7.93-.06-15.87.13-23.79-.16-8.46-.3-16.38-2.56-23.17-7.88S360,172.76,358.3,164.41a53.19,53.19,0,0,1-1-10.75Z"
      transform="translate(-0.94 -0.24)"
    />
    <path
      fill="#404041"
      d="M306.5,96.77q0-47.5,0-95c0-1.28.33-1.56,1.56-1.54,4.45.08,8.89.06,13.34,0,.83,0,1.13.24,1,1a4.28,4.28,0,0,0,0,.62V191.65c0,1.64,0,1.64-1.66,1.64-4.32,0-8.64,0-13,0-1.05,0-1.28-.29-1.28-1.31Q306.52,144.39,306.5,96.77Z"
      transform="translate(-0.94 -0.24)"
    />
    <path
      fill="none"
      stroke="#404041"
      strokeMiterlimit="10"
      strokeWidth="5px"
      d="M210.18,107.55a15.32,15.32,0,1,1-15.39,15A15.29,15.29,0,0,1,210.18,107.55Z"
      transform="translate(-0.94 -0.24)"
    />
  </svg>
)
export default SVG
