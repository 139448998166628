/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'contact.pageTitle',
    defaultMessage: 'Talk to a Voltan',
  },
  title: {
    id: 'contact.title',
    defaultMessage: 'Talk to a Voltan.',
  },
  description: {
    id: 'contact.description',
    defaultMessage:
      'Fill out the form below and a Voltan will be in touch to discuss how we can help you use less energy and get more cash.',
  },
  successDescription: {
    id: 'contact.successDescription',
    defaultMessage:
      'We’ll be in touch soon to evaluate how much you stand to earn from your Demand Response participation.<br />' +
      '<br />' +
      'Return to CashDash to learn more about how you can start earning cash and use less energy.',
  },
  successAlert: {
    id: 'contact.successAlert',
    defaultMessage: "We've received your request.",
  },
  thankYou: {
    id: 'contact.thankYou',
    defaultMessage: 'Thank You!',
  },
  buttonText: {
    id: 'contact.buttonText',
    defaultMessage: 'Take me to the CashDash',
  },
  buttonLink: {
    id: 'contact.buttonLink',
    defaultMessage: 'https://www.voltus.co/',
  },
  submit: {
    id: 'contact.submit',
    defaultMessage: 'Submit',
  },
})
