import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { bindActionCreators } from 'redux'

import { createStructuredSelector } from 'reselect'

import {
  selectReportSaveSuccess,
  selectReportsHasData,
  selectReportsIsLoadingData,
} from '../../modules/reports'
import { Home } from './home.component'

const mapStateToProps = createStructuredSelector({
  isLoadingReportsData: selectReportsIsLoadingData,
  isReportSaved: selectReportSaveSuccess,
  reportHasData: selectReportsHasData,
})

const BaseHome = (props) => {
  const location = useLocation()
  const intl = useIntl()
  return <Home {...props} location={location} intl={intl} />
}

export const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(BaseHome)
