import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="layout-sidebar-left-collapse">
      <path
        id="Vector"
        d="M5.99886 2.66876V13.3354M9.99886 6.66876L8.66553 8.0021L9.99886 9.33543M2.66553 4.0021C2.66553 3.64847 2.806 3.30933 3.05605 3.05929C3.3061 2.80924 3.64524 2.66876 3.99886 2.66876H11.9989C12.3525 2.66876 12.6916 2.80924 12.9417 3.05929C13.1917 3.30933 13.3322 3.64847 13.3322 4.0021V12.0021C13.3322 12.3557 13.1917 12.6949 12.9417 12.9449C12.6916 13.195 12.3525 13.3354 11.9989 13.3354H3.99886C3.64524 13.3354 3.3061 13.195 3.05605 12.9449C2.806 12.6949 2.66553 12.3557 2.66553 12.0021V4.0021Z"
        stroke="#36454F"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
