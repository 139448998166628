import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="50"
    height="35"
    viewBox="0 0 50 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.68441 33.309C4.84716 33.309 2.46753 31.0209 2.46753 28.1836C2.46753 25.3464 4.75564 23.0583 7.59289 23.0583C10.4301 23.0583 12.8098 25.3464 12.8098 28.1836C12.8098 30.9294 10.5217 33.2175 7.68441 33.309ZM7.68441 23.882C5.39631 23.882 3.4743 25.804 3.4743 28.0921C3.4743 30.3802 5.39631 32.3022 7.68441 32.3022C9.97252 32.3022 11.8945 30.3802 11.8945 28.0921C11.8945 25.804 9.97252 23.882 7.68441 23.882Z"
      fill="#36454F"
      stroke="#36454F"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M42.0943 33.309C39.2571 33.309 36.8774 31.0209 36.8774 28.1836C36.8774 25.3464 39.1655 23.0583 42.0028 23.0583C44.84 23.0583 47.2197 25.3464 47.2197 28.1836C47.2197 30.9294 44.9316 33.2175 42.0943 33.309ZM42.0943 23.882C39.8062 23.882 37.8842 25.804 37.8842 28.0921C37.8842 30.3802 39.8062 32.3022 42.0943 32.3022C44.3824 32.3022 46.3044 30.3802 46.3044 28.0921C46.3044 25.804 44.3824 23.882 42.0943 23.882Z"
      fill="#36454F"
      stroke="#36454F"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M36.3266 27.634H17.3811V28.5493H36.3266V27.634Z"
      fill="#36454F"
      stroke="#36454F"
      strokeMiterlimit="10"
    />
    <path
      d="M19.1252 27.634H13.4507V28.5493H19.1252V27.634Z"
      fill="#36454F"
      stroke="#36454F"
      strokeMiterlimit="10"
    />
    <path
      d="M1.91524 27.8154H1V22.2324C1 20.0358 2.37287 18.1138 4.3864 17.3816C5.66774 16.924 7.13213 16.741 8.50499 16.741H8.96261V17.6562H8.50499C7.22365 17.6562 5.94231 17.8393 4.66097 18.2969C3.01353 18.846 1.91524 20.4935 1.91524 22.2324V27.8154Z"
      fill="#36454F"
      stroke="#36454F"
      strokeMiterlimit="10"
    />
    <path
      d="M49 27.7692H48.077V15.5764C48.077 8.059 44.2923 4.20864 44.2923 4.11696L44.0154 3.84193L45.3077 1.91675H38.8462V1H47.0616L45.2154 3.75026C46.1385 4.85037 49 8.70073 49 15.5764V27.7692Z"
      fill="#36454F"
      stroke="#36454F"
      strokeMiterlimit="10"
    />
    <path
      d="M3.65081 18.5385L2.84619 18.0817L3.02499 17.625C4.72363 14.5192 6.86928 11.7789 9.28314 9.31251C13.038 5.56731 19.3856 1 27.8788 1H39.7693V1.91346H27.8788C19.7432 1.91346 13.6638 6.29808 9.99836 9.95193C7.5845 12.3269 5.52825 15.0673 3.91901 18.0817L3.65081 18.5385Z"
      fill="#36454F"
      stroke="#36454F"
      strokeMiterlimit="10"
    />
    <path
      d="M12.5349 13.8135L12.6264 13.1729C12.6264 13.0814 13.0841 10.8848 15.2806 8.50514C17.2942 6.40008 20.7258 3.7653 28.2308 3.30768L37.4295 3.37979H37.521C37.6126 3.37979 43.3786 5.11875 43.3786 10.8848V11.3424H28.9178L12.5349 13.8135ZM28.8262 4.38655C23.2433 4.66112 18.8501 6.30856 16.1044 9.14581C15.0976 10.1526 14.3654 11.3424 13.8163 12.7153L28.7347 10.5187H42.3718C42.0972 6.12551 37.8871 4.66113 37.338 4.47808L28.8262 4.38655Z"
      fill="#36454F"
      stroke="#36454F"
      strokeMiterlimit="10"
    />
    <path
      d="M29.1892 3.37982H28.2739V11.3424H29.1892V3.37982Z"
      fill="#36454F"
      stroke="#36454F"
      strokeMiterlimit="10"
    />
  </svg>
)
