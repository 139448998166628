import { Record } from 'immutable'
import { createActions, createReducer } from 'reduxsauce'

export const { Types: ContactTypes, Creators: ContactActions } = createActions(
  {
    sendContact: ['formData', 'formik'],
    sendContactSuccess: null,
    sendContactFailure: ['error'],
  },
  { prefix: 'CONTACT_' }
)

const ContactRecord = new Record({
  sendSuccess: false,
  formError: null,
})

export const INITIAL_STATE = new ContactRecord({})

const resetState = () => INITIAL_STATE

const sendContactSuccess = (state) => state.set('sendSuccess', true)

const sendContactFailure = (state, { error }) => state.set('formError', error)

export const reducer = createReducer(INITIAL_STATE, {
  [ContactTypes.SEND_CONTACT]: resetState,
  [ContactTypes.SEND_CONTACT_SUCCESS]: sendContactSuccess,
  [ContactTypes.SEND_CONTACT_FAILURE]: sendContactFailure,
})
