import { defineMessages } from 'react-intl'

export default defineMessages({
  sitesCount: {
    id: 'fileInfo.sitesCount',
    defaultMessage: 'You will import {count} if you continue.',
  },
  sitesCountValue: {
    id: 'fileInfo.sitesCountValue',
    defaultMessage: '{count} {count, plural, one {site} other {sites} }',
  },
})
