import { Field } from 'formik'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { TextInput } from '../forms/textInput'
import {
  FIRST_NAME_FIELD_NAME,
  LAST_NAME_FIELD_NAME,
  EMAIL_FIELD_NAME,
  PHONE_FIELD_NAME,
  COMPANY_FIELD_NAME,
} from './contactForm.constants'
import messages from './contactForm.messages'

export class ContactForm extends PureComponent {
  static propTypes = {
    isSubmitting: PropTypes.bool,
  }

  static defaultProps = {
    isSubmitting: false,
  }

  render() {
    const { isSubmitting } = this.props
    return (
      <div className="contact-form">
        <div className="contact-form__row">
          <Field
            name={FIRST_NAME_FIELD_NAME}
            component={TextInput}
            placeholder="First Name"
            label={<FormattedMessage {...messages.firstNameLabel} />}
            inline
            disabled={isSubmitting}
          />
          <Field
            name={LAST_NAME_FIELD_NAME}
            component={TextInput}
            placeholder="Last Name"
            label={<FormattedMessage {...messages.lastNameLabel} />}
            inline
            disabled={isSubmitting}
          />
        </div>
        <div className="contact-form__row">
          <Field
            name={EMAIL_FIELD_NAME}
            component={TextInput}
            type="email"
            placeholder="Email Address"
            label={<FormattedMessage {...messages.emailLabel} />}
            inline
            disabled={isSubmitting}
          />

          <Field
            name={PHONE_FIELD_NAME}
            component={TextInput}
            type="phone"
            placeholder="Phone Number"
            label={<FormattedMessage {...messages.phoneLabel} />}
            inline
            disabled={isSubmitting}
          />
        </div>
        <div className="contact-form__row">
          <Field
            name={COMPANY_FIELD_NAME}
            component={TextInput}
            placeholder="Company Name"
            label={<FormattedMessage {...messages.companyNameLabel} />}
            inline
            disabled={isSubmitting}
          />
        </div>
      </div>
    )
  }
}
