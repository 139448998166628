import numeral from 'numeral'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import ChartComponent from 'react-chartjs-2'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { colors } from '@voltus/core-components'
import { drawColors } from '../../../../shared/utils/colors'
import { renderWhenTrue } from '../../../../shared/utils/rendering'
import { URLS } from '../report.constants'
import messages from './graph.messages'

export class GraphComponent extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    labels: PropTypes.array.isRequired,
    abbreviations: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
    programs: PropTypes.array.isRequired,
    areProgramsAvailable: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
  }

  formatEarnings(earnings) {
    return numeral(earnings).format('($0.[0]a)').toUpperCase()
  }

  renderEmptyPlaceholder = renderWhenTrue(() => (
    <div className="graph__placeholder graph__placeholder--empty">
      <div className="graph__placeholder-background" />
      <div className="graph__placeholder-title graph__placeholder-title--empty">
        <FormattedMessage {...messages.emptyPlaceholderTitle} />
      </div>
      <div className="graph__placeholder-text">
        <FormattedMessage {...messages.emptyPlaceholderText} />
      </div>
    </div>
  ))

  renderNoProgramsPlaceholder = renderWhenTrue(() => (
    <div className="graph__placeholder graph__placeholder--no-programs">
      <div className="graph__placeholder-background" />
      <div className="graph__placeholder-title graph__placeholder-title--no-programs">
        <FormattedMessage {...messages.noProgramsPlaceholderTitle} />
      </div>
      <div className="graph__placeholder-text">
        <FormattedMessage
          {...messages.noProgramsPlaceholderText}
          values={{
            voltusLink: this.renderNoProgramsPlaceholderLink(
              'noProgramsVoltusLink'
            ),
            linkedInLink: this.renderNoProgramsPlaceholderLink(
              'noProgramsLinkedInLink'
            ),
            twitterLink: this.renderNoProgramsPlaceholderLink(
              'noProgramsTwitterLink'
            ),
            youTubeLink: this.renderNoProgramsPlaceholderLink(
              'noProgramsYouTubeLink'
            ),
          }}
        />
      </div>
    </div>
  ))

  renderNoProgramsPlaceholderLink = (id) => (
    <Link to={URLS[id]} className="graph__placeholder-link">
      <FormattedMessage {...messages[id]} />
    </Link>
  )

  renderChart = renderWhenTrue(() => (
    <div className="graph__chart-wrapper-outer">
      <div className="graph__chart-wrapper-inner">
        <div className="graph__chart-wrapper">
          <ChartComponent
            type={this.props.type}
            data={{
              datasets: [
                {
                  data: this.props.values,
                  backgroundColor: drawColors(this.props.values.length),
                },
              ],
              labels: this.props.abbreviations,
            }}
            options={{
              label: {
                color: colors.grays['0'],
                minLabelCircumference: 0.3,
              },
              cutoutPercentage: 40,
              responsive: true,
              maintainAspectRatio: false,
              defaultFontFamily: 'Inter, sans-serif',
              defaultFontSize: 14,
              aspectRatio: 1,
              legend: {
                display: false,
              },
              title: {
                display: false,
              },
              animation: {
                duration: 1000,
                animateScale: false,
                animateRotate: false,
              },
              tooltips: {
                backgroundColor: colors.grays['70'],
                bodyFontColor: colors.grays['0'],
                bodyFontFamily: 'Inter, sans-serif',
                caretPadding: 15,
                displayColors: false,
                bodyFontSize: 13,
                titleMarginBottom: 15,
                xPadding: 15,
                yPadding: 15,
                labelSpacing: 0,
                footerMarginTop: 12,
                cornerRadius: 4,
                callbacks: {
                  title: (tooltipItem) =>
                    this.props.labels[tooltipItem[0].index].toUpperCase(),
                  label: (tooltipItem, data) => {
                    const earnings = this.formatEarnings(
                      data.datasets[0].data[tooltipItem.index]
                    )
                    return this.props.intl.formatMessage(messages.earnings, {
                      earnings,
                    })
                  },
                  afterLabel: () =>
                    this.props.intl.formatMessage(messages.potentialEarnings),
                  footer: (tooltipItem) => {
                    const programs = this.props.programs[tooltipItem[0].index]
                    return this.props.intl.formatMessage(messages.programs, {
                      programs,
                    })
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  ))

  render() {
    return (
      <div className="graph">
        {this.renderChart(!!this.props.values.length)}

        {this.renderEmptyPlaceholder(
          !this.props.values.length && this.props.areProgramsAvailable
        )}

        {this.renderNoProgramsPlaceholder(
          !this.props.values.length && !this.props.areProgramsAvailable
        )}
      </div>
    )
  }
}

export const Graph = injectIntl(GraphComponent)
