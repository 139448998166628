import * as React from 'react'

type PointerPosition = {
  /**
   * The X coordinate of the mouse pointer in local (DOM content) coordinates.
   */
  clientX: number
  /**
   * The Y coordinate of the mouse pointer in local (DOM content) coordinates.
   */
  clientY: number
  /**
   * The X coordinate of the mouse pointer relative to the position of the padding edge of the target node.
   */
  offsetX: number
  /**
   * The Y coordinate of the mouse pointer relative to the position of the padding edge of the target node.
   */
  offsetY: number
  /**
   * The X coordinate of the mouse pointer relative to the whole document.
   */
  pageX: number
  /**
   * The Y coordinate of the mouse pointer relative to the whole document.
   */
  pageY: number
  /**
   * The X coordinate of the mouse pointer in global (screen) coordinates.
   */
  screenX: number
  /**
   * The Y coordinate of the mouse pointer in global (screen) coordinates.
   */
  screenY: number
  /**
   * Alias for clientX.
   */
  x: number
  /**
   * Alias for clientY.
   */
  y: number
  /**
   * Returns true if the alt key was down when the mouse event was fired.
   */
  altKey: boolean
  /**
   * Returns true if the control key was down when the mouse event was fired.
   */
  ctrlKey: boolean
  /**
   * Returns true if the shift key was down when the mouse event was fired.
   */
  shiftKey: boolean
  /**
   * Returns true if the meta key was down when the mouse event was fired.
   */
  metaKey: boolean
}

const defaultPointerPosition = {
  clientX: 0,
  clientY: 0,
  offsetX: 0,
  offsetY: 0,
  pageX: 0,
  pageY: 0,
  screenX: 0,
  screenY: 0,
  x: 0,
  y: 0,
  altKey: false,
  ctrlKey: false,
  shiftKey: false,
  metaKey: false,
}

const stateContext = React.createContext<PointerPosition>(
  defaultPointerPosition
)

const pickProps = (e: PointerEvent): PointerPosition => ({
  clientX: e.clientX,
  clientY: e.clientY,
  offsetX: e.offsetX,
  offsetY: e.offsetY,
  pageX: e.pageX,
  pageY: e.pageY,
  screenX: e.screenX,
  screenY: e.screenY,
  x: e.x,
  y: e.y,
  altKey: e.altKey,
  ctrlKey: e.ctrlKey,
  shiftKey: e.shiftKey,
  metaKey: e.metaKey,
})

export const PointerContextProvider = ({ children }) => {
  const [mousePos, setMousePos] = React.useState<PointerPosition>(
    defaultPointerPosition
  )

  React.useEffect(() => {
    const handlePointerMove = (e: PointerEvent) => {
      setMousePos(pickProps(e))
    }
    window.addEventListener('pointermove', handlePointerMove)
    window.addEventListener('pointerdown', handlePointerMove)

    return () => {
      window.removeEventListener('pointermove', handlePointerMove)
      window.removeEventListener('pointerdown', handlePointerMove)
    }
  }, [])

  return (
    <stateContext.Provider value={mousePos}>{children}</stateContext.Provider>
  )
}

export const usePointerContext = (): PointerPosition =>
  React.useContext<PointerPosition>(stateContext)
