import { fromJS, Record, Map, List } from 'immutable'
import { createActions, createReducer } from 'reduxsauce'

export const { Types: PostcodesFileTypes, Creators: PostcodesFileActions } =
  createActions(
    {
      parseFile: ['file', 'callbacks'],
      parseFileSuccess: [],
      parseFileFailure: ['error', 'callbacks'],
      setFileData: ['meta', 'data'],
      setPostcodeColumn: ['index'],
      resetData: null,
    },
    { prefix: 'POSTCODES_FILE_' }
  )

const PostcodesFileRecord = new Record({
  meta: Map(),
  data: List(),
  postcodeColumn: 0,
  isFileSet: false,
})

export const INITIAL_STATE = new PostcodesFileRecord({})

export const reset = () => INITIAL_STATE

export const setFileData = (state = INITIAL_STATE, { meta, data }) =>
  state.merge(
    fromJS({
      meta,
      data,
      isFileSet: true,
    })
  )

export const setPostcodeColumn = (state = INITIAL_STATE, { index }) =>
  state.set('postcodeColumn', Number(index))

export const reducer = createReducer(INITIAL_STATE, {
  [PostcodesFileTypes.SET_FILE_DATA]: setFileData,
  [PostcodesFileTypes.SET_POSTCODE_COLUMN]: setPostcodeColumn,
  [PostcodesFileTypes.RESET_DATA]: reset,
})
