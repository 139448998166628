import Papa from 'papaparse'
import { pick } from 'ramda'
import { put, takeLatest, select } from 'redux-saga/effects'

import { getErrorMessage } from '../../shared/utils/errorHelpers'
import reportError from '../../shared/utils/reportError'
import { POSTCODE_SYNONYMS } from './postcodesFile.constants'
import { validateFile } from './postcodesFile.helpers'
import messages from './postcodesFile.messages'
import { PostcodesFileTypes, PostcodesFileActions } from './postcodesFile.redux'
import { selectColumnNames } from './postcodesFile.selectors'

const parseCSV = (file) => {
  return new Promise((resolve) => {
    Papa.parse(file, {
      complete: resolve,
    })
  })
}

export function* guessPostcodeColumn() {
  try {
    const columnNames = yield select(selectColumnNames)
    const rawColumnNames = columnNames.toArray()

    let foundColumnIndex = -1
    const includesKeyword = (keyword) => (name) =>
      String(name).toLowerCase().includes(keyword)

    for (const keyword of POSTCODE_SYNONYMS) {
      const index = rawColumnNames.findIndex(includesKeyword(keyword))
      if (index > -1) {
        foundColumnIndex = index
        break
      }
    }

    return foundColumnIndex
  } catch (error) {
    /* istanbul ignore next */
    reportError(error)
  }
  return null
}

export function* parseFile({ file, callbacks = {} }) {
  try {
    const meta = pick(['name', 'size'], file)
    yield validateFile(file)

    const { data } = yield parseCSV(file)
    yield put(PostcodesFileActions.setFileData(meta, data))

    yield put(PostcodesFileActions.parseFileSuccess())
  } catch (error) {
    yield put(PostcodesFileActions.parseFileFailure(error, callbacks))
  }
}

export function* parseFileSuccess() {
  try {
    const foundNameIndex = yield guessPostcodeColumn()

    if (foundNameIndex > -1) {
      yield put(PostcodesFileActions.setPostcodeColumn(foundNameIndex))
    }
  } catch (error) {
    /* istanbul ignore next */
    reportError(error)
  }
}

export function parseFileFailure({ error, callbacks = {} }) {
  try {
    if (error.code && callbacks.setError) {
      const message = getErrorMessage(error.code, messages)
      callbacks.setError(message)
    }
  } catch (e) {
    /* istanbul ignore next */
    reportError(e)
  }
}

export function* watchPostcodesFile() {
  try {
    yield takeLatest(PostcodesFileTypes.PARSE_FILE, parseFile)
    yield takeLatest(PostcodesFileTypes.PARSE_FILE_SUCCESS, parseFileSuccess)
    yield takeLatest(PostcodesFileTypes.PARSE_FILE_FAILURE, parseFileFailure)
  } catch (error) {
    /* istanbul ignore next */
    reportError(error)
  }
}
