import classnames from 'classnames'
import PropTypes from 'prop-types'
import { find, propEq } from 'ramda'
import Slider from 'rc-slider'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { renderWhenNotNil } from '../../utils/rendering'

export class RangeValue extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    selected: PropTypes.boolean,
    value: PropTypes.number.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.object,
        title: PropTypes.object,
      })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  get selectedItem() {
    const { values, value } = this.props

    return find(propEq('value', value))(values)
  }

  get title() {
    if (this.selectedItem) {
      return this.selectedItem.title
    }
    return null
  }

  renderValue = (item) => {
    const { label } = item
    const selected = this.selectedItem.value === item.value

    return (
      <div
        className={classnames('range-value__item', {
          'range-value__item-active': selected,
        })}
        key={item.value}
      >
        <div
          className="range-value__bar"
          onClick={() => this.props.onChange(item.value)}
        />
        <span className="range-value__label">
          <FormattedMessage {...label} />
        </span>
      </div>
    )
  }

  renderTitle = renderWhenNotNil(() => (
    <h3 className="range-value__title">
      <FormattedMessage {...this.title} />
    </h3>
  ))

  render() {
    const { values, value, onChange, className } = this.props

    return (
      <div className={classnames(className, 'range-value__container')}>
        {this.renderTitle(this.title)}

        <div className="range-value__slider-container">
          <div className="range-value__items">
            {values.map(this.renderValue)}
          </div>

          <div className="range-value__slider">
            <Slider
              min={0}
              max={2}
              step={1}
              value={value}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    )
  }
}
