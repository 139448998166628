import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import { selectLocalesLanguage } from '../modules/locales'
import { StartupActions } from '../modules/startup'
import { App } from './app.component'

const mapStateToProps = createStructuredSelector({
  language: selectLocalesLanguage,
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startup: StartupActions.startup,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(App)
