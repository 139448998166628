import { combineReducers } from 'redux-immutable'

import { reducer as contactReducer } from './contact/contact.redux'
import { reducer as localesReducer } from './locales/locales.redux'
import { reducer as postcodesFileReducer } from './postcodesFile/postcodesFile.redux'
import { reducer as reportsReducer } from './reports/reports.redux'
//<-- IMPORT MODULE REDUCER -->

export default function createReducer() {
  return combineReducers({
    locales: localesReducer,
    reports: reportsReducer,
    postcodesFile: postcodesFileReducer,
    contact: contactReducer,
    //<-- INJECT MODULE REDUCER -->
  })
}
