import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { components } from 'react-select'

export class SingleValue extends PureComponent {
  static propTypes = {
    selectProps: PropTypes.object,
  }

  render() {
    const { selectProps, ...props } = this.props
    const { emptyValuePlaceholder } = selectProps
    const isEmptyText = props.children === ''
    const children =
      emptyValuePlaceholder && isEmptyText
        ? emptyValuePlaceholder
        : props.children
    return (
      <components.SingleValue {...props} {...selectProps}>
        {children}
      </components.SingleValue>
    )
  }
}
