import React from 'react'
import createSVGComponent from './createSVGComponent'
export default createSVGComponent(
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="8" fill="black" />
    <line
      x1="5.65811"
      y1="5.71448"
      x2="12.2439"
      y2="12.3003"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="5.71448"
      y1="12.3003"
      x2="12.3003"
      y2="5.71448"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
