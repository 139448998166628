import React, { PureComponent } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'

import messages from './loader.messages'

export class LoaderComponent extends PureComponent {
  static propTypes = {}

  render() {
    return (
      <div className="loader">
        <div className="loader__bar">
          <div className="loader__text">
            <FormattedMessage {...messages.text} />
          </div>
        </div>
      </div>
    )
  }
}

export const Loader = injectIntl(LoaderComponent)
