import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import {
  ReportsActions,
  selectReportsData,
  selectReportsIsLoadingData,
  selectReportsFrequency,
  selectReportsResponseTime,
  selectReportsTotalEarnings,
  selectReportsTotalPrograms,
  selectReportsTotalSites,
  selectReportsStatesLabels,
  selectReportsStatesAbbreviations,
  selectReportsStatesPrograms,
  selectReportsStatesEarnings,
  selectReportsAreProgramsAvailable,
} from '../../../modules/reports'
import { Report } from './report.component'

const mapStateToProps = createStructuredSelector({
  reportsData: selectReportsData,
  isLoadingReportsData: selectReportsIsLoadingData,
  frequency: selectReportsFrequency,
  responseTime: selectReportsResponseTime,
  totalEarnings: selectReportsTotalEarnings,
  totalPrograms: selectReportsTotalPrograms,
  totalSites: selectReportsTotalSites,
  statesLabels: selectReportsStatesLabels,
  statesAbbreviations: selectReportsStatesAbbreviations,
  statesPrograms: selectReportsStatesPrograms,
  statesEarnings: selectReportsStatesEarnings,
  areProgramsAvailable: selectReportsAreProgramsAvailable,
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFrequency: ReportsActions.setFrequency,
      setResponseTime: ReportsActions.setResponseTime,
      setReportToken: ReportsActions.saveSuccess,
      getSavedReport: ReportsActions.getSaved,
    },
    dispatch
  )

const BaseReport = (props) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const [searchParams] = useSearchParams()
  return (
    <Report
      {...props}
      navigate={navigate}
      intl={intl}
      searchParams={searchParams}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseReport)
