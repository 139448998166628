import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'

import Check from './navigation.check_svg'
export class NavigationComponent extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
  }

  renderItem = (item, index) => {
    const lastIndex = index === this.props.items.length - 1
    return (
      <React.Fragment key={index}>
        <div
          className={classnames('navigation__item-sections', {
            'navigation__item--active': item.active,
            'navigation__item--visited': item.visited,
          })}
        >
          {!item.visited ? (
            <span className="navigation__item-index">{index + 1}</span>
          ) : (
            <span className="navigation__item-index">
              <Check />
            </span>
          )}
          <span className="navigation__item-label">{item.label}</span>
        </div>
        {!lastIndex ? (
          <div
            className={classnames('navigation__item-progress', {
              'navigation__item-progress-visited': item.visited,
              'navigation__item-progress-active': item.active,
            })}
          />
        ) : null}
      </React.Fragment>
    )
  }

  render() {
    return (
      <div className="navigation__container">
        {this.props.items.map(this.renderItem)}
      </div>
    )
  }
}

export const Navigation = injectIntl(NavigationComponent)
